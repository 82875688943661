import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { OPEN_REPORT_REVIEW_MODAL } from './actions';
import api from '@/redux/api/review';
import cloneDeep from 'lodash/cloneDeep';
import ms from 'ms';

/* Action Types */
export const FETCH_HELPFUL_COUNT_FAIL = 'la/domain/fetchHelpfulCount/FAIL';
export const FETCH_HELPFUL_COUNT_REQUEST = 'la/domain/fetchHelpfulCount/REQUEST';
export const FETCH_HELPFUL_COUNT_SUCCESS = 'la/domain/fetchHelpfulCount/SUCCESS';

export const FETCH_STATUSES_FAIL = 'la/domain/fetchBidderStatuses/FAIL';
export const FETCH_STATUSES_REQUEST = 'la/domain/fetchBidderStatuses/REQUEST';
export const FETCH_STATUSES_SUCCESS = 'la/domain/fetchBidderStatuses/SUCCESS';

export const SET_REVIEW_STATUS_FAIL = 'la/domain/setBidderReviewStatus/FAIL';
export const SET_REVIEW_STATUS_REQUEST = 'la/domain/setBidderReviewStatus/REQUEST';
export const SET_REVIEW_STATUS_SUCCESS = 'la/domain/setBidderReviewStatus/SUCCESS';

const REDUX_STORE_TIME = ms('30m');

//reducer
export type ReviewStatusSlice = {
    activeReview: number;
    helpfulCountById: any;
    helpfulCountLoaded: any;
    helpfulCountLoading: boolean;
    statusesById: any;
    statusesLoaded: any;
    statusesLoading: boolean;
    submitted: boolean;
};

export const defaultReviewStatusSlice: ReviewStatusSlice = {
    activeReview: 0,
    helpfulCountById: {},
    helpfulCountLoaded: {},
    helpfulCountLoading: false,
    statusesById: {},
    statusesLoaded: {},
    statusesLoading: false,
    submitted: false,
};

export default function reducer(
    state: ReviewStatusSlice = defaultReviewStatusSlice,
    action: any = {}
): ReviewStatusSlice {
    switch (action.type) {
        case FETCH_HELPFUL_COUNT_FAIL:
            return {
                ...state,
                helpfulCountLoading: false,
            };
        case FETCH_HELPFUL_COUNT_REQUEST:
            return {
                ...state,
                helpfulCountLoading: true,
            };
        case FETCH_HELPFUL_COUNT_SUCCESS:
            const existingCounts = cloneDeep(state.helpfulCountById) || {};
            const existingCountsLoaded = cloneDeep(state.helpfulCountLoaded) || {};
            const loadedTimes = action.meta.reviewIds.map((id) => {
                return { [id]: action.meta.actionTime };
            });
            const NewCountsLoaded = Object.assign({}, ...loadedTimes);
            return {
                ...state,
                helpfulCountById: Object.assign(existingCounts, action.payload),
                helpfulCountLoaded: Object.assign(existingCountsLoaded, NewCountsLoaded),
                helpfulCountLoading: false,
            };
        case FETCH_STATUSES_FAIL:
            return {
                ...state,
                statusesLoading: false,
            };
        case FETCH_STATUSES_REQUEST:
            return {
                ...state,
                statusesLoading: true,
            };
        case FETCH_STATUSES_SUCCESS:
            const existingStatuses = cloneDeep(state.statusesById) || {};
            const existingStatusesLoaded = cloneDeep(state.statusesLoaded) || {};
            const times = action.meta.reviewIds.map((id) => {
                return { [id]: action.meta.actionTime };
            });
            const NewStatusesLoaded = Object.assign({}, ...times);
            return {
                ...state,
                statusesById: Object.assign(existingStatuses, action.payload),
                statusesLoaded: Object.assign(existingStatusesLoaded, NewStatusesLoaded),
                statusesLoading: false,
            };
        case SET_REVIEW_STATUS_SUCCESS:
            const { active, reviewId, reviewStatus } = action.meta;
            const existing = cloneDeep(state.statusesById) || {};
            let updated = existing[reviewId]; // push/pop reviewStatus accordingly

            if (Boolean(active)) {
                updated.push(reviewStatus);
            } else {
                updated = updated.filter((u) => u !== reviewStatus);
            }

            const newStatus = { [reviewId]: updated }; // if reviewStatus is helpful update helpfulCount

            const existingHelpfulCounts = cloneDeep(state.helpfulCountById) || {};
            let count = existingHelpfulCounts[reviewId];

            if (reviewStatus === 'helpful') {
                count = !active ? count - 1 : count + 1;
            }

            const newCount = { [reviewId]: count };
            return {
                ...state,
                helpfulCountById: Object.assign(existingHelpfulCounts, newCount),
                statusesById: Object.assign(existing, newStatus),
                submitted: false,
            };
        case OPEN_REPORT_REVIEW_MODAL:
            return {
                ...state,
                activeReview: action.payload,
            };
        case SET_REVIEW_STATUS_REQUEST:
            return {
                ...state,
                submitted: true,
            };
        case SET_REVIEW_STATUS_FAIL:
            return {
                ...state,
                submitted: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const idSelector = (_: GlobalState, id: number) => id;

export const getUiReviewStatus = createSelector(stateSelector, (state) => state.reviewStatus);
export const isSubmitted = createSelector(getUiReviewStatus, (state) => state.submitted);

export const statusesByIdSelector = createSelector(getUiReviewStatus, (state) => state.statusesById || {});

export const helpfulCountByIdSelector = createSelector(getUiReviewStatus, (state) => state.helpfulCountById || {});

const helpfulCountLoadedSelector = createSelector(getUiReviewStatus, (state) => state.helpfulCountLoaded || {});

export const getHelpfulCountLoading = createSelector(getUiReviewStatus, (state) => state.helpfulCountLoading);

const statusesLoadedSelector = createSelector(getUiReviewStatus, (state) => state.statusesLoaded || {});

export const getStatusesLoading = createSelector(getUiReviewStatus, (state) => state.statusesLoading);

export const getActiveReview = createSelector(getUiReviewStatus, (state) => state.activeReview);

export const getStatusesById = createSelector([statusesByIdSelector, idSelector], (statusesById, id) => {
    return statusesById[id] || [];
});

export const getHelpfulCountById = createSelector([helpfulCountByIdSelector, idSelector], (helpfulCountById, id) => {
    return helpfulCountById[id] || 0;
});

export const getHelpfulCountLoadedById = createSelector(
    [helpfulCountLoadedSelector, idSelector],
    (helpfulCountById, id) => {
        return helpfulCountById[id] || 0;
    }
);

export const getStatusesLoadedById = createSelector([statusesLoadedSelector, idSelector], (statusesLoaded, id) => {
    return statusesLoaded[id] || 0;
});

const shouldFetchBidderStatusesByIdIfNeeded = (state, reviewIds) => {
    const isLoading = getStatusesLoading(state);
    const isLoggedIn = isUserLoggedIn(state);

    if (isLoading || !isLoggedIn) {
        return false;
    }

    const filteredIds = reviewIds.filter(() => {
        const existingStatus = getStatusesLoadedById(state, reviewIds);
        if (!existingStatus) {
            return true;
        } else {
            const diff = Date.now() - existingStatus;
            return diff > REDUX_STORE_TIME;
        }
    });
    return filteredIds.length > 0 ? filteredIds : false;
};

const shouldFetchHelpfulCountByIdIfNeeded = (state, reviewIds) => {
    const isLoading = getHelpfulCountLoading(state);
    if (isLoading) {
        return false;
    }
    const filteredIds = reviewIds.filter((id) => {
        const existingStatus = getHelpfulCountLoadedById(state, id);
        if (!existingStatus) {
            return true;
        } else {
            const diff = Date.now() - existingStatus;
            return diff > REDUX_STORE_TIME;
        }
    });
    return filteredIds.length > 0 ? filteredIds : false;
};

/* ACTION CREATORS */
const loadBidderStatusesById = (reviewIds) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            type: FETCH_STATUSES_REQUEST,
        });
        if (authToken) {
            const response = await api.fetchBidderStatuses({
                authToken,
                deployment,
                reviewIds,
            });
            dispatch({
                meta: { actionTime: Date.now(), reviewIds },
                payload: response,
                type: FETCH_STATUSES_SUCCESS,
            });
        } else {
            dispatch({
                payload: 'no auth token',
                type: FETCH_STATUSES_FAIL,
            });
        }
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_STATUSES_FAIL,
        });
    }
};

const loadHelpfulCountsById = (reviewIds) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            type: FETCH_HELPFUL_COUNT_REQUEST,
        });
        const response = await api.fetchHelpfulCounts({
            deployment,
            reviewIds,
        });
        dispatch({
            meta: { actionTime: Date.now(), reviewIds },
            payload: response,
            type: FETCH_HELPFUL_COUNT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_HELPFUL_COUNT_FAIL,
        });
    }
};

export const setBidderReviewStatus = (reviewStatus: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            meta: reviewStatus,
            type: SET_REVIEW_STATUS_REQUEST,
        });
        await api.setBidderReviewStatus({
            authToken,
            deployment,
            reviewStatus,
        });
        dispatch({
            meta: reviewStatus,
            type: SET_REVIEW_STATUS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            payload: error,
            type: SET_REVIEW_STATUS_FAIL,
        });
    }
};

export const fetchBidderStatusesByIdIfNeeded =
    (reviewIds: number[]) => (dispatch: AppDispatch, getState: AppGetState) => {
        const filteredIds = shouldFetchBidderStatusesByIdIfNeeded(getState(), reviewIds);
        if (filteredIds) {
            return dispatch(loadBidderStatusesById(filteredIds));
        }
        return Promise.resolve();
    };

export const fetchHelpfulCountsByIdIfNeeded =
    (reviewIds: number[]) => (dispatch: AppDispatch, getState: AppGetState) => {
        const filteredIds = shouldFetchHelpfulCountByIdIfNeeded(getState(), reviewIds);
        if (filteredIds) {
            return dispatch(loadHelpfulCountsById(filteredIds));
        }
        return Promise.resolve();
    };
