import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { HouseData } from '@/types/HousePercentile';
import { OPEN_REVIEWS_MODAL } from './actions';
import { Rating } from '@/types/Rating';
import { ReviewComment, ReviewResponse } from '@/types/Review';
import api from '@/redux/api/review';
import cloneDeep from 'lodash/cloneDeep';
import ms from 'ms';

/* Action Types */
export const FETCH_COMMENTS_FAIL = 'la/domain/fetchSellerComments/FAIL';
export const FETCH_COMMENTS_REQUEST = 'la/domain/fetchSellerComments/REQUEST';
export const FETCH_COMMENTS_SUCCESS = 'la/domain/fetchSellerComments/SUCCESS';

export const FETCH_PERCENTILES_FAIL = 'la/domain/fetchSellerPercentiles/FAIL';
export const FETCH_PERCENTILES_REQUEST = 'la/domain/fetchSellerPercentiles/REQUEST';
export const FETCH_PERCENTILES_SUCCESS = 'la/domain/fetchSellerPercentiles/SUCCESS';

export const FETCH_RATINGS_FAIL = 'la/domain/fetchSellerRatings/FAIL';
export const FETCH_RATINGS_REQUEST = 'la/domain/fetchSellerRatings/REQUEST';
export const FETCH_RATINGS_SUCCESS = 'la/domain/fetchSellerRatings/SUCCESS';

export const FETCH_RATING_SUMMARY_FAIL = 'la/domain/fetchSellerRatingSummary/FAIL';
export const FETCH_RATING_SUMMARY_REQUEST = 'la/domain/fetchSellerRatingSummary/REQUEST';
export const FETCH_RATING_SUMMARY_SUCCESS = 'la/domain/fetchSellerRatingSummary/SUCCESS';

export const FETCH_TOP_REVIEWS_FAIL = 'la/domain/fetchSellerLatestTopReviews/FAIL';
export const FETCH_TOP_REVIEWS_REQUEST = 'la/domain/fetchSellerLatestTopReviews/REQUEST';
export const FETCH_TOP_REVIEWS_SUCCESS = 'la/domain/fetchSellerLatestTopReviews/SUCCESS';

const REDUX_STORE_TIME = ms('30m');

export type SellerRatingsSlice = {
    activeSeller: number;
    commentsById: { [index: number]: ReviewComment };
    commentsLoaded: { [index: number]: number };
    commentsLoading: boolean;
    loaded: { [index: number]: number };
    loading: boolean;
    percentilesById: { [index: number]: HouseData[] };
    percentilesLoaded: { [index: number]: number };
    percentilesLoading: boolean;
    ratingSummaryById: { [index: number]: Rating };
    ratingSummaryLoaded: { [index: number]: number };
    ratingSummaryLoading: boolean;
    reviewsById: { [index: number]: ReviewResponse };
    topReviewsLoaded: { [index: number]: number };
    topReviewsLoading: boolean;
};

//reducer
export const defaultSellerRatingsSlice: SellerRatingsSlice = {
    activeSeller: 0,
    commentsById: {},
    commentsLoaded: {},
    commentsLoading: false,
    loaded: {},
    loading: false,
    percentilesById: {},
    percentilesLoaded: {},
    percentilesLoading: false,
    ratingSummaryById: {},
    ratingSummaryLoaded: {},
    ratingSummaryLoading: false,
    reviewsById: {},
    topReviewsLoaded: {},
    topReviewsLoading: false,
};

export default function reducer(
    state: SellerRatingsSlice = defaultSellerRatingsSlice,
    action: any = {}
): SellerRatingsSlice {
    switch (action.type) {
        case FETCH_COMMENTS_FAIL:
            return {
                ...state,
                commentsLoading: false,
            };
        case FETCH_RATINGS_FAIL:
            return {
                ...state,
                loading: false,
            };
        case FETCH_RATING_SUMMARY_FAIL:
            return {
                ...state,
                ratingSummaryLoading: false,
            };
        case FETCH_TOP_REVIEWS_FAIL:
            return {
                ...state,
                topReviewsLoading: false,
            };
        case FETCH_PERCENTILES_FAIL:
            return {
                ...state,
                percentilesLoading: false,
            };
        case FETCH_COMMENTS_REQUEST:
            return {
                ...state,
                commentsLoading: true,
            };
        case FETCH_RATINGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_RATING_SUMMARY_REQUEST:
            return {
                ...state,
                ratingSummaryLoading: true,
            };
        case FETCH_TOP_REVIEWS_REQUEST:
            return {
                ...state,
                topReviewsLoading: true,
            };
        case FETCH_PERCENTILES_REQUEST:
            return {
                ...state,
                percentilesLoading: true,
            };
        case FETCH_COMMENTS_SUCCESS:
            const existingComments = cloneDeep(state.commentsById) || {};
            const existingCommentsLoaded = cloneDeep(state.commentsLoaded) || {};
            const NewCommentsLoaded = {};
            NewCommentsLoaded[action.meta.sellerId] = action.meta.actionTime;
            return {
                ...state,
                commentsById: Object.assign(existingComments, action.payload),
                commentsLoaded: Object.assign(existingCommentsLoaded, NewCommentsLoaded),
                commentsLoading: false,
            };
        case FETCH_RATINGS_SUCCESS:
            const existingRating = cloneDeep(state.ratingSummaryById) || {};
            const existingReviews = cloneDeep(state.reviewsById) || {};
            const existingLoaded = cloneDeep(state.loaded) || {};
            const NewRatingLoaded = {};
            NewRatingLoaded[action.meta.sellerId] = action.meta.actionTime;
            return {
                ...state,
                loaded: Object.assign(existingLoaded, NewRatingLoaded),
                loading: false,
                ratingSummaryById: Object.assign(existingRating, {
                    [action.payload.houseId]: {
                        ...action.payload,
                    },
                }),
                reviewsById: Object.assign(existingReviews, {
                    [action.payload.houseId]: action.payload.reviews || [],
                }),
            };
        case FETCH_RATING_SUMMARY_SUCCESS:
            const ratingSummaryExisting = cloneDeep(state.ratingSummaryById) || {};
            const ratingSummaryExistingLoaded = cloneDeep(state.ratingSummaryLoaded) || {};
            const NewRatingSummaryLoaded = {};
            NewRatingSummaryLoaded[action.meta.sellerId] = action.meta.actionTime;

            return {
                ...state,
                ratingSummaryById: Object.assign(ratingSummaryExisting, {
                    [action.payload.houseId]: {
                        ...action.payload,
                    },
                }),
                ratingSummaryLoaded: Object.assign(ratingSummaryExistingLoaded, NewRatingSummaryLoaded),
                ratingSummaryLoading: false,
            };
        case FETCH_TOP_REVIEWS_SUCCESS:
            const existingTopReviews = cloneDeep(state.reviewsById) || {};
            const existingTopReviewsLoaded = cloneDeep(state.topReviewsLoaded) || {};
            const NewTopReviewsLoaded = {};
            NewTopReviewsLoaded[action.meta.sellerId] = action.meta.actionTime;

            return {
                ...state,
                reviewsById: Object.assign(existingTopReviews, {
                    [action.meta.sellerId]: action.payload || [],
                }),
                topReviewsLoaded: Object.assign(existingTopReviewsLoaded, NewTopReviewsLoaded),
                topReviewsLoading: false,
            };
        case FETCH_PERCENTILES_SUCCESS:
            const existingPercentiles = cloneDeep(state.percentilesById) || {};
            const existingPercentilesLoaded = cloneDeep(state.percentilesLoaded) || {};
            const NewPercentile = {};
            NewPercentile[action.meta.sellerId] = action.meta.actionTime;
            return {
                ...state,
                percentilesById: Object.assign(existingPercentiles, action.payload),
                percentilesLoaded: Object.assign(existingPercentilesLoaded, NewPercentile),
                percentilesLoading: false,
            };
        case OPEN_REVIEWS_MODAL:
            return {
                ...state,
                activeSeller: action.payload,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const sellerRatingsSlice = createSelector(stateSelector, (state) => state.sellerRatings);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(sellerRatingsSlice, (state) => {
    const ratingSummary = state.ratingSummaryById;
    const reviews = state.reviewsById;
    let ratingAndReviews = {};

    if (ratingSummary) {
        for (let ratingSellerId in ratingSummary) {
            if (reviews[ratingSellerId]) {
                ratingAndReviews[ratingSellerId] = {
                    ...ratingSummary[ratingSellerId],
                    reviews: reviews[ratingSellerId],
                };
            } else {
                ratingAndReviews[ratingSellerId] = ratingSummary[ratingSellerId];
            }
        }
    }

    if (reviews) {
        for (let reviewSellerId in reviews) {
            if (!ratingAndReviews[reviewSellerId]) {
                ratingAndReviews[reviewSellerId] = reviews[reviewSellerId];
            }
        }
    }

    return ratingAndReviews;
});

export const getActiveSeller = createSelector(sellerRatingsSlice, (state) => state.activeSeller || 0);

const commentsByIdSelector = createSelector(sellerRatingsSlice, (state) => state.commentsById || {});

const percentilesByIdSelector = createSelector(sellerRatingsSlice, (state) => state.percentilesById || {});

const commentsLoadedSelector = createSelector(sellerRatingsSlice, (state) => state.commentsLoaded || {});

const loadedSelector = createSelector(sellerRatingsSlice, (state) => state.loaded);

const ratingSummaryLoadedSelector = createSelector(sellerRatingsSlice, (state) => state.ratingSummaryLoaded || {});

const topReviewsLoadedSelector = createSelector(sellerRatingsSlice, (state) => state.topReviewsLoaded || {});

const percentilesLoadedSelector = createSelector(sellerRatingsSlice, (state) => state.percentilesLoaded || {});

export const getCommentsLoading = createSelector(sellerRatingsSlice, (state) => state.commentsLoading);

export const getIsLoading = createSelector(sellerRatingsSlice, (state) => state.loading);

export const getIsRatingSummaryLoading = createSelector(sellerRatingsSlice, (state) => state.ratingSummaryLoading);

export const getIsTopReviewsLoading = createSelector(sellerRatingsSlice, (state) => state.topReviewsLoading);

const getPercentilesLoading = createSelector(sellerRatingsSlice, (state) => state.percentilesLoading);

export const getLoadedCommentsById = createSelector([commentsLoadedSelector, idSelector], (loaded, id) => {
    return loaded[id] || 0;
});

export const getCommentsById = createSelector([commentsByIdSelector, idSelector], (byId, id) => {
    return byId[id] || {};
});

export const getLoadedSellerRatingById = createSelector([loadedSelector, idSelector], (loaded, id) => {
    return loaded[id] || 0;
});

export const getLoadedSellerTopReviewsById = createSelector([topReviewsLoadedSelector, idSelector], (loaded, id) => {
    return loaded[id] || 0;
});

export const getLoadedSellerRatingSummaryById = createSelector(
    [ratingSummaryLoadedSelector, idSelector],
    (loaded, id) => {
        return loaded[id] || 0;
    }
);

export const getSellerRatingById = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});
export const getSellerRatingTotalReviews = createSelector(getSellerRatingById, ({ totalReviews }) => totalReviews || 0);
export const getSellerRatingOverall = createSelector(getSellerRatingById, ({ overall }) => overall || 0);
export const getSellerRatingAllowPublicReviews = createSelector(getSellerRatingById, ({ allowPublicReviews }) =>
    Boolean(allowPublicReviews)
);

export const getDisplayRating = createSelector(getSellerRatingById, (sellerRating) => {
    if (sellerRating) {
        return sellerRating['totalReviews'] >= 5 ? sellerRating['overall'] : 0;
    }
    return 0;
});

const getLoadedPercentilesById = createSelector([percentilesLoadedSelector, idSelector], (byId, id) => {
    return byId[id] || 0;
});

export const getSellerPercentilesById = createSelector([percentilesByIdSelector, idSelector], (percentilesById, id) => {
    return percentilesById[id] || {};
});

const shouldFetchSellerCommentsByIdIfNeeded = (state, sellerId) => {
    const existingRating = getLoadedCommentsById(state, sellerId);
    const isLoading = getCommentsLoading(state);
    if (isLoading) {
        return false;
    }
    if (!existingRating) {
        return true;
    } else {
        const diff = Date.now() - existingRating;
        return diff > REDUX_STORE_TIME;
    }
};

const shouldFetchSellerRatingsByIdIfNeeded = (state, sellerId) => {
    const existingRating = getLoadedSellerRatingById(state, sellerId);
    const isLoading = getIsLoading(state);
    if (isLoading) {
        return false;
    }
    if (!existingRating) {
        return true;
    } else {
        const diff = Date.now() - existingRating;
        return diff > REDUX_STORE_TIME;
    }
};

const shouldFetchSellerRatingSummaryByIdIfNeeded = (state, sellerId) => {
    const existingRating = getLoadedSellerRatingSummaryById(state, sellerId);
    const isLoading = getIsRatingSummaryLoading(state);

    if (isLoading) {
        return false;
    }

    if (!existingRating) {
        return true;
    } else {
        const diff = Date.now() - existingRating;
        return diff > REDUX_STORE_TIME;
    }
};

const shouldFetchSellerLatestTopReviewsByIdIfNeeded = (state, sellerId) => {
    const existingReviews = getLoadedSellerTopReviewsById(state, sellerId);
    const isLoading = getIsTopReviewsLoading(state);

    if (isLoading) {
        return false;
    }

    if (!existingReviews) {
        return true;
    } else {
        const diff = Date.now() - existingReviews;
        return diff > REDUX_STORE_TIME;
    }
};

const shouldFetchSellerPercentilesByIdIfNeeded = (state, sellerId) => {
    const existingPercentiles = getLoadedPercentilesById(state, sellerId);
    const isLoading = getPercentilesLoading(state);
    if (isLoading) {
        return false;
    }
    if (!existingPercentiles) {
        return true;
    } else {
        const diff = Date.now() - existingPercentiles;
        return diff > REDUX_STORE_TIME;
    }
};

export type SellerRatingsMeta = {
    filter?: string;
    page?: number;
    pageSize?: number;
    sellerId: number;
    sort?: string;
};

/* ACTION CREATORS */
export const loadSellerRatingsById =
    ({ filter = '', page = 1, pageSize = 8, sellerId, sort }: SellerRatingsMeta) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                type: FETCH_RATINGS_REQUEST,
            });

            const response = await api.fetchHouseReviewData({
                authToken,
                deployment,
                filter,
                houseId: sellerId,
                page,
                pageSize,
                sort,
            });
            dispatch({
                meta: { actionTime: Date.now(), sellerId },
                payload: response.payload,
                type: FETCH_RATINGS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: FETCH_RATINGS_FAIL,
            });
        }
    };

export const loadSellerRatingSummaryById =
    (sellerId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                type: FETCH_RATING_SUMMARY_REQUEST,
            });

            const response = await api.fetchHouseRatingData({
                authToken,
                deployment,
                houseId: sellerId,
            });
            dispatch({
                meta: { actionTime: Date.now(), sellerId },
                payload: response.payload,
                type: FETCH_RATING_SUMMARY_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: FETCH_RATING_SUMMARY_FAIL,
            });
        }
    };

export const loadSellerLatestTopReviewsById =
    (sellerId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            dispatch({
                type: FETCH_TOP_REVIEWS_REQUEST,
            });

            const response = await api.fetchHouseLatestTopReviewsData({
                authToken,
                deployment,
                houseId: sellerId,
            });
            dispatch({
                meta: { actionTime: Date.now(), sellerId },
                payload: response.payload,
                type: FETCH_TOP_REVIEWS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: FETCH_TOP_REVIEWS_FAIL,
            });
        }
    };

const loadSellerPercentilesById = (sellerId) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            type: FETCH_PERCENTILES_REQUEST,
        });
        const response = await api.fetchHousePercentiles({
            authToken,
            deployment,
            houseId: sellerId,
        });
        dispatch({
            meta: { actionTime: Date.now(), sellerId },
            payload: response,
            type: FETCH_PERCENTILES_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_PERCENTILES_FAIL,
        });
    }
};

const loadSellerCommentsById = (sellerId) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            type: FETCH_COMMENTS_REQUEST,
        });
        const response = await api.fetchHouseComments({
            authToken,
            deployment,
            houseId: sellerId,
        });
        dispatch({
            meta: { actionTime: Date.now(), sellerId },
            payload: response,
            type: FETCH_COMMENTS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: FETCH_COMMENTS_FAIL,
        });
    }
};

export const fetchSellerRatingsByIdIfNeeded =
    ({ filter = '', page = 1, pageSize = 8, sellerId, sort }: SellerRatingsMeta) =>
    (dispatch: AppDispatch, getState: AppGetState) => {
        if (shouldFetchSellerRatingsByIdIfNeeded(getState(), sellerId)) {
            return dispatch(
                loadSellerRatingsById({
                    filter,
                    page,
                    pageSize,
                    sellerId,
                    sort,
                })
            );
        }
        return Promise.resolve();
    };

export const fetchSellerRatingSummaryByIdIfNeeded =
    (sellerId: number) => (dispatch: AppDispatch, getState: AppGetState) => {
        if (shouldFetchSellerRatingSummaryByIdIfNeeded(getState(), sellerId)) {
            return dispatch(loadSellerRatingSummaryById(sellerId));
        }
        return Promise.resolve();
    };

export const fetchSellerLatestTopReviewsByIdIfNeeded =
    (sellerId: number) => (dispatch: AppDispatch, getState: AppGetState) => {
        if (shouldFetchSellerLatestTopReviewsByIdIfNeeded(getState(), sellerId)) {
            return dispatch(loadSellerLatestTopReviewsById(sellerId));
        }
        return Promise.resolve();
    };

export const fetchSellerPercentilesByIdIfNeeded =
    (sellerId: number) => (dispatch: AppDispatch, getState: AppGetState) => {
        if (shouldFetchSellerPercentilesByIdIfNeeded(getState(), sellerId)) {
            return dispatch(loadSellerPercentilesById(sellerId));
        }
        return Promise.resolve();
    };

export const fetchSellerCommentsByIdIfNeeded = (sellerId: number) => (dispatch: AppDispatch, getState: AppGetState) => {
    if (shouldFetchSellerCommentsByIdIfNeeded(getState(), sellerId)) {
        return dispatch(loadSellerCommentsById(sellerId));
    }
    return Promise.resolve();
};
