import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getClientIpAddress } from './browser';
import { getDeployment } from './config';
import { getIsBot } from './analytics';
import { getUserExcludedHouses } from './search/exclusions/searchExclusions.selectors';
import {
    LOAD_SIMILAR_SOLD_ITEMS_FAIL,
    LOAD_SIMILAR_SOLD_ITEMS_REQUEST,
    LOAD_SIMILAR_SOLD_ITEMS_SUCCESS,
    LOAD_SIMILAR_SOLD_ITEMS_SUCCESS_ACTION,
} from './actions';
import { SimilarItemInfo as SimilarItemInfoType } from '@/types/SimilarItemInfo';
import api from '@/redux/api/similarItems';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* REDUCER */
export type SimilarSoldItemsState = {
    byId: { [id: number]: number[] };
    loaded: { [id: number]: number };
    loading: number[];
};

export const emptyDefaultSimilarSoldItemsState: SimilarSoldItemsState = {
    byId: { 1000: [1, 2, 3, 4] },
    loaded: { 123: 1000 },
    loading: [5, 6, 7, 8],
};

export const defaultSimilarSoldItemsSlice: SimilarSoldItemsState = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(
    state: SimilarSoldItemsState = defaultSimilarSoldItemsSlice,
    action: any = {}
): SimilarSoldItemsState {
    switch (action.type) {
        case LOAD_SIMILAR_SOLD_ITEMS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.itemId]),
            };
        case LOAD_SIMILAR_SOLD_ITEMS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_SIMILAR_SOLD_ITEMS_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.itemId]: action.payload.map((i) => i.itemId),
                },
                loaded: {
                    ...state.loaded,
                    [action.meta.itemId]: action.meta.actionTime,
                },
                loading: difference(state.loading, [action.meta.itemId]),
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const similarSoldItemsSlice = createSelector(stateSelector, (state) => state.similarSoldItems);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(similarSoldItemsSlice, (state) => state.byId);

const loadedSelector = createSelector(similarSoldItemsSlice, (state) => state.loaded);

const loadingSelector = createSelector(similarSoldItemsSlice, (state) => state.loading);

export const getSimilarItemIds = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);

const getLoadTime = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const shouldFetch = (state, itemId) => {
    if (!itemId) {
        return false;
    }
    const item = getSimilarItemIds(state, itemId);
    if (item.length) {
        const loaded = getLoadTime(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isLoading(state, itemId);
    return !loading;
};

/* ACTION CREATORS */
const loadSimilarSoldItems = (similarItemInfo: SimilarItemInfoType) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const ipAddress = getClientIpAddress(state);
        const isBot = getIsBot(state);
        const searchExclusions = getUserExcludedHouses(state);

        dispatch({
            payload: similarItemInfo.itemId,
            type: LOAD_SIMILAR_SOLD_ITEMS_REQUEST,
        });

        const { payload } = await api.fetchSimilarSoldItems({
            authToken,
            deployment,
            ipAddress,
            isBot,
            searchExclusions,
            similarItemInfo,
        });

        return dispatch({
            meta: {
                actionTime: Date.now(),
                itemId: similarItemInfo.itemId,
            },
            payload: payload.items,
            type: LOAD_SIMILAR_SOLD_ITEMS_SUCCESS,
        } as LOAD_SIMILAR_SOLD_ITEMS_SUCCESS_ACTION);
    } catch (error) {
        return dispatch({
            error: true,
            meta: { itemId: similarItemInfo.itemId },
            payload: error,
            type: LOAD_SIMILAR_SOLD_ITEMS_FAIL,
        });
    }
};

export const fetchSimilarSoldItemsIfNeeded =
    (similarItemInfo: SimilarItemInfoType) => async (dispatch: AppDispatch, getState: AppGetState) => {
        if (similarItemInfo?.itemId && shouldFetch(getState(), similarItemInfo.itemId)) {
            return dispatch(loadSimilarSoldItems(similarItemInfo));
        }
        return Promise.resolve();
    };
