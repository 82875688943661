import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchAllBidderBiddingInfoIfNeeded } from '@/redux/modules/bidder/biddingInfoAll/bidderBiddingInfoAll.actions';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getAuthToken, getUserData } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { getIsTimedPlus, getItemModelCatalogId } from '@/redux/modules/item/summary/itemSummary.selectors';
import { getRetractedAbsenteeBidAnalytics } from '@/redux/modules/analytics';
import { postRetractBid, postRetractTimedPlusBid } from '@/redux/modules/retractBid/retractBid.api';
import { SubmitRetractBidParams, SubmitRetractBidPayload } from '@/redux/modules/retractBid/retractBid.types';
import { trackBidRetractedAnalytics } from '@/utils/analytics';

export const submitRetractBid = createAsyncThunk<SubmitRetractBidPayload, SubmitRetractBidParams>(
    'la/ui/retractBid/submitRetractBid',
    async ({ itemId, location, retractionExplanation, retractionReason }, { dispatch, getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const catalogId = getItemModelCatalogId(state, itemId);
        const isTimedPlus = getIsTimedPlus(state, itemId);
        const { bidderId } = getUserData(state);

        if (isTimedPlus) {
            await postRetractTimedPlusBid({
                authToken,
                catalogId,
                deployment,
                itemId,
                retractionExplanation,
                retractionReason,
            });
        } else {
            await postRetractBid({
                authToken,
                bidderId,
                deployment,
                itemId,
                retractionExplanation,
                retractionReason,
            });
        }

        await dispatch(fetchAllBidderBiddingInfoIfNeeded(true));

        const analytics = getRetractedAbsenteeBidAnalytics(state, itemId, retractionReason, retractionExplanation);

        trackBidRetractedAnalytics({
            pageName: getAmplitudePageNameFromPath(location.pathname),
            url: getFullUrl(deployment, location.pathname),
        });

        return { analytics, itemId };
    }
);
