import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { SET_SIGNIFYD_SESSION_ID } from './actions';

// reducer
export type SignifySlice = {
    sessionId: string;
};

export const defaultSignifySlice: SignifySlice = {
    sessionId: '',
};

export default function reducer(state: SignifySlice = defaultSignifySlice, action: any = {}): SignifySlice {
    switch (action.type) {
        case SET_SIGNIFYD_SESSION_ID:
            return {
                ...state,
                sessionId: action.payload,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const signifydSlice = createSelector(stateSelector, (state) => state.signifyd);

export const getSignifydSessionId = createSelector(signifydSlice, (state) => state.sessionId);

/* ACTION CREATORS */
export const saveSignifydSessionId = (sessionId: string) => ({
    payload: sessionId,
    type: SET_SIGNIFYD_SESSION_ID,
});
