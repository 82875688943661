import { ApiHosts, handleResponseOld, handleResponseWithNon200Errors, makePost } from '../../api/helpers';

/**
 * @category Auction-Engine API
 * @see postPlaceLiveBidResponse
 */
type PostPlaceLiveBidResponse = {
    error: boolean;
    payload: string;
};

/**
 * @category Auction-Engine API
 * @see postPlaceLiveBid
 */
type PostPlaceLiveBidParams = {
    authToken: string;
    bidAmount: number;
    catalogId: number;
    deployment: string;
    itemId: number;
    sellerId: number;
    source: string;
};

/**
 * POST a bid to clerking API for live auction bidding
 * @function postPlaceLiveBid
 * @category Auction-Engine API
 * @param {PostPlaceLiveBidParams}
 * @see https://auction-service-DEPLOYMENT.liveauctioneers.com/clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/bids/place
 */
export const postPlaceLiveBid = ({
    authToken,
    bidAmount,
    catalogId,
    deployment,
    itemId,
    sellerId,
    source,
}: PostPlaceLiveBidParams) => {
    return new Promise<PostPlaceLiveBidResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `clerk/houses/${sellerId}/auctions/${catalogId}/lots/${itemId}/bids/place`,
            authToken,
            deployment,
            path: ApiHosts.AuctionEngine,
        });
        request.send({
            amount: bidAmount,
            bidSource: source,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                manuallyHandledErrorCodes: [406, 422],
                reject,
                request,
                resolve,
                response,
            })
        );
    });
};

/**
 * @category Mainhost API
 * @see postRetractBid
 */
type PostRetractBidParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    itemId: number;
    retractionExplanation: any;
    retractionReason: any;
};

/**
 * makes POST request to mainhost retract bid API
 * @function postRetractBid
 * @category Mainhost API
 * @param {PostRetractBidParams}
 * @see https://mainhost-DEPLOYMENT.liveauctioneers.com/api/bidders/${bidderId}/biditems/${itemId}/retract
 * @see https://github.com/LIVEauctioneers/mainhost-prod/blob/master/httpdocs/api/bidders.php#L1540
 */
export const postRetractBid = ({
    authToken,
    bidderId,
    deployment,
    itemId,
    retractionExplanation,
    retractionReason,
}: PostRetractBidParams) => {
    return new Promise<void>((resolve, reject) => {
        const request = makePost({
            apiPath: `api/bidders/${bidderId}/biditems/${itemId}/retract`,
            authToken,
            deployment,
            path: ApiHosts.Mainhost,
        });
        request.send({
            retractionExplanation,
            retractionReason,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
};

/**
 * makes POST request auction-service retract bid
 * @function postRetractTimedPlusBid
 * @category Auction Service
 * @param {PostRetractBidParams}
 * @see https://auction-service-DEPLOYMENT.liveauctioneers.com/clerk/bidder/retract?auctionId=${auctionId}&lotId=${lotId}
 */
export const postRetractTimedPlusBid = ({
    authToken,
    catalogId,
    deployment,
    itemId,
    retractionExplanation,
    retractionReason,
}: Omit<PostRetractBidParams, 'bidderId'> & { catalogId: number }) => {
    return new Promise<void>((resolve, reject) => {
        const request = makePost({
            apiPath: 'clerk/bidder/retract',
            authToken,
            deployment,
            path: ApiHosts.AuctionService,
            queryParams: {
                auctionId: catalogId,
                lotId: itemId,
            },
        });
        request.send({
            retractionExplanation,
            retractionReason,
        });
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });
};
