import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { AttributionData, getSetPasswordAnalytics } from './analytics';
import { createSelector } from '@reduxjs/toolkit';
import { DISMISS_MODAL } from './actions';
import { getAuthToken, getBidderEmail, getUserData } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { getNewSignupSearchParams, setNewSignupSearchParams, submitSaveSearch } from './saveSearch';
import { IntlShape } from '@liveauctioneers/hammer-ui-core/intl';
import { LOG_OUT_BIDDER } from './account/logout/logout.actions';
import { postSetPassword } from '@/redux/modules/account/createAccount/createAccount.api';
import { submitLogin } from '@/redux/modules/account/login/login.actions';
import { toast } from '@liveauctioneers/hammer-ui-core/toast';
import type { FeatureFlagType } from '@kameleoon/javascript-sdk';

/* Action Types */
export const SET_PASSWORD_FAIL = 'la/ui/setPassword/FAIL';
export const SET_PASSWORD_REQUEST = 'la/ui/setPassword/REQUEST';
export const SET_PASSWORD_SUCCESS = 'la/ui/setPassword/SUCCESS';

// reducer
export type SetPasswordSlice = {
    error: boolean;
    errorMessage: string;
    submitted: boolean;
    success: boolean;
};

export const defaultSetPasswordSlice: SetPasswordSlice = {
    error: false,
    errorMessage: '',
    submitted: false,
    success: false,
};

export default function reducer(state: SetPasswordSlice = defaultSetPasswordSlice, action: any = {}): SetPasswordSlice {
    switch (action.type) {
        case SET_PASSWORD_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
                submitted: false,
                success: false,
            };
        case SET_PASSWORD_REQUEST:
            return {
                ...state,
                error: false,
                errorMessage: '',
                submitted: true,
                success: false,
            };
        case SET_PASSWORD_SUCCESS:
            return {
                ...state,
                error: false,
                errorMessage: '',
                submitted: false,
                success: true,
            };
        case DISMISS_MODAL:
        case LOG_OUT_BIDDER:
            return { ...defaultSetPasswordSlice };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;

export const getUiSetPassword = createSelector(stateSelector, (state) => state.setPassword);

/* ACTION CREATORS */
export const submitSetPassword =
    (
        password: string,
        attributionData: AttributionData,
        formatMessage: IntlShape['formatMessage'],
        featureFlags: FeatureFlagType[],
        shouldCloseModal?: boolean
    ) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        let emailAddress = '';
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            emailAddress = getBidderEmail(state);
            const userData = getUserData(state);

            await dispatch({
                type: SET_PASSWORD_REQUEST,
            });
            await postSetPassword({ authToken, deployment, password });

            // bidderID from token not response
            const analytics = getSetPasswordAnalytics(getState(), userData.bidderId, attributionData);

            await dispatch({
                meta: {
                    actionTime: Date.now(),
                    analytics,
                },
                payload: userData,
                type: SET_PASSWORD_SUCCESS,
            });
            await dispatch(
                submitLogin({
                    emailAddress,
                    featureFlags,
                    location: {
                        hash: '',
                        key: '',
                        pathname: '',
                        search: '',
                        state: null,
                    },
                    // this form is for the account creation process, so it will always be a new account
                    newAccount: true,
                    password,
                    shouldCloseModal,
                })
            );

            // submit save search if this completes a new account signup and a search exists
            const newSignupSearchParams = getNewSignupSearchParams(state);
            if (Object.keys(newSignupSearchParams).length > 0) {
                await dispatch(submitSaveSearch(newSignupSearchParams, true));
                toast('success', {
                    description: formatMessage({
                        id: 'toastMessages.descriptions.followingSearch',
                    }),
                    title: formatMessage({
                        id: 'toastMessages.titles.success',
                    }),
                });
                dispatch(setNewSignupSearchParams({}));
            }
        } catch (error) {
            dispatch({
                error: true,
                meta: { emailAddress },
                payload: error.error_message || error.message || error,
                type: SET_PASSWORD_FAIL,
            });
        }
    };
