import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import {
    DISMISS_MODAL,
    OPEN_SEND_SELLER_CONSIGNMENT_MESSAGE_MODAL,
    OPEN_SEND_SELLER_MESSAGE_MODAL,
    OPEN_SEND_SELLER_PHOTO_REQUEST_MODAL,
} from './actions';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { Location } from 'react-router-dom';
import { PhotoRequestType } from '@/components/Modals/SendSellerPhotoRequest/types/SendSellerPhotoRequest';
import {
    postStartConversationMessage,
    postStartConversationWithAttachment,
} from '@/redux/modules/conversation/messages/conversationMessages.api';
import { trackSendSellerMessage } from './analytics';

/* Action Types */
export const SEND_SELLER_MESSAGE_FAIL = 'la/ui/sendSellerMessage/FAIL';
export const SEND_SELLER_MESSAGE_REQUEST = 'la/ui/sendSellerMessage/REQUEST';
export const SEND_SELLER_MESSAGE_SUCCESS = 'la/ui/sendSellerMessage/SUCCESS';

// reducer
export type SendSellerMessageSlice = {
    error: boolean;
    imageGalleryIndex?: number;
    imageGalleryLength?: number;
    messageDetails: MessageDetails;
    requestType?: PhotoRequestType;
    sellerId: number;
    sellerName: string;
    subject: string;
    submitted: boolean;
    success: boolean;
    title: string;
};

export const defaultSendSellerMessageSlice: SendSellerMessageSlice = {
    error: false,
    messageDetails: undefined,
    sellerId: 0,
    sellerName: '',
    subject: '',
    submitted: false,
    success: false,
    title: '',
};

export default function reducer(
    state: SendSellerMessageSlice = defaultSendSellerMessageSlice,
    action: any = {}
): SendSellerMessageSlice {
    switch (action.type) {
        case OPEN_SEND_SELLER_PHOTO_REQUEST_MODAL:
            return {
                ...state,
                imageGalleryIndex: action.payload.imageGalleryIndex,
                imageGalleryLength: action.payload.imageGalleryLength,
                messageDetails: action.payload.messageDetails,
                requestType: action.payload.requestType,
                sellerId: action.payload.sellerId,
                sellerName: action.payload.sellerName,
                subject: action.payload.subject,
                title: action.payload.title,
            };
        case OPEN_SEND_SELLER_CONSIGNMENT_MESSAGE_MODAL:
        case OPEN_SEND_SELLER_MESSAGE_MODAL:
            return {
                ...state,
                messageDetails: action.payload.messageDetails,
                sellerId: action.payload.sellerId,
                sellerName: action.payload.sellerName,
                subject: action.payload.subject,
                title: action.payload.title,
            };
        case SEND_SELLER_MESSAGE_REQUEST:
            return {
                ...state,
                error: false,
                submitted: true,
                success: false,
            };
        case DISMISS_MODAL:
            return {
                ...state,
                messageDetails: undefined,
                sellerId: 0,
                sellerName: '',
                subject: '',
                success: false,
                title: '',
            };
        case SEND_SELLER_MESSAGE_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        case SEND_SELLER_MESSAGE_FAIL:
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;

export const getUiSendSellerMessage = createSelector(stateSelector, (state) => state.sendSellerMessage);
export const getUiSendSellerMessageSuccess = createSelector(getUiSendSellerMessage, (state) => state.success);
export const getUiSendSellerMessageSubmitted = createSelector(getUiSendSellerMessage, (state) => state.submitted);
export const getUiSendSellerMessageError = createSelector(getUiSendSellerMessage, (state) => state.error);

/* ACTION CREATORS */
export type MessageDetails = {
    catalogId: number;
    itemId: number;
    location: Location;
    message: string;
    sellerId: number;
    subject: string;
};
export const submitStartConversation =
    (messageDetails: MessageDetails) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const bidderId = getBidderId(state);

            const { catalogId, itemId, location, message, sellerId, subject } = messageDetails;

            dispatch({
                type: SEND_SELLER_MESSAGE_REQUEST,
            });
            const response = await postStartConversationMessage({
                authToken,
                bidderId,
                catalogId,
                deployment,
                houseId: sellerId,
                lotId: itemId,
                messageBody: message,
                subject,
            });
            dispatch({
                payload: response.payload,
                type: SEND_SELLER_MESSAGE_SUCCESS,
            });
            if (location?.pathname.includes('/item/')) {
                dispatch(
                    trackSendSellerMessage({
                        catalogId,
                        houseId: sellerId,
                        itemId,
                    })
                );
            }
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SEND_SELLER_MESSAGE_FAIL,
            });
        }
    };

export const startConversationWithAttachment =
    ({
        attachments,
        messageBody,
        sellerId,
        subject,
    }: {
        attachments: Array<any>;
        messageBody: string;
        sellerId: number;
        subject: string;
    }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const bidderId = getBidderId(state);

            dispatch({
                type: SEND_SELLER_MESSAGE_REQUEST,
            });
            const response = await postStartConversationWithAttachment({
                attachments: attachments,
                authToken,
                bidderId,
                deployment,
                messageBody,
                sellerId,
                subject,
            });
            return dispatch({
                meta: { actionTime: Date.now() },
                payload: response.payload,
                type: SEND_SELLER_MESSAGE_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: SEND_SELLER_MESSAGE_FAIL,
            });
        }
    };
