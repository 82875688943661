import { ApiHosts, handleResponseOld, handleResponseWithNon200Errors, makeGet } from '../../api/helpers';
import { AugmentedSearchQuery } from '@/types/search/searchQuery';
import { CategorySearchResult, SearchResult } from '@/redux/modules/search/search.types';
import shuffle from 'lodash/shuffle';

export const transformSearchResult = (searchResult: SearchResult) => {
    if (typeof searchResult.payload === 'object' && searchResult.payload?.itemIDs) {
        searchResult.payload.itemIDs = shuffle(searchResult.payload.itemIDs);
    }
    return {
        error: searchResult.error,
        payload: searchResult.payload,
    };
};

/**
 * @category Search API
 * @see fetchCategoryResults
 */
type FetchCategoryResultsParams = {
    authToken: string;
    deployment: string;
    ids: any;
    ipAddress: string;
    searchQuery: any;
};
/**
 * fetches category search results via Get to Search API
 * @function fetchCategoryResults
 * @category Search API
 * @param {FetchCategoryResultsParams}
 * @return {Promise<CategorySearchResult>}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/v4/categoryweb?ids=${encodedIds}&parameters=${encodedParams}
 */
export const fetchCategoryResults = ({
    authToken,
    deployment,
    ids,
    ipAddress,
    searchQuery,
}: FetchCategoryResultsParams): Promise<CategorySearchResult> =>
    new Promise<CategorySearchResult>((resolve, reject) => {
        const encodedIds = encodeURIComponent(JSON.stringify(ids));
        let buyNow = '';
        if (searchQuery.buyNow) {
            buyNow = `&buyNow=${encodeURIComponent(JSON.stringify(searchQuery.buyNow))}`;
            delete searchQuery.buyNow;
        }
        let searchString = `${ApiHosts.Search}?ids=${encodedIds}${buyNow}`;
        const encodedParams = encodeURIComponent(JSON.stringify(searchQuery));
        searchString = `${searchString}&parameters=${encodedParams}`;

        const request = makeGet({
            apiPath: 'v4/categoryweb',
            authToken,
            deployment,
            ipAddress,
            // @ts-expect-error
            //  path *wants* an ApiHosts enum value.
            //  However, this API request did some complex URL building logic.
            //  Thus, I will leave it here until some brave soul chooses to fix it.
            path: searchString,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Search API
 * @see fetchPromotedSearchResults
 */
type FetchPromotedSearchResultsParams = {
    authToken: string;
    deployment: string;
    ids?: any;
    ipAddress: string;
    searchQuery: any;
};
/**
 * fetches promoted search results via Get to Search API
 * @function fetchPromotedSearchResults
 * @category Search API
 * @param {FetchPromotedSearchResultsParams}
 * @return {Promise<SearchResult>}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/v4/categoryweb?ids=${encodedIds}&parameters=${encodedParams}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/v4/web?ids=${encodedIds}&parameters=${encodedParams}
 */
export const fetchPromotedSearchResults = ({
    authToken,
    deployment,
    ids,
    ipAddress,
    searchQuery,
}: FetchPromotedSearchResultsParams): Promise<SearchResult> =>
    // TODO should be the new type. (Or a union technically)
    new Promise<SearchResult>((resolve, reject) => {
        // temp for phased rollout of search
        let searchString = `${ApiHosts.Search}?promoted=1&parameters=${encodeURIComponent(
            JSON.stringify(searchQuery)
        )}`;

        let apiPath = 'v4/web';
        // TODO this should not be done here. Make the API calls just call the API.
        if (ids && ids.length) {
            searchString = `${searchString}&ids=${encodeURIComponent(JSON.stringify(ids))}`;
            apiPath = 'v4/categoryweb';
        } else if (searchQuery.campaignId && searchQuery.campaignId > 0) {
            searchString = `${searchString}&ids=${encodeURIComponent('[]')}`;
            apiPath = 'v4/categoryweb';
        }

        const request = makeGet({
            apiPath,
            authToken,
            deployment,
            ipAddress,
            // @ts-expect-error
            //  path *wants* an ApiHosts enum value.
            //  However, this API request did some complex URL building logic.
            //  Thus, I will leave it here until some brave soul chooses to fix it.
            path: searchString,
        });

        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformSearchResult,
            })
        );
    });

/**
 * @category Search API
 * @see fetchSearchFacetsResults
 */
type FetchSearchFacetsResultsParams = {
    authToken: string;
    deployment: string;
    ipAddress: string;
    searchQuery: any;
};
/**
 * Fetches a faceted set of search results via Get to Search API
 * @function fetchSearchFacetsResults
 * @category Search API
 * @param {FetchSearchFacetsResultsParams}
 * @return {Promise<SearchResult>}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/faceted?parameters=${encodedParams}
 */
export const fetchSearchFacetsResults = ({
    authToken,
    deployment,
    ipAddress,
    searchQuery,
}: FetchSearchFacetsResultsParams): Promise<SearchResult> =>
    new Promise<SearchResult>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'faceted',
            authToken,
            deployment,
            ipAddress,
            // @ts-expect-error
            //  path *wants* an ApiHosts enum value.
            //  However, this API request did some complex URL building logic.
            //  Thus, I will leave it here until some brave soul chooses to fix it.
            path: `${ApiHosts.Search}?parameters=${encodeURIComponent(JSON.stringify(searchQuery))}`,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Search API
 * @see fetchSearchResults
 */
type FetchSearchResultsParams = {
    authToken: string;
    deployment: string;
    geoCoordinates: any;
    ipAddress: string;
    searchQuery: AugmentedSearchQuery;
    useGeoLocation: boolean;
};
/**
 * Fetches search results via Get to Search API
 * @function fetchSearchResults
 * @category Search API
 * @param {FetchSearchResultsParams}
 * @param {Promise<SearchResult>}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/web?parameters=${encodedParams}
 */
export const fetchSearchResults = ({
    authToken,
    deployment,
    geoCoordinates,
    ipAddress,
    searchQuery,
    useGeoLocation = false,
}: FetchSearchResultsParams): Promise<SearchResult> =>
    new Promise<SearchResult>((resolve, reject) => {
        let buyNow = '';
        if (searchQuery.buyNow) {
            buyNow = `&buyNow=${encodeURIComponent(JSON.stringify(searchQuery.buyNow))}`;
            delete searchQuery.buyNow;
        }
        if (useGeoLocation) {
            searchQuery.useGeo = true;
        }

        if (Boolean(geoCoordinates?.latitude) && Boolean(geoCoordinates?.longitude)) {
            searchQuery.lat = `${geoCoordinates.latitude}`;
            searchQuery.long = `${geoCoordinates.longitude}`;
        }

        /*
         * This is stupid but for some reason it's an array of a string true. Fix this when we fix search :)
         * @see https://liveauctioneers.slack.com/archives/C9LU1NR5Y/p1643056149066600?thread_ts=1643047891.062100&cid=C9LU1NR5Y
         */
        if (searchQuery.topRated) {
            searchQuery.topRated = true;
        }

        let searchString = `${ApiHosts.Search}?parameters=${encodeURIComponent(JSON.stringify(searchQuery))}${buyNow}`;
        const request = makeGet({
            apiPath: 'v4/web',
            authToken,
            deployment,
            ipAddress,
            // @ts-expect-error
            //  path *wants* an ApiHosts enum value.
            //  However, this API request did some complex URL building logic.
            //  Thus, I will leave it here until some brave soul chooses to fix it.
            path: searchString,
            useCacheKey: false,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
