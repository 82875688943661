import { createSelector } from '@reduxjs/toolkit';
import { getUserExcludedFacets } from '@/redux/modules/search/exclusions/searchExclusions.selectors';
import { GlobalState } from '@/redux/store';
import { searchFacetsSelector } from '@/redux/modules/searchFacets';
import { ShippingFacetOption } from '@liveauctioneers/caterwaul-components/lib/types/Facet';
import { ZeroResultsContainerCategory } from '@/components/ZeroResults/ZeroResultsContainer';
import cloneDeep from 'lodash/cloneDeep';
import FacetTypes from '@/types/search/enums/facetTypes';
import uniqBy from 'lodash/uniqBy';

export const annotateFacetExclusions = (facets: any[] = [], exclusions: any = {}) =>
    facets.map<any>((facet) =>
        !exclusions[facet.id]
            ? facet
            : {
                  ...facet,
                  excludable: true,
                  options: facet.options.map((option) => ({
                      ...option,
                      excluded: exclusions[facet.id].includes(option.id),
                  })),
              }
    );

// Search selectors can get very, very complex.
// Because of that, circular dependencies are going to be common.
// To avoid that, this file should contain selectors that pull from multiple slices.
// When it's all minified it would run fine, since everything's in one file,
// but test runs exploderate. 💥

const searchSliceSelector = ({ search }: GlobalState) => search;
const idSelector = (_: GlobalState, id: number | boolean | string) => id;
const idSecondSelector = (state: GlobalState, _, id: string) => id;

export const getRawUpcomingSearchFacets = createSelector(
    searchFacetsSelector,
    getUserExcludedFacets,
    (facets, exclusions) => annotateFacetExclusions(facets.upcoming, exclusions)
);

export const getRawArchivedSearchFacets = createSelector(
    searchFacetsSelector,
    getUserExcludedFacets,
    (facets, exclusions) => annotateFacetExclusions(facets.archived, exclusions)
);

const getAllCategories = (returnCategories: any[], facets: any[], level: number) => {
    level++;
    facets.forEach(({ categories, count, id, name }) => {
        returnCategories.push({ count, id, level, name });
        if (categories) {
            getAllCategories(returnCategories, categories, level);
        }
    });
};

// For each key in the exclusions object, find the raw facet with that key, and add
// it to the search facets array
export const getArchivedSearchFacets = createSelector(
    [searchSliceSelector, getUserExcludedFacets, getRawArchivedSearchFacets],
    (search, facetExclusions, rawFacets) => {
        const facets = cloneDeep(search.facets) || [];

        // for each exclusion, find the corresponding raw facet option, and add it
        Object.keys(facetExclusions).forEach((complexFacetId) => {
            const index = facets.findIndex((f) => f.id === complexFacetId);
            const complexFacet = rawFacets.find((f) => f.id === complexFacetId);
            if (index === -1 || !complexFacet || !complexFacet.options) {
                return;
            }
            // add excluded options to the options present in the search results
            facets[index].options = facetExclusions[complexFacetId].reduce((options, exclusion) => {
                const includedOptionsIndex = options.findIndex((o) => o.id === exclusion);
                const rawFacetOption = complexFacet.options.find((o) => o.id === exclusion);
                if (!rawFacetOption) {
                    return options;
                }
                // remove excluded facet item from list, if found
                if (includedOptionsIndex >= 0) {
                    options.splice(includedOptionsIndex, 1);
                }
                // return options with excluded facet absent from list
                return options;
            }, facets[index].options || []);
        });
        return annotateFacetExclusions(facets, facetExclusions);
    }
);

export const getUpcomingSearchFacets = createSelector(
    [searchSliceSelector, getUserExcludedFacets, getRawUpcomingSearchFacets],
    (search, facetExclusions, rawFacets) => {
        const facets = cloneDeep(search.facets) || [];

        // for each exclusion, find the corresponding raw facet option, and add it
        Object.keys(facetExclusions).forEach((complexFacetId) => {
            const index = facets.findIndex((f) => f.id === complexFacetId);
            const complexFacet = rawFacets.find((f) => f.id === complexFacetId);
            if (index === -1 || !complexFacet || !complexFacet.options) {
                return;
            }
            // add excluded options to the options present in the search results
            facets[index].options = facetExclusions[complexFacetId].reduce((options, exclusion) => {
                const includedOptionsIndex = options.findIndex((o) => o.id === exclusion);
                const rawFacetOption = complexFacet.options.find((o) => o.id === exclusion);
                if (!rawFacetOption) {
                    return options;
                }
                // remove excluded facet item from list, if found
                if (includedOptionsIndex >= 0) {
                    options.splice(includedOptionsIndex, 1);
                }
                // return options with excluded facet absent from list
                return options;
            }, facets[index].options || []);
        });
        return annotateFacetExclusions(facets, facetExclusions);
    }
);

const getUpcomingRecommendedSearchFacets = createSelector(
    [searchSliceSelector, getUserExcludedFacets, getRawUpcomingSearchFacets],
    (search, facetExclusions, rawFacets) => {
        const facets = cloneDeep(search.soldItemFacets) || [];

        // for each exclusion, find the corresponding raw facet option, and add it
        Object.keys(facetExclusions).forEach((complexFacetId) => {
            const index = facets.findIndex((f) => f.id === complexFacetId);
            const complexFacet = rawFacets.find((f) => f.id === complexFacetId);
            if (index === -1 || !complexFacet || !complexFacet.options) {
                return;
            }
            // add excluded options to the options present in the search results
            facets[index].options = facetExclusions[complexFacetId].reduce((options, exclusion) => {
                const includedOptionsIndex = options.findIndex((o) => o.id === exclusion);
                const rawFacetOption = complexFacet.options.find((o) => o.id === exclusion);
                if (!rawFacetOption) {
                    return options;
                }
                // remove excluded facet item from list, if found
                if (includedOptionsIndex >= 0) {
                    options.splice(includedOptionsIndex, 1);
                }
                // return options with excluded facet absent from list
                return options;
            }, facets[index].options || []);
        });
        return annotateFacetExclusions(facets, facetExclusions);
    }
);

export const getSelectedOptionFacetValues = createSelector(
    [idSelector, idSecondSelector, getUpcomingSearchFacets, getArchivedSearchFacets],
    (archivedSearch, id, upcomingSearchFacets, archivedSearchFacets) => {
        const facets = archivedSearch ? archivedSearchFacets : upcomingSearchFacets;
        const facetValues = facets.find((facet: ShippingFacetOption) => facet.id === id);
        const selectedFacetsValues =
            facetValues && facetValues.options
                ? facetValues.options.find((facet: ShippingFacetOption) => facet.selected)
                : {};
        return selectedFacetsValues || {};
    }
);

export const getSelectedFacetOptionsById = createSelector(
    [idSelector, idSecondSelector, getUpcomingSearchFacets, getArchivedSearchFacets],
    (archivedSearch, id, upcomingSearchFacets, archivedSearchFacets) => {
        const facets = archivedSearch ? archivedSearchFacets : upcomingSearchFacets;
        const facetValues = facets.find((facet: ShippingFacetOption) => facet.id === id);

        if (facetValues?.options) {
            return facetValues.options.filter((facet: ShippingFacetOption) => facet.selected);
        }
        return [];
    }
);

export const getMergedOptionFacet = createSelector(
    [
        idSelector,
        idSecondSelector,
        getUpcomingSearchFacets,
        getArchivedSearchFacets,
        getRawUpcomingSearchFacets,
        getRawArchivedSearchFacets,
    ],
    (
        archivedSearch,
        id,
        upcomingSearchFacets,
        archivedSearchFacets,
        rawUpcomingSearchFacets,
        rawArchivedSearchFacets
    ) => {
        const facets = archivedSearch ? archivedSearchFacets : upcomingSearchFacets;
        const rawFacets = archivedSearch ? rawArchivedSearchFacets : rawUpcomingSearchFacets;

        const facetValue = facets.find((facet: ShippingFacetOption) => facet.id === id);
        const rawFacetValue = rawFacets.find((facet: ShippingFacetOption) => facet.id === id);

        const mergedOptions = rawFacetValue?.options.map((option) => {
            const existingOption = facetValue?.options.find((o) => o.id === option.id);
            return {
                ...option,
                count: existingOption?.count || 0,
                selected: existingOption?.selected || false,
            };
        });

        return {
            ...rawFacetValue,
            options: mergedOptions,
            selected: facetValue?.selected || false,
        };
    }
);

export const getMergedRangeFacet = createSelector(
    [
        idSelector,
        idSecondSelector,
        getUpcomingSearchFacets,
        getArchivedSearchFacets,
        getRawUpcomingSearchFacets,
        getRawArchivedSearchFacets,
    ],
    (
        archivedSearch,
        id,
        upcomingSearchFacets,
        archivedSearchFacets,
        rawUpcomingSearchFacets,
        rawArchivedSearchFacets
    ) => {
        const facets = archivedSearch ? archivedSearchFacets : upcomingSearchFacets;
        const rawFacets = archivedSearch ? rawArchivedSearchFacets : rawUpcomingSearchFacets;

        const facetValue = facets.find((facet: ShippingFacetOption) => facet.id === id);
        const rawFacetValue = rawFacets.find((facet: ShippingFacetOption) => facet.id === id);

        return {
            ...rawFacetValue,
            range: facetValue?.range ?? { max: 0, min: 0 },
            selected: facetValue?.selected || false,
        };
    }
);

export const getSelectedCategoryFacetValue = createSelector(
    [idSelector, idSecondSelector, getUpcomingSearchFacets, getArchivedSearchFacets],
    (archivedSearch: boolean, id, upcomingSearchFacets, archivedSearchFacets) => {
        const facets = archivedSearch ? archivedSearchFacets : upcomingSearchFacets;
        const facetValues = facets.find((facet: ShippingFacetOption) => facet.id === id);
        return facetValues?.categories?.facets?.[0] || {};
    }
);

export const getSoldFacetsSelectedCreator = createSelector(getUpcomingRecommendedSearchFacets, (recommendedFacets) => {
    const facet = recommendedFacets?.find((x) => x.id === 'creator');
    if (!facet) {
        return [];
    }
    const { options: creators } = facet;
    return creators.find((c) => c.selected);
});

export const getSoldItemImportantCreators = createSelector(
    [searchSliceSelector, getUpcomingRecommendedSearchFacets],
    (search, recommendedFacets) => {
        const facet = recommendedFacets?.find((x) => x.id === 'creator');
        if (!facet) {
            return [];
        }
        // @ts-ignore
        const { totalSoldItems = 0 } = search;
        const { options: creators } = facet;
        return cloneDeep(creators.filter((creator) => creator.count / totalSoldItems >= 0.1));
    }
);

export const getSoldItemImportantStylePeriod = createSelector(
    [searchSliceSelector, getUpcomingRecommendedSearchFacets],
    (search, recommendedFacets) => {
        const facet = recommendedFacets?.find((x) => x.id === 'stylePeriod');
        if (!facet) {
            return [];
        }
        const { totalSoldItems = 0 } = search;
        const { options: creators } = facet;
        return cloneDeep(creators.filter((creator) => creator.count / totalSoldItems >= 0.1));
    }
);

// F1 indicates category facet
export const getSoldItemImportantCategories = createSelector(
    [searchSliceSelector, getUpcomingRecommendedSearchFacets],
    (search, recommendedFacets) => {
        const facet = recommendedFacets?.find((x) => x.id === 'F1');
        if (!facet) {
            return [];
        }
        const { categories: facets } = facet;
        // @ts-ignore
        const { totalSoldItems = 0 } = search;

        let categories: ZeroResultsContainerCategory[] = [];
        getAllCategories(categories, facets, 0);
        return categories
            .filter((category) => category.count / totalSoldItems >= 0.1)
            .sort((a, b) => b.count - a.count)
            .sort((a, b) => b.level - a.level);
    }
);

// F1 indicates category facet
export const getItemImportantCategories = createSelector(getUpcomingSearchFacets, (searchFacets) => {
    const categoryFacet = searchFacets?.find((x) => x.id === 'F1');
    if (!categoryFacet) {
        return [];
    }

    const {
        categories: { facets },
    } = categoryFacet;

    let categories: ZeroResultsContainerCategory[] = [];
    getAllCategories(categories, facets, 0);
    return categories.sort((a, b) => b.count - a.count).sort((a, b) => b.level - a.level);
});

// F2 indicates origin facet
export const getSoldItemImportantOrigins = createSelector(
    [searchSliceSelector, getUpcomingRecommendedSearchFacets],
    (search, recommendedFacets) => {
        const facet = recommendedFacets?.find((x) => x.id === 'F2');
        if (!facet) {
            return [];
        }
        const { categories: facets } = facet;
        const { totalSoldItems = 0 } = search;

        let categories: ZeroResultsContainerCategory[] = [];
        getAllCategories(categories, facets, 0);
        return categories
            .filter((category) => category.count / totalSoldItems >= 0.1)
            .sort((a, b) => b.count - a.count)
            .sort((a, b) => b.level - a.level);
    }
);

export const getUpcomingAuctions = createSelector(
    [idSelector, getUpcomingSearchFacets, getArchivedSearchFacets],
    (archivedSearch, upcomingSearchFacets, archivedSearchFacets) => {
        const facets = archivedSearch ? archivedSearchFacets : upcomingSearchFacets;
        const facetValues = facets.find((f) => f.id === FacetTypes.AUCTION_HOUSE_ID);
        return facetValues ? facetValues.options.length : 0;
    }
);

export const getCategoryWithGreatestResults = createSelector(
    [idSelector, getUpcomingSearchFacets, getArchivedSearchFacets],
    (archivedSearch, upcomingSearchFacets, archivedSearchFacets) => {
        const facets = archivedSearch ? archivedSearchFacets : upcomingSearchFacets;
        const categoryFacets = facets.find((f) => f.id === FacetTypes.CATEGORY_ID);
        return categoryFacets && categoryFacets.categories && categoryFacets.categories.facets.length > 0
            ? categoryFacets.categories.facets[0]
            : {};
    }
);

export const getArchivedAndUpcomingFacetValues = createSelector(
    [idSelector, getRawUpcomingSearchFacets, getRawArchivedSearchFacets],
    (facetId: number, rawUpcomingSearchFacets: any, rawArchivedSearchFacets: any) => {
        const auctionHouseFilter = (facet) => {
            return facet.id === facetId;
        };
        let facets: any[] = [];
        let doneFacets: any[] = [];

        if (rawUpcomingSearchFacets.length) {
            const filtered = rawUpcomingSearchFacets.filter(auctionHouseFilter);
            if (filtered.length) {
                facets = rawUpcomingSearchFacets.filter(auctionHouseFilter)[0].options;
            }
        }
        if (rawArchivedSearchFacets.length) {
            const filtered = rawArchivedSearchFacets.filter(auctionHouseFilter);
            if (filtered.length) {
                doneFacets = rawArchivedSearchFacets.filter(auctionHouseFilter)[0].options;
            }
        }

        const uniq = uniqBy([...facets, ...doneFacets], (facet) => facet.id);
        return uniq.length ? cloneDeep(uniq) : [];
    }
);

export function getSearchAuctionHouseFacetValues(state: GlobalState) {
    return getArchivedAndUpcomingFacetValues(state, 'auctionHouse');
}

export function getSearchCountryFacetValues(state: GlobalState) {
    return getArchivedAndUpcomingFacetValues(state, 'countryCode');
}
