import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { LOAD_STOREFRONT_ITEMS_FAIL, LOAD_STOREFRONT_ITEMS_REQUEST, LOAD_STOREFRONT_ITEMS_SUCCESS } from './actions';
import api from '@/redux/api/storefront';
import difference from 'lodash/difference';
import union from 'lodash/union';

export type StorefrontState = {
    items: number[];
    loaded: {};
    loading: any[];
    logoID: string;
    totalItems: number;
};

/* reducer */
export const DEFAULT_STATE: StorefrontState = {
    items: [],
    loaded: {},
    loading: [],
    logoID: '',
    totalItems: 0,
};

export default function reducer(state: StorefrontState = DEFAULT_STATE, action: any = {}): StorefrontState {
    switch (action.type) {
        case LOAD_STOREFRONT_ITEMS_SUCCESS:
            return {
                ...state,
                // @ts-ignore
                items: [...new Set(action.payload.items.map((x) => x.itemId))],
                logoID: action.payload.sellers[0].logoId,
            };
        case LOAD_STOREFRONT_ITEMS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, action.meta),
            };
        case LOAD_STOREFRONT_ITEMS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, action.payload),
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const storefrontSlice = createSelector(stateSelector, (state) => state.storefront);

export const getStorefrontItemIds = createSelector(storefrontSlice, (state) => state.items);

/* ACTION CREATORS */
export const loadStorefrontItems = (sellerId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        dispatch({
            payload: sellerId,
            type: LOAD_STOREFRONT_ITEMS_REQUEST,
        });

        const response = await api.fetchLotsBySellerId({
            authToken,
            deployment,
            sellerId,
        });

        return dispatch({
            meta: { actionTime: Date.now(), sellerId },
            payload: response.data,
            type: LOAD_STOREFRONT_ITEMS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { sellerId },
            payload: error,
            type: LOAD_STOREFRONT_ITEMS_FAIL,
        });
    }
};
