import { AmplitudePageName } from '@/utils/getPageNameFromPath';

import { createAmplitudeSearchQueryParams } from '@/utils/analytics/utils/createAmplitudeSearchQueryParams';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchSearchResults } from './search.api';
import { getAugmentedSearchQuery } from './search.helpers';
import { getAuthToken, getZipCodeGeoCoordinates } from '@/redux/modules/account/user/user.selectors';
import { getClientIpAddress } from '../browser';
import {
    getCurrentSearch,
    getSearchSellerItemsSearch,
    getSearchSellerItemsSearchSellerId,
    getSearchSubmittedFromPage,
    getSearchTotalFound,
    getUseGeoLocation,
} from './search.selectors';
import { getDeployment } from '@/redux/modules/config';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { getIsBot, trackPlacedSearch } from '@/redux/modules/analytics';
import { getSearchPageSize } from '../pagination';
import { getSearchSuggestions } from '@/redux/modules/searchSuggestions';
import { getSellerName } from '@/redux/modules/seller';
import { getUserExcludedFacets } from '@/redux/modules/search/exclusions/searchExclusions.selectors';
import { GlobalState } from '@/redux/store';
import { isClientSide } from '@/utils/analytics/core';
import {
    LOAD_SEARCH_RESULTS_SUCCESS,
    LOAD_SEARCH_RESULTS_SUCCESS_ACTION,
    SearchResultPayload,
    SubmitSearchPayload,
} from './search.types';
import { SearchCompletedTrigger } from '@/utils/analytics/core.types';
import { searchPromoted } from '@/redux/modules/searchPromoted';
import {
    trackSearchCompletedAnalytics,
    TrackSearchCompletedSearchType,
    trackSearchRefinedAnalytics,
} from '@/utils/analytics';

export const submitSearch = createAsyncThunk<SearchResultPayload | string, SubmitSearchPayload>(
    'la/domain/search/submitSearch',
    async ({ pageName, routerState = {}, searchQuery }, { dispatch, getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        const ipAddress = getClientIpAddress(state);
        const isBot = getIsBot(state);
        const currentSearch = getCurrentSearch(state);
        const submittedFromPage = getSearchSubmittedFromPage(state);

        const searchSuggestions = getSearchSuggestions(state);

        // Annotate facets which have user-defined search exclusions
        const searchExclusions = getUserExcludedFacets(state);
        const augmentedSearchQuery = getAugmentedSearchQuery(searchQuery, searchExclusions);

        const searchForCacheCheck = JSON.stringify(augmentedSearchQuery);
        if (searchForCacheCheck === currentSearch) {
            trackSearchCompletedOrRefined({
                deployment,
                routerState,
                searchSuggestions,
                searchTerm: searchQuery.searchTerm,
                state,
                submittedFromPage,
                totalFound: getSearchTotalFound(state),
            });
            return {};
        }

        const useGeoLocation = getUseGeoLocation(state);
        const geoCoordinates = getZipCodeGeoCoordinates(state);

        const options = {
            authToken,
            deployment,
            geoCoordinates,
            ipAddress,
            isBot,
            searchQuery: augmentedSearchQuery,
            useGeoLocation,
        };

        // main search api dispatch
        const promises: Promise<any>[] = [fetchSearchResults(options)];
        // add search for promoted items if making a catalog search
        if (pageName !== 'catalog') {
            promises.push(dispatch(searchPromoted({ pageName, searchQuery })));
        }

        const [results] = await Promise.all(promises);

        results.payload.currentQueryKey = searchForCacheCheck;

        // TODO: once all the reducers that subscribe to LOAD_SEARCH_RESULTS_SUCCESS are updated to toolkit, this dispatch should be removed
        const successAction: LOAD_SEARCH_RESULTS_SUCCESS_ACTION = {
            error: false,
            meta: { actionTime: Date.now() },
            payload: results.payload as SearchResultPayload,
            type: LOAD_SEARCH_RESULTS_SUCCESS,
        };

        dispatch(successAction);

        trackSearchCompletedOrRefined({
            deployment,
            routerState,
            searchSuggestions,
            searchTerm: searchQuery.searchTerm,
            state,
            submittedFromPage,
            totalFound: results.payload.totalFound,
        });

        return results.payload;
    }
);

type TrackSearchCompletedParams = {
    deployment: ReturnType<typeof getDeployment>;
    routerState: SubmitSearchPayload['routerState'];
    searchSuggestions: any;
    searchTerm: string;
    state: GlobalState;
    submittedFromPage: AmplitudePageName;
    totalFound: number;
};

const trackSearchCompletedOrRefined = ({
    deployment,
    routerState,
    searchSuggestions,
    searchTerm,
    state,
    submittedFromPage,
    totalFound,
}: TrackSearchCompletedParams) => {
    if (isClientSide()) {
        const pageSize = getSearchPageSize(state);
        const sanitizedAnalyticsQueryParams = createAmplitudeSearchQueryParams(
            window?.location?.search ?? '',
            pageSize
        );

        // Facet applied. Tracking Search Refined
        if (routerState.filterAction) {
            const trackSearchRefinedPayload = {
                numberOfSearchResults: totalFound,
                pageName: submittedFromPage,
                searchKeywords: searchTerm,
                url: getFullUrl(deployment, window.location.pathname),
                ...sanitizedAnalyticsQueryParams,
            };
            trackSearchRefinedAnalytics(trackSearchRefinedPayload);
        } else {
            // No facet applied. Tracking Search Completed
            const isSellerItemsSearch = getSearchSellerItemsSearch(state);
            const sellerId = getSearchSellerItemsSearchSellerId(state);
            const sellerName = getSellerName(state, sellerId);

            const fromSellerPage = isSellerItemsSearch && !Boolean(submittedFromPage) && !Boolean(searchTerm);

            const searchInput = document.getElementById('search-input') as HTMLInputElement;
            const submittedFromSearchBar = searchInput?.value === searchTerm;

            const keywordSearchSuggestionUsed = searchSuggestions[0]?.suggestions.some(
                (search) => search.label !== searchInput?.value && search.label === searchTerm
            );

            const searchSuggestionUsed = Boolean(keywordSearchSuggestionUsed);

            const trackSearchCompletedPayload = {
                numberOfSearchResults: totalFound,
                page: sanitizedAnalyticsQueryParams.page,
                pageName: fromSellerPage ? AmplitudePageName.Auctioneer : submittedFromPage,
                pageSize,
                searchKeywords: fromSellerPage ? sellerName : searchTerm,
                searchSuggestionUsed,
                searchType: fromSellerPage
                    ? TrackSearchCompletedSearchType.Auctioneer
                    : TrackSearchCompletedSearchType.Keyword,
                trigger:
                    searchSuggestionUsed || submittedFromSearchBar
                        ? SearchCompletedTrigger.SearchBar
                        : SearchCompletedTrigger.Other,
                url: getFullUrl(deployment, window.location.pathname),
            };

            trackSearchCompletedAnalytics(trackSearchCompletedPayload);
        }
    }
};

export const updateSearchInputBuffer = createAsyncThunk<void, string>(
    'la/domain/search/updateSearchInputBuffer',
    async (keyword, { dispatch }) => {
        if (Boolean(keyword)) {
            dispatch(trackPlacedSearch(keyword));
        }
    }
);

export const updateSearchSubmittedFromPage = createAsyncThunk<AmplitudePageName, AmplitudePageName>(
    'la/domain/search/updateSearchSubmittedFromPage',
    (pageName) => pageName
);

export const updateSearchSellerItemsSearch = createAsyncThunk<boolean, boolean>(
    'la/domain/search/updateSearchSellerItemsSearch',
    (sellerItemsSearch) => sellerItemsSearch
);

export const updateSearchSellerItemsSearchSellerId = createAsyncThunk<number, number>(
    'la/domain/search/updateSearchSellerItemsSearchSellerId',
    (sellerItemsSearch) => sellerItemsSearch
);

export const fetchMoreSearchResults = createAsyncThunk<SearchResultPayload | string, SubmitSearchPayload>(
    'la/domain/search/fetchMoreSearchResults',
    async ({ pageName, searchQuery }, { dispatch, getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        const ipAddress = getClientIpAddress(state);
        const isBot = getIsBot(state);

        // Annotate facets which have user-defined search exclusions
        const searchExclusions = getUserExcludedFacets(state);
        const augmentedSearchQuery = getAugmentedSearchQuery(
            { ...(isBot ? { isBot } : {}), ...searchQuery },
            searchExclusions
        );

        const useGeoLocation = getUseGeoLocation(state);
        const geoCoordinates = getZipCodeGeoCoordinates(state);

        const promises: Promise<any>[] = [
            fetchSearchResults({
                authToken,
                deployment,
                geoCoordinates,
                ipAddress,
                searchQuery: augmentedSearchQuery,
                useGeoLocation,
            }),
        ];

        if (pageName !== 'catalog') {
            promises.push(dispatch(searchPromoted({ pageName, searchQuery })));
        }

        const [results] = await Promise.all(promises);
        return results.payload;
    }
);

export const setUseGeoLocation = createAsyncThunk<boolean, boolean>(
    'la/domain/search/setUseGeoLocation',
    (useGeoLocation) => useGeoLocation
);
