import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getClientIpAddress } from './browser';
import { getDeployment } from './config';
import api from '@/redux/api/searchSuggestions';
import cloneDeep from 'lodash/cloneDeep';

export const SEARCH_SUGGESTIONS_FAIL = 'la/ui/searchSuggestions/FAIL';
export const SEARCH_SUGGESTIONS_REQUEST = 'la/ui/searchSuggestions/REQUEST';
export const SEARCH_SUGGESTIONS_SUCCESS = 'la/ui/searchSuggestions/SUCCESS';

export type SearchSuggestion = {
    houseId?: number;
    label: string;
    value: string;
};

type RelatedSuggestions = {
    suggestions: SearchSuggestion[];
    title: string;
};

// reducer
export type SearchSuggestionsSlice = {
    error: boolean;
    secondarySuggestions: SearchSuggestion[];
    submitted: boolean;
    success: boolean;
    suggestions: SearchSuggestion[];
};

export const defaultSearchSuggestionsSlice: SearchSuggestionsSlice = {
    error: false,
    secondarySuggestions: [],
    submitted: false,
    success: false,
    suggestions: [],
};

export type State = typeof defaultSearchSuggestionsSlice;

export default function reducer(state = defaultSearchSuggestionsSlice, action: any = {}): SearchSuggestionsSlice {
    switch (action.type) {
        case SEARCH_SUGGESTIONS_REQUEST:
            return {
                ...state,
                submitted: true,
                success: false,
            };
        case SEARCH_SUGGESTIONS_SUCCESS:
            if (action.meta.secondary) {
                return {
                    ...state,
                    secondarySuggestions: cloneDeep(action.payload),
                    submitted: false,
                    success: true,
                };
            }

            return {
                ...state,
                submitted: false,
                success: true,
                suggestions: cloneDeep(action.payload),
            };
        case SEARCH_SUGGESTIONS_FAIL:
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const searchSuggestionsSlice = createSelector(stateSelector, (state) => state.searchSuggestions);

export const getSecondarySearchSuggestions = createSelector(searchSuggestionsSlice, (uiSearchSuggestions) => {
    if (uiSearchSuggestions.secondarySuggestions) {
        return uiSearchSuggestions.secondarySuggestions.reduce<{
            houses: SearchSuggestion[];
            suggestions: SearchSuggestion[];
        }>(
            (refinedResult, result) => {
                if (result.label === 'Auction Houses') {
                    return refinedResult;
                }
                if (result.houseId) {
                    refinedResult.houses.push(result);
                } else {
                    refinedResult.suggestions.push(result);
                }
                return refinedResult;
            },
            { houses: [], suggestions: [] }
        );
    }
    return { houses: [], suggestions: [] };
});

export const getSearchSuggestions = createSelector(
    searchSuggestionsSlice,
    (uiSearchSuggestions): RelatedSuggestions[] => {
        if (uiSearchSuggestions.suggestions) {
            let reduced = uiSearchSuggestions.suggestions.reduce<{
                auctionHouses: SearchSuggestion[];
                auctionItems: SearchSuggestion[];
            }>(
                (refinedResult, result) => {
                    if (result.label === 'Auction Houses') {
                        return refinedResult;
                    }
                    if (result.houseId) {
                        refinedResult.auctionHouses.push(result);
                    } else {
                        refinedResult.auctionItems.push(result);
                    }
                    return refinedResult;
                },
                { auctionHouses: [], auctionItems: [] }
            );
            let suggestions: RelatedSuggestions[] = [
                {
                    suggestions: reduced.auctionItems,
                    title: '',
                },
            ];
            if (reduced.auctionHouses.length > 0) {
                suggestions.push({
                    suggestions: reduced.auctionHouses,
                    title: 'Auction Houses',
                });
            }
            return suggestions || [{ suggestions: [], title: '' }];
        } else {
            return [{ suggestions: [], title: '' }];
        }
    }
);

/* ACTION CREATORS */
export const fetchSearchSuggestions =
    (term: string, secondary: boolean = false) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const ipAddress = getClientIpAddress(state);

            dispatch({
                payload: term,
                type: SEARCH_SUGGESTIONS_REQUEST,
            });
            const response = await api.fetchSuggestions({
                deployment,
                ipAddress,
                term,
            });
            dispatch({
                meta: { secondary },
                payload: response.payload,
                type: SEARCH_SUGGESTIONS_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { term },
                payload: error,
                type: SEARCH_SUGGESTIONS_FAIL,
            });
        }
    };
