import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { HiddenQuoteReason, SHIPPING_CACHE_TIME } from '@/redux/modules/shipping/shipping.types';
import { ShippingVisibility } from '@/types/ShippingEstimate';

const stateSelector = (state: GlobalState) => state;
const shippingSlice = createSelector(stateSelector, (state) => state.shipping);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(shippingSlice, (state) => state.byId);

const loadedSelector = createSelector(shippingSlice, (state) => state.loaded);

const loadingSelector = createSelector(shippingSlice, (state) => state.loading);

export const getShippingEstimate = createSelector([byIdSelector, idSelector], (byId, id) => byId[id]);

export const getShippingEstimateVisibility = createSelector([getShippingEstimate], (estimate) => {
    if (!Boolean(estimate) || typeof estimate === 'string') {
        return ShippingVisibility.Uncalculated;
    }
    return estimate.visibility;
});

export const getShippingEstimateAvailability = createSelector([getShippingEstimate], (estimate) => {
    if (!Boolean(estimate) || typeof estimate === 'string') {
        return false;
    }
    return estimate.shippingAvailable;
});

export const getShippingEstimateTotal = createSelector([byIdSelector, idSelector], (byId, itemId) => {
    const estimate = byId[itemId];
    if (!Boolean(estimate) || typeof estimate === 'string') {
        return 0;
    }
    return estimate.total;
});

export const getShippingEstimateCity = createSelector([byIdSelector, idSelector], (byId, itemId) => {
    const estimate = byId[itemId];
    if (!Boolean(estimate) || typeof estimate === 'string') {
        return '';
    }
    return estimate.city;
});

export const getShippingEstimateState = createSelector([byIdSelector, idSelector], (byId, itemId) => {
    const estimate = byId[itemId];
    if (!Boolean(estimate) || typeof estimate === 'string') {
        return '';
    }
    return estimate.state;
});

const getLoadTimeForShippingEstimate = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

export const getShippingEstimateAttempted = createSelector([getLoadTimeForShippingEstimate], (loadTime) =>
    Boolean(loadTime)
);

export const getShippingEstimateHiddenReason = createSelector([getShippingEstimate], (estimate) => {
    // if `estimate` hasn't been loaded, or `estimate` is an actual estimate, there is no hidden reason.
    if (!Boolean(estimate) || typeof estimate !== 'string') {
        return null;
    }
    return estimate;
});

export const getShippingEstimateIsTooExpensive = createSelector([getShippingEstimate], (reason) => {
    if (typeof reason === 'string') {
        return reason === HiddenQuoteReason.TooExpensive;
    }
    return false;
});

export const getShippingEstimateHadError = createSelector([getShippingEstimate], (reason) => {
    if (typeof reason === 'string') {
        return reason !== HiddenQuoteReason.TooExpensive;
    }
    return false;
});

export const isShippingEstimateLoading = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(id)
);

export const shouldFetchItemShippingEstimate = (state: GlobalState, itemId: number): boolean => {
    if (!itemId) {
        return false;
    }
    const item = getShippingEstimate(state, itemId);
    if (item) {
        const loaded = getLoadTimeForShippingEstimate(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < SHIPPING_CACHE_TIME) {
            return false;
        }
    }
    const loading = isShippingEstimateLoading(state, itemId);
    return !loading;
};
