import { AugmentedSearchQuery, AugmentedSearchQueryOptions, SearchQuery } from '@/types/search/searchQuery';
import { SearchExclusionSlice } from '@/redux/modules/search/exclusions/searchExclusions.types';

export const getAugmentedSearchQuery = (
    searchQuery: SearchQuery,
    searchExclusions: SearchExclusionSlice['excludedFacets']
): AugmentedSearchQuery => {
    const augmentedOptions: AugmentedSearchQueryOptions = Object.keys(searchExclusions).reduce(
        (options, facetWithExclusions) => ({
            ...options,
            [facetWithExclusions]: [
                {
                    exclude: searchExclusions[facetWithExclusions] || [],
                    include: searchQuery.options[facetWithExclusions] || [],
                },
            ],
        }),
        {
            ...searchQuery.options,
            auctionHouse: [{ exclude: [], include: [] }],
        }
    );
    const augmentedSearchQuery: AugmentedSearchQuery = {
        ...searchQuery,
        options: augmentedOptions,
    };

    // due to BUGS-3037 and other bugs, dropping this down to only send 100 exclusions
    augmentedSearchQuery.options.auctionHouse[0]?.exclude.slice(0, 100);
    augmentedSearchQuery.topRated = augmentedSearchQuery.options.topRated;

    return augmentedSearchQuery;
};
