import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import {
    LOAD_SELLER_UPCOMING_CATALOGS_FAIL,
    LOAD_SELLER_UPCOMING_CATALOGS_REQUEST,
    LOAD_SELLER_UPCOMING_CATALOGS_SUCCESS,
} from '@/redux/modules/actions/sellerUpcomingCatalogs';
import api from '@/redux/api/sellerCatalogs';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

export type SellerUpcomingCatalogsSlice = {
    byId: any;
    loaded: any;
    loading: any[];
};

export const defaultSellerUpcomingCatalogsSlice: SellerUpcomingCatalogsSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(
    state: SellerUpcomingCatalogsSlice = defaultSellerUpcomingCatalogsSlice,
    action: any = {}
): SellerUpcomingCatalogsSlice {
    switch (action.type) {
        case LOAD_SELLER_UPCOMING_CATALOGS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.sellerId]),
            };
        case LOAD_SELLER_UPCOMING_CATALOGS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_SELLER_UPCOMING_CATALOGS_SUCCESS:
            const { sellerId } = action.meta;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [sellerId]: action.payload.catalogs.map((catalog) => catalog.catalogId),
                },
                loaded: { ...state.loaded, [sellerId]: action.meta.actionTime },
                loading: difference(state.loading, [sellerId]),
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const sellerUpcomingCatalogsSlice = createSelector(stateSelector, (state) => state.sellerUpcomingCatalogs);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(sellerUpcomingCatalogsSlice, (state) => state.byId);

const loadedSelector = createSelector(sellerUpcomingCatalogsSlice, (state) => state.loaded);

const loadingSelector = createSelector(sellerUpcomingCatalogsSlice, (state) => state.loading);

// takes in a (state, id) and returns the data
export const getSellerUpcomingCatalogs = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);

// takes in a (state, id) and returns the load time
export const getLoadTimeForSellerUpcomingCatalogs = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

// takes in a (state, id) and returns the is loading
export const isSellerUpcomingCatalogsLoading = createSelector([loadingSelector, idSelector], (loading, id) => {
    return loading.includes(id);
});

const shouldFetchUpcomingCatalogs = (state: GlobalState, sellerId: number) => {
    if (!sellerId) {
        return false;
    }
    const item = getSellerUpcomingCatalogs(state, sellerId);
    if (item.length) {
        const loaded = getLoadTimeForSellerUpcomingCatalogs(state, sellerId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isSellerUpcomingCatalogsLoading(state, sellerId);
    return !loading;
};

/* ACTION CREATORS */
const loadUpcomingCatalogs = (sellerId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: sellerId,
            type: LOAD_SELLER_UPCOMING_CATALOGS_REQUEST,
        });
        const response = await api.fetchSellerUpcomingCatalogsById({
            deployment,
            sellerId,
        });
        dispatch({
            meta: { actionTime: Date.now(), sellerId },
            payload: response.data,
            type: LOAD_SELLER_UPCOMING_CATALOGS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { sellerId },
            payload: error,
            type: LOAD_SELLER_UPCOMING_CATALOGS_FAIL,
        });
    }
};

export const fetchSellerUpcomingCatalogsIfNeeded =
    (sellerId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        if (shouldFetchUpcomingCatalogs(getState(), sellerId)) {
            return dispatch(loadUpcomingCatalogs(sellerId));
        }
        return Promise.resolve();
    };
