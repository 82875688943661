import { createSlice } from '@reduxjs/toolkit';
import { defaultRetractBidSlice } from './retractBid.types';
import { DISMISS_MODAL, DISMISS_MODAL_ACTION, OPEN_RETRACT_BID_MODAL, OpenRetractBidModalAction } from '../actions';
import { submitRetractBid } from '@/redux/modules/retractBid/retractBid.actions';

const retractBidSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(submitRetractBid.pending, (slice, { meta }) => {
            slice.itemId = meta.arg.itemId;
            slice.bidRetracted = true;
            slice.submitted = true;
            slice.success = false;
            slice.error = false;
        });
        builder.addCase(submitRetractBid.fulfilled, (slice, { payload }) => {
            slice.itemId = payload.itemId;
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(submitRetractBid.rejected, (slice, { meta }) => {
            slice.itemId = meta.arg.itemId;
            slice.submitted = false;
            slice.success = false;
            slice.error = true;
        });

        builder.addCase<typeof OPEN_RETRACT_BID_MODAL, OpenRetractBidModalAction>(
            OPEN_RETRACT_BID_MODAL,
            (slice, { payload }) => {
                slice.itemId = payload.itemId;
            }
        );
        builder.addCase<typeof DISMISS_MODAL, DISMISS_MODAL_ACTION>(DISMISS_MODAL, (slice) => {
            slice.bidRetracted = false;
        });
    },
    initialState: defaultRetractBidSlice,
    name: 'retractBid',
    reducers: {},
});

export const { reducer: retractBidReducer } = retractBidSlice;
