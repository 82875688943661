import { ApiHosts, handleResponseOld, makePost } from '@/redux/api/helpers';

export type PostSetSearchFacetOptionExclusionResponseData = {
    houseIds: number[];
    message: string;
    success: boolean;
};

/**
 * @category Item API
 * @see postSetSearchFacetOptionExclusion
 */
type PostSetSearchFacetOptionExclusionResponse = {
    data: PostSetSearchFacetOptionExclusionResponseData;
};

/**
 * @category Item API
 * @see postSetSearchFacetOptionExclusion
 */
type PostSetSearchFacetOptionExclusionParams = {
    authToken: string;
    deployment: string;
    exclude: boolean;
    facetId: string;
    optionId: string | number;
};

/**
 * POST to item-api to save Search Facet exclusions for user account
 * @function postSetSearchFacetOptionExclusion
 * @category Item API
 * @param {PostSetSearchFacetOptionExclusionParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/search/exclusion/seller/${optionId}/${excludeBit}
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/bidder/routes/post-search-exclusion.ts
 */
export const postSetSearchFacetOptionExclusion = ({
    authToken,
    deployment,
    exclude,
    facetId,
    optionId,
}: PostSetSearchFacetOptionExclusionParams) =>
    new Promise<PostSetSearchFacetOptionExclusionResponse>((resolve, reject) => {
        let requestURL: string;
        // transform the `exclude` bool into a number (0 or 1)
        const excludeBit = Number(exclude);

        switch (facetId) {
            case 'auctionHouse':
                requestURL = `search/exclusion/seller/${optionId}/${excludeBit}`;
                break;
            default:
                return reject(`Excluding search results by ${facetId} is not yet supported.`);
        }
        const request = makePost({
            apiPath: requestURL,
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.send();
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
