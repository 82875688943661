import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { DEFAULT_ARCHIVE_STATUSES, DEFAULT_LIVE_STATUSES } from './search/search.types';
import { fetchSearchFacetsResults } from '@/redux/modules/search/search.api';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getClientIpAddress } from './browser';
import { getDeployment } from './config';
import { getIsBot } from './analytics';
import { LOAD_CATEGORY_RESULTS_SUCCESS } from '@/redux/modules/actions';
import { LOAD_SEARCH_RESULTS_SUCCESS } from '@/redux/modules/search/search.types';

export const LOAD_FACETS_FAIL = 'LOAD_FACETS_FAIL';
export const LOAD_FACETS_REQUEST = 'LOAD_FACETS_REQUEST';
export const LOAD_UPCOMING_FACETS_SUCCESS = 'LOAD_UPCOMING_FACETS_SUCCESS';
const LOAD_ARCHIVED_FACETS_SUCCESS = 'LOAD_ARCHIVED_FACETS_SUCCESS';

// reducer
export type SearchFacetsSlice = {
    error: boolean;
    facets: {
        archived: any[];
        upcoming: any[];
    };
    submitted: boolean;
    success: boolean;
};

export const defaultSearchFacetsSlice: SearchFacetsSlice = {
    error: false,
    facets: {
        archived: [],
        upcoming: [],
    },
    submitted: false,
    success: false,
};

export default function reducer(state = defaultSearchFacetsSlice, action: any = {}): SearchFacetsSlice {
    switch (action.type) {
        case LOAD_FACETS_REQUEST:
            return {
                ...state,
                error: false,
                submitted: true,
                success: false,
            };
        case LOAD_UPCOMING_FACETS_SUCCESS:
            return {
                ...state,
                facets: {
                    ...state.facets,
                    upcoming: action.payload.facets,
                },
                submitted: false,
                success: true,
            };
        case LOAD_CATEGORY_RESULTS_SUCCESS:
        case LOAD_SEARCH_RESULTS_SUCCESS:
            if (action.payload.soldItemFacets?.length) {
                return {
                    ...state,
                    facets: {
                        ...state.facets,
                        archived: action.payload.soldItemFacets,
                    },
                };
            }

            return {
                ...state,
            };
        case LOAD_ARCHIVED_FACETS_SUCCESS:
            return {
                ...state,
                facets: {
                    ...state.facets,
                    archived: action.payload.facets,
                },
                submitted: false,
                success: true,
            };
        case LOAD_FACETS_FAIL:
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const searchFacetsSlice = createSelector(stateSelector, (state) => state.searchFacets);
export const searchFacetsSelector = createSelector(searchFacetsSlice, (state) => state.facets);

export const getFetchFacetsSubmitted = createSelector(searchFacetsSlice, (state) => state.submitted);

const shouldFetchUpcomingFacets = createSelector(
    [searchFacetsSlice],
    (searchFacets: any) =>
        searchFacets.error ||
        (searchFacets.facets && searchFacets.facets.upcoming && searchFacets.facets.upcoming.length === 0)
);

const shouldFetchArchivedFacets = createSelector(
    [searchFacetsSlice],
    (searchFacets: any) =>
        searchFacets.error ||
        (searchFacets.facets && searchFacets.facets.archived && searchFacets.facets.archived.length === 0)
);

/* ACTION CREATORS */
const loadFacets = (archivedSearch) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        const ipAddress = getClientIpAddress(state);
        const isBot = getIsBot(state);

        dispatch({
            type: LOAD_FACETS_REQUEST,
        });
        const searchStatus = archivedSearch ? DEFAULT_ARCHIVE_STATUSES : DEFAULT_LIVE_STATUSES;
        const searchQuery = {
            ...(isBot ? { isBot } : {}),
            options: {
                status: searchStatus,
            },
        };

        const results = await fetchSearchFacetsResults({
            authToken,
            deployment,
            ipAddress,
            searchQuery,
        });
        if (results.error) {
            dispatch({
                error: true,
                payload: results,
                type: LOAD_FACETS_FAIL,
            });
        } else {
            archivedSearch
                ? dispatch({
                      payload: results.payload,
                      type: LOAD_ARCHIVED_FACETS_SUCCESS,
                  })
                : dispatch({
                      payload: results.payload,
                      type: LOAD_UPCOMING_FACETS_SUCCESS,
                  });
        }
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: LOAD_FACETS_FAIL,
        });
    }
};

export const fetchSearchFacetsIfNeeded =
    (archivedSearch: boolean) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const shouldLoadFacets = archivedSearch ? shouldFetchArchivedFacets(state) : shouldFetchUpcomingFacets(state);
        if (shouldLoadFacets) {
            return dispatch(loadFacets(archivedSearch));
        }
        return Promise.resolve();
    };
