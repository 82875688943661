import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { FOLLOW_SELLER_SUCCESS, UNFOLLOW_SELLER_SUCCESS } from './followSeller';
import { getDeployment } from './config';
import api from '@/redux/api/seller';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
const LOAD_SELLER_FOLLOWER_COUNT_FAIL = 'la/domain/sellerFollowerCount/LOAD_FAIL';
const LOAD_SELLER_FOLLOWER_COUNT_REQUEST = 'la/domain/sellerFollowerCount/LOAD_REQUEST';
const LOAD_SELLER_FOLLOWER_COUNT_SUCCESS = 'la/domain/sellerFollowerCount/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('5m');

/* reducer */
export type SellerFollowerCountSlice = {
    byId: any;
    loaded: any;
    loading: any[];
    loadingSellersFollowerCount: boolean;
};

export const defaultSellerFollowerCountSlice: SellerFollowerCountSlice = {
    byId: {},
    loaded: {},
    loading: [],
    loadingSellersFollowerCount: false,
};

export default function reducer(
    state: SellerFollowerCountSlice = defaultSellerFollowerCountSlice,
    action: any = {}
): SellerFollowerCountSlice {
    let loaded;
    let existing;
    let time: number;

    switch (action.type) {
        case LOAD_SELLER_FOLLOWER_COUNT_FAIL:
            return {
                ...state,
                loading: difference(state.loading, action.payload.sellerIds),
                loadingSellersFollowerCount: false,
            };
        case LOAD_SELLER_FOLLOWER_COUNT_REQUEST:
            return {
                ...state,
                loading: union(state.loading, action.payload),
                loadingSellersFollowerCount: true,
            };
        case LOAD_SELLER_FOLLOWER_COUNT_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = cloneDeep(state.loaded);
            time = action.meta.actionTime;
            action.payload.forEach((sellerFollowerCount) => {
                existing = { ...existing, ...sellerFollowerCount };
            });
            action.meta.sellerIds.forEach((id: number) => {
                loaded[id] = time;
            });
            const s = {
                ...state,
                byId: existing,
                loaded,
                loading: difference(state.loading, action.meta.sellerIds),
                loadingSellersFollowerCount: false,
            };
            return s;
        case FOLLOW_SELLER_SUCCESS:
            existing = cloneDeep(state.byId);
            existing[action.payload] = existing[action.payload] + 1;
            return {
                ...state,
                byId: existing,
            };
        case UNFOLLOW_SELLER_SUCCESS:
            existing = cloneDeep(state.byId);
            existing[action.payload] = existing[action.payload] - 1;
            return {
                ...state,
                byId: existing,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const sellerFollowerCountSlice = createSelector(stateSelector, (state) => state.sellerFollowerCount);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(sellerFollowerCountSlice, (state) => state.byId);
const loadedSelector = createSelector(sellerFollowerCountSlice, (state) => state.loaded);
const loadingSelector = createSelector(sellerFollowerCountSlice, (state) => state.loading);

const getLoadTime = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

export const getSellerFollowerCountById = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || 0);

/* ACTION CREATORS */
// all sellers

const isLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

// Currently we are only ever looking up a single sellerId at a time so the check will only check one
const shouldFetchSellerFollowerCount = (state: GlobalState, sellerIds: number[]) => {
    if (!sellerIds.length) {
        return false;
    }
    const loaded = getLoadTime(state, sellerIds[0]);
    if (loaded) {
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isLoading(state, sellerIds[0]);
    return !loading;
};

export const fetchSellerFollowerCount = (sellerIds: number[]) => {
    return async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            dispatch({
                payload: sellerIds,
                type: LOAD_SELLER_FOLLOWER_COUNT_REQUEST,
            });
            const results = await api.fetchFollowerCount({
                deployment,
                sellerIds,
            });
            dispatch({
                meta: { actionTime: Date.now(), sellerIds },
                payload: results.data,
                type: LOAD_SELLER_FOLLOWER_COUNT_SUCCESS,
            });
        } catch (error) {
            dispatch({
                payload: { error, sellerIds },
                type: LOAD_SELLER_FOLLOWER_COUNT_FAIL,
            });
        }
    };
};

export const fetchSellerFollowerCountIfNeeded =
    (sellerIds: number[], force: boolean = false) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        if (force || shouldFetchSellerFollowerCount(getState(), sellerIds)) {
            return dispatch(fetchSellerFollowerCount(sellerIds));
        }
        return Promise.resolve();
    };
