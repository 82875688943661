import { Address } from '@liveauctioneers/caterwaul-components/types/Address';
import { ShippingQuote } from '@/types/ShippingQuote';

export type ShippingQuoteSlice = {
    byId: { [id: number]: ShippingQuote };
    loaded: { [id: number]: number };
    loading: number[];
};

export const defaultShippingQuoteSlice: ShippingQuoteSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export type ShippingQuoteInfoType = {
    catalogId: number;
    force?: boolean;
    invoiceId: number;
    itemIds?: number[];
    shippingAddress?: Address;
};

export type FinalShippingQuoteType = {
    authToken: string;
    deployment: string;
    invoiceId: number;
    quoteId: string;
    quoteNumber: string;
};
