import { AppDispatch, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import {
    LOAD_SELLER_ENDED_CATALOGS_FAIL,
    LOAD_SELLER_ENDED_CATALOGS_REQUEST,
    LOAD_SELLER_ENDED_CATALOGS_SUCCESS,
} from '@/redux/modules/actions/sellerEndedCatalogs';
import api from '@/redux/api/sellerCatalogs';
import difference from 'lodash/difference';
import union from 'lodash/union';

export type SellerEndedCatalogsSlice = {
    byId: any;
    loaded: any;
    loading: any[];
};

export const defaultSliceSellerEndedCatalogsSlice: SellerEndedCatalogsSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(
    state: SellerEndedCatalogsSlice = defaultSliceSellerEndedCatalogsSlice,
    action: any = {}
): SellerEndedCatalogsSlice {
    switch (action.type) {
        case LOAD_SELLER_ENDED_CATALOGS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.sellerId]),
            };
        case LOAD_SELLER_ENDED_CATALOGS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_SELLER_ENDED_CATALOGS_SUCCESS:
            const { actionTime, sellerId } = action.meta;
            const newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [sellerId]: action.payload.catalogs.map((catalog) => catalog.catalogId),
                },
                loading: difference(state.loading, [sellerId]),
            }; // TODO: make this reducer pure
            // dont set loaded if we're on the server because we'll need to get the rest

            // @ts-ignore
            newState.loaded = global.__SERVER__ ? { ...state.loaded } : { ...state.loaded, [sellerId]: actionTime };
            return newState;
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const sellerEndedCatalogsSlice = createSelector(stateSelector, (state) => state.sellerEndedCatalogs);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(sellerEndedCatalogsSlice, (state) => state.byId);

const loadedSelector = createSelector(sellerEndedCatalogsSlice, (state) => state.loaded);

const loadingSelector = createSelector(sellerEndedCatalogsSlice, (state) => state.loading);

// takes in a (state, id) and returns the data
export const getEndedCatalogs = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);

// takes in a (state, id) and returns the load time
export const getLoadTimeForEndedCatalogs = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

// takes in a (state, id) and returns the is loading
export const isEndedCatalogsLoading = createSelector([loadingSelector, idSelector], (loading, id) => {
    return loading.includes(id);
});

/* ACTION CREATORS */
const loadEndedCatalogs = (sellerId, page, count, sortTerm) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: sellerId,
            type: LOAD_SELLER_ENDED_CATALOGS_REQUEST,
        });
        const response = await api.fetchSellerEndedCatalogsById({
            count,
            deployment,
            page,
            sellerId,
            sortTerm,
        });
        dispatch({
            meta: { actionTime: Date.now(), sellerId },
            payload: response.data,
            type: LOAD_SELLER_ENDED_CATALOGS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { sellerId },
            payload: error,
            type: LOAD_SELLER_ENDED_CATALOGS_FAIL,
        });
    }
};

export const fetchSellerEndedCatalogs =
    (sellerId: number, page: number, pageSize: number | 'all', sortTerm: string) => async (dispatch: AppDispatch) => {
        return dispatch(loadEndedCatalogs(sellerId, page, pageSize, sortTerm));
    };
