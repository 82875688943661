import { createSlice } from '@reduxjs/toolkit';
import { Crumb } from '@liveauctioneers/caterwaul-components/lib/Breadcrumbs/Breadcrumbs';
import { defaultSearchSlice, SearchResultPayload, SearchSlice } from './search.types';
import {
    fetchMoreSearchResults,
    setUseGeoLocation,
    submitSearch,
    updateSearchInputBuffer,
    updateSearchSellerItemsSearch,
    updateSearchSellerItemsSearchSellerId,
    updateSearchSubmittedFromPage,
} from './search.actions';
import {
    LOAD_CATEGORY_RESULTS_FAIL,
    LOAD_CATEGORY_RESULTS_FAIL_ACTION,
    LOAD_CATEGORY_RESULTS_REQUEST,
    LOAD_CATEGORY_RESULTS_REQUEST_ACTION,
    LOAD_CATEGORY_RESULTS_SUCCESS,
    LOAD_CATEGORY_RESULTS_SUCCESS_ACTION,
    LOAD_DISCOVER_ITEMS_SUCCESS,
} from '@/redux/modules/actions';
import { TypedActionWithPayload } from '@/types/redux';

const resetSearchState = (slice: SearchSlice): SearchSlice => ({
    ...slice,
    error: false,
    submitted: true,
    success: false,
});

const addSearchResults = (
    slice: SearchSlice,
    {
        currentQueryKey,
        facets,
        itemIDs,
        similarItemsURL,
        soldItemFacets,
        soldItemIDs,
        sort,
        totalBuyNow,
        totalFound,
        totalLive,
        totalPages,
        totalSold,
    }: SearchResultPayload
): SearchSlice => {
    if (typeof itemIDs === 'undefined' && typeof soldItemIDs === 'undefined') {
        // if we are just passing an empty object because we dont want to reload then we just set some properties and call it good
        return {
            ...slice,
            error: false,
            submitted: false,
            success: true,
        };
    }
    return {
        ...slice,
        currentQueryKey,
        facets,
        isInitialLoading: false,
        isLoading: false,
        itemIds: itemIDs,
        similarItemsURL,
        soldItemFacets,
        soldItemIds: soldItemIDs,
        sort,
        submitted: false,
        success: true,
        totalBuyNowItems: totalBuyNow,
        totalFound,
        totalLiveItems: totalLive,
        totalPages,
        totalSoldItems: totalSold,
    };
};

const enterErrorState = (slice: SearchSlice): SearchSlice => ({
    ...slice,
    error: true,
    submitted: false,
    success: false,
});

const searchSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(submitSearch.pending, resetSearchState);
        builder.addCase<typeof LOAD_CATEGORY_RESULTS_REQUEST, LOAD_CATEGORY_RESULTS_REQUEST_ACTION>(
            LOAD_CATEGORY_RESULTS_REQUEST,
            (state) => resetSearchState({ ...state, categoryCrumbs: [] })
        );
        builder.addCase(fetchMoreSearchResults.pending, resetSearchState);

        builder.addCase(updateSearchInputBuffer.pending, (state, { meta }) => {
            state.searchInputBuffer = meta.arg;
        });

        builder.addCase(submitSearch.fulfilled, (state, { payload }) =>
            addSearchResults(state, payload as SearchResultPayload)
        );
        builder.addCase<
            typeof LOAD_DISCOVER_ITEMS_SUCCESS,
            TypedActionWithPayload<typeof LOAD_DISCOVER_ITEMS_SUCCESS, SearchResultPayload>
        >(LOAD_DISCOVER_ITEMS_SUCCESS, (state, { payload }) => addSearchResults(state, payload));
        builder.addCase<typeof LOAD_CATEGORY_RESULTS_SUCCESS, LOAD_CATEGORY_RESULTS_SUCCESS_ACTION>(
            LOAD_CATEGORY_RESULTS_SUCCESS,
            (state, { payload }) => {
                if (payload.soldItemIDs || payload.itemIDs) {
                    return addSearchResults(
                        {
                            ...state,
                            categoryCrumbs:
                                payload.breadcrumbs?.map(
                                    (crumb): Crumb => ({
                                        label: crumb.name,
                                        link: crumb.url,
                                    })
                                ) || [],
                        },
                        payload
                    );
                }
            }
        );

        builder.addCase(fetchMoreSearchResults.fulfilled, (state, { payload }) => {
            const { itemIDs } = payload as SearchResultPayload;
            const allItemIds = [...state.itemIds];
            itemIDs.forEach((id) => {
                if (!allItemIds.includes(id)) {
                    allItemIds.push(id);
                }
            });
            const updatedIdsPayload: SearchResultPayload = {
                ...(payload as SearchResultPayload),
                itemIDs: allItemIds,
            };
            return addSearchResults(state, updatedIdsPayload);
        });

        builder.addCase(setUseGeoLocation.fulfilled, (state, { payload }) => {
            state.useGeoLocation = payload;
        });

        builder.addCase(updateSearchSubmittedFromPage.fulfilled, (state, { payload }) => {
            state.submittedFromPage = payload;
        });

        builder.addCase(updateSearchSellerItemsSearch.fulfilled, (state, { payload }) => {
            state.sellerItemsSearch = payload;
        });

        builder.addCase(updateSearchSellerItemsSearchSellerId.fulfilled, (state, { payload }) => {
            state.sellerItemsSearchSellerId = payload;
        });

        builder.addCase(fetchMoreSearchResults.rejected, enterErrorState);
        builder.addCase(submitSearch.rejected, enterErrorState);
        builder.addCase<typeof LOAD_CATEGORY_RESULTS_FAIL, LOAD_CATEGORY_RESULTS_FAIL_ACTION>(
            LOAD_CATEGORY_RESULTS_FAIL,
            (state) => {
                // On fail, clear possible crumbs on page.
                return enterErrorState({ ...state, categoryCrumbs: [] });
            }
        );
        builder.addCase(updateSearchInputBuffer.rejected, enterErrorState);
    },
    initialState: defaultSearchSlice,
    name: 'search',
    reducers: {},
});

export const { reducer: searchReducer } = searchSlice;
