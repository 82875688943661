import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { EmptySeller, Seller } from '@/types/Seller';
import { getAuctioneerLogoUrl } from '@/utils/urls';
import { getDeployment } from './config';
import { HouseBetas } from '@/types/seller/HouseBetas.enum';
import {
    LOAD_ALL_SELLERS_FAIL,
    LOAD_ALL_SELLERS_REQUEST,
    LOAD_ALL_SELLERS_SUCCESS,
    LOAD_SELLERS_FAIL,
    LOAD_SELLERS_REQUEST,
    LOAD_SELLERS_SUCCESS,
    LOAD_UPCOMING_CATALOGS_SUCCESS,
} from './actions';
import { LOAD_CATALOGS_SUCCESS } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { LOAD_HOME_DATA_SUCCESS } from '@/redux/modules/home/home.actions';
import { safelyEscapeText } from '@/utils/safelyRender';
import { whichSellerCatalogCountsNeeded } from './sellerCatalogCounts';
import api from '@/redux/api/seller';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import groupBy from 'lodash/groupBy';
import ms from 'ms';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type SellerSlice = {
    byId: { [id: number]: Seller };
    loaded: { [id: number]: number };
    loadedSellers: boolean;
    loading: number[];
    loadingSellers: boolean;
};

export const defaultSellerSlice: SellerSlice = {
    byId: {},
    loaded: {},
    loadedSellers: false,
    loading: [],
    loadingSellers: false,
};

export default function reducer(state = defaultSellerSlice, action: any = {}): SellerSlice {
    let existing;
    let loaded;
    let loadedSellers;
    let loading;
    let loadingSellers;
    let time;

    switch (action.type) {
        case LOAD_ALL_SELLERS_FAIL:
            return {
                ...state,
                loadingSellers: false,
            };
        case LOAD_ALL_SELLERS_REQUEST:
            return {
                ...state,
                loadingSellers: true,
            };
        case LOAD_SELLERS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, action.meta.sellerIds),
            };
        case LOAD_SELLERS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, action.payload),
            };
        case LOAD_ALL_SELLERS_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;
            action.payload.forEach((item) => {
                existing[item.sellerId] = {
                    ...(existing[item.sellerId] || EmptySeller),
                    ...item,
                    name: safelyEscapeText(item.name),
                };
                loaded[item.sellerId] = time;
                loading = difference(loading, [item.sellerId]);
            });
            loadingSellers = false;
            loadedSellers = true;
            return {
                ...state,
                byId: existing,
                loaded,
                loadedSellers,
                loading,
                loadingSellers,
            };
        case LOAD_CATALOGS_SUCCESS:
        case LOAD_HOME_DATA_SUCCESS:
        case LOAD_SELLERS_SUCCESS:
        case LOAD_UPCOMING_CATALOGS_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;

            if (action.payload.sellers) {
                action.payload.sellers.forEach((item) => {
                    existing[item.sellerId] = {
                        ...(existing[item.sellerId] || EmptySeller),
                        ...item,
                        name: safelyEscapeText(item.name),
                    };
                    loaded[item.sellerId] = time;
                    loading = difference(loading, [item.sellerId]);
                });
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const sellerSlice = createSelector(stateSelector, (state) => state.seller);

const idSelector = (_: GlobalState, id: number) => id;
const secondIdSelector = (_: GlobalState, __: number, id: number) => id;
const idsSelector = (_: GlobalState, ids: number[]) => ids;
const querySelector = (_: GlobalState, query: string) => query;

const byIdSelector = createSelector(sellerSlice, (state) => state.byId);

const loadedSelector = createSelector(sellerSlice, (state) => state.loaded);

const loadingSelector = createSelector(sellerSlice, (state) => state.loading);

const areAllSellersLoaded = createSelector(sellerSlice, (state) => state.loadedSellers || false);

export const areAllSellersLoading = createSelector(sellerSlice, (state) => state.loadingSellers || false);

const getAllSellers = createSelector(byIdSelector, (byId) => Object.keys(byId).map((key) => byId[key]));

// This is pretty gross.  Hopefully we can just get the correct form of data
const generateStateLists = (sellers: any = {}) => {
    const europeSellers = sellers.europe || [];
    const usSellers = sellers.us || [];
    const worldSellers = sellers.world || [];

    const europeSellerByState = groupBy(
        europeSellers.sort((a, b) => {
            if (a.country.toLowerCase() > b.country.toLowerCase()) {
                return 1;
            }
            return -1;
        }),
        (seller) => seller.countryCode
    );
    const usSellersByState = groupBy(
        usSellers.sort((a, b) => {
            if (a.stateName.toLowerCase() > b.stateName.toLowerCase()) {
                return 1;
            }
            return -1;
        }),
        (seller) => seller.stateCode
    );
    const worldSellersByState = groupBy(
        worldSellers.sort((a, b) => {
            if (a.country.toLowerCase() > b.country.toLowerCase()) {
                return 1;
            }
            return -1;
        }),
        (seller) => seller.countryCode
    );

    const europe: any[] = [];
    Object.keys(europeSellerByState).forEach((key) => {
        if (!europeSellerByState[key][0].country) {
            return;
        }
        const country = {
            name: europeSellerByState[key][0].country,
            sellers: europeSellerByState[key]
                .map((seller) => {
                    return { name: seller.name, sellerId: seller.sellerId };
                })
                .sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return -1;
                }),
        };
        europe.push(country);
    });

    const us: any[] = [];
    Object.keys(usSellersByState).forEach((key) => {
        if (!usSellersByState[key][0].stateName) {
            return;
        }
        const state = {
            name: usSellersByState[key][0].stateName,
            sellers: usSellersByState[key]
                .map((seller) => {
                    return { name: seller.name, sellerId: seller.sellerId };
                })
                .sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return -1;
                }),
        };
        us.push(state);
    });

    const world: any[] = [];
    Object.keys(worldSellersByState).forEach((key) => {
        if (!worldSellersByState[key][0].country) {
            return;
        }
        const country = {
            name: worldSellersByState[key][0].country,
            sellers: worldSellersByState[key]
                .map((seller) => {
                    return { name: seller.name, sellerId: seller.sellerId };
                })
                .sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return -1;
                }),
        };
        world.push(country);
    });

    return {
        europe,
        us,
        world,
    };
};

const getFilteredAllSellers = createSelector(getAllSellers, (allSellers) =>
    allSellers.filter((x) => {
        // Premiere Props has 3 seller ids now, filter out the first two from the directory page
        return x.sellerId !== 675 && x.sellerId !== 759;
    })
);

export const getAllSellersForDirectoryPage = createSelector(getFilteredAllSellers, (allSellers) => {
    const sellers = groupBy(allSellers, (seller) => seller.regionCode);
    return generateStateLists(sellers);
});

const getMatchingSellersInternal = createSelector([getFilteredAllSellers, querySelector], (sellers, query) => {
    if (!query) {
        return sellers;
    }

    const searchTokens = query.toLowerCase().split(/\s/);
    return sellers.filter((seller) => {
        const matchingTokens = searchTokens.filter((token) => {
            const { name = '' } = seller;
            let cleanName = '';
            if (name.indexOf('.') !== -1) {
                cleanName = name.replace(/\./g, '');
            }
            return `${name}`.toLowerCase().indexOf(token) !== -1 || cleanName.toLowerCase().indexOf(token) !== -1;
        });
        return matchingTokens.length === searchTokens.length;
    });
});

export const getMatchingSellersFunction = (state: GlobalState) => {
    return (query: string) => getMatchingSellersInternal(state, query);
};

export const shouldFetchSellers = createSelector(
    [areAllSellersLoaded, areAllSellersLoading],
    (allSellersLoaded, allSellersLoading) => !allSellersLoaded && !allSellersLoading
);

const getLoadTimeForSeller = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isSellerLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

export const getSeller = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || EmptySeller);

export const getSellerName = createSelector(getSeller, ({ name }) => name || '');

export const getSellerLogoId = createSelector(getSeller, ({ logoId }) => logoId || '');

export const getSellerLogoUrl = createSelector([getSellerLogoId, secondIdSelector], (logoId, width) =>
    getAuctioneerLogoUrl(logoId, width)
);

export const getSellerAddress = createSelector(getSeller, (seller) => {
    if (seller) {
        return {
            address: seller.address || '',
            address2: seller.address2 || '',
            city: seller.city || '',
            country: seller.country || 'United States',
            countryCode: seller.countryCode || 'us',
            stateCode: seller.stateCode || '',
            zip: seller.zip || '',
        };
    }
    return {};
});

export const getSellerEncodedLocation = createSelector(getSellerAddress, (sellerAddress) => {
    const location = [
        `${sellerAddress.address}`,
        `${sellerAddress.city}`,
        `${sellerAddress.stateCode}`,
        `${sellerAddress.zip}`,
        `${sellerAddress.country}`,
    ]
        // remove duplicate whitespaces then trim off extra spaces from the ends before we encode
        .join(' ')
        .replace(/\s+/g, ' ')
        .trim();
    return encodeURIComponent(location);
});
/**
 * getHousesById returns array of house/seller data from given array of ids
 * @param State state
 * @param number[] ids
 * @returns Seller[]
 */
export const getHousesById = createSelector([byIdSelector, idsSelector], (byId, ids) => {
    return ids.map((sellerId: number) => byId[sellerId]).filter((seller) => Boolean(seller)) as Seller[];
});

export const getSellerInArtaBeta = createSelector(getSeller, (seller) => {
    const betas = seller?.betas || [];
    return betas.includes(9);
});

export const getSellerInLiveShippingBeta = createSelector(getSeller, (seller) => {
    const betas = seller?.betas || [];
    const shippingBetas = [
        HouseBetas.LiveShippingViaPackengers,
        HouseBetas.LiveShippingViaNationalLogisticsService,
        HouseBetas.LiveShippingViaShippingSaint,
    ];
    return shippingBetas.some((betaId) => betas.includes(betaId));
});

export const getSellerInInvisibleApprovalBeta = createSelector(getSeller, (seller) => {
    const betas = seller?.betas || [];
    return betas.includes(HouseBetas.InvisibleApproval);
});

export const getSellerInExcludeAutoApprovalWith100OrLessBeta = createSelector(getSeller, (seller) => {
    const betas = seller?.betas || [];
    return betas.includes(HouseBetas.ExcludeAutoApprovalWith100OrLess);
});

export const getSellerATGPayBeta = createSelector(getSeller, (seller) => {
    const betas = seller?.betas || [];
    return betas.includes(HouseBetas.ATGPay);
});

export const getSellerInSkipCoFBeta = createSelector(getSeller, (seller) => {
    const betas = seller?.betas || [];
    return betas.includes(38);
});

export const shouldFetchSeller = (state: GlobalState, sellerId: number) => {
    if (!sellerId) {
        return false;
    }
    const item = getSeller(state, sellerId);
    if (item) {
        const loaded = getLoadTimeForSeller(state, sellerId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isSellerLoading(state, sellerId);
    return !loading;
};

const whichSellersNeeded = (state, sellerIds) => sellerIds.filter((sellerId) => shouldFetchSeller(state, sellerId));

/* ACTION CREATORS */
// all sellers
const loadAllSellers = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_ALL_SELLERS_REQUEST,
        });

        const response = await api.fetchSellers({ deployment });
        dispatch({
            meta: { actionTime: Date.now() },
            payload: response.data,
            type: LOAD_ALL_SELLERS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: LOAD_ALL_SELLERS_FAIL,
        });
    }
};

export const fetchAllSellersIfNeeded = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    if (shouldFetchSellers(getState())) {
        return dispatch(loadAllSellers());
    }
    return Promise.resolve();
};

// seller
const loadSellers = (sellerIds) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: sellerIds,
            type: LOAD_SELLERS_REQUEST,
        });
        const response = await api.fetchSellersByIds({
            deployment,
            sellerIds,
        });
        dispatch({
            meta: { actionTime: Date.now(), sellerIds },
            payload: response.data,
            type: LOAD_SELLERS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { sellerIds },
            payload: error,
            type: LOAD_SELLERS_FAIL,
        });
    }
};

export const fetchSellersIfNeeded = (sellerIds: number[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const neededSellers = whichSellersNeeded(getState(), sellerIds);
    const neededCatalogCounts = whichSellerCatalogCountsNeeded(getState(), sellerIds);
    const needed = union(neededSellers, neededCatalogCounts);

    if (needed.length) {
        return dispatch(loadSellers(needed));
    }
    return Promise.resolve();
};
