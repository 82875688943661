import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import api from '@/redux/api/sellerRecordResults';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
export const LOAD_SELLER_RECORD_RESULTS_FAIL = 'la/domain/sellerRecordResults/LOAD_FAIL';
export const LOAD_SELLER_RECORD_RESULTS_REQUEST = 'la/domain/sellerRecordResults/LOAD_REQUEST';
export const LOAD_SELLER_RECORD_RESULTS_SUCCESS = 'la/domain/sellerRecordResults/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type SellerRecordResultsSlice = {
    byId: any;
    loaded: any;
    loading: any[];
};

export const defaultSellerRecordResultsSlice: SellerRecordResultsSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(
    state: SellerRecordResultsSlice = defaultSellerRecordResultsSlice,
    action: any = {}
): SellerRecordResultsSlice {
    let existing;
    let loaded;
    let loading;
    let time;

    switch (action.type) {
        case LOAD_SELLER_RECORD_RESULTS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.sellerId]),
            };
        case LOAD_SELLER_RECORD_RESULTS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_SELLER_RECORD_RESULTS_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;

            if (action.payload.itemIds) {
                let sellerId = action.meta.sellerId;
                existing[sellerId] = [...action.payload.itemIds];
                loaded[sellerId] = time;
                loading = difference(loading, [sellerId]);
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const sellerRecordResultsSlice = createSelector(stateSelector, (state) => state.sellerRecordResults);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(sellerRecordResultsSlice, (state) => state.byId);

const loadedSelector = createSelector(sellerRecordResultsSlice, (state) => state.loaded);

const loadingSelector = createSelector(sellerRecordResultsSlice, (state) => state.loading);

// takes in a (state, id) and returns the data
export const getSellerRecordResultsIds = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);

// takes in a (state, id) and returns the load time
export const getLoadTimeForRecordResults = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

// takes in a (state, id) and returns the is loading
export const isSellerRecordResultsLoading = createSelector([loadingSelector, idSelector], (loading, id) => {
    return loading.indexOf(id) !== -1;
});

const shouldFetchSellerRecordResults = (state, sellerId) => {
    if (!sellerId) {
        return false;
    }
    const items = getSellerRecordResultsIds(state, sellerId);
    if (items.length) {
        const loaded = getLoadTimeForRecordResults(state, sellerId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isSellerRecordResultsLoading(state, sellerId);
    return !loading;
};

/* ACTION CREATORS */
const loadSellerRecordResults = (sellerId) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: sellerId,
            type: LOAD_SELLER_RECORD_RESULTS_REQUEST,
        });
        const response = await api.fetchSellerRecordResultsById({
            deployment,
            sellerId,
        });
        dispatch({
            meta: { actionTime: Date.now(), sellerId },
            payload: response.data,
            type: LOAD_SELLER_RECORD_RESULTS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { sellerId },
            payload: error,
            type: LOAD_SELLER_RECORD_RESULTS_FAIL,
        });
    }
};

export const fetchSellerRecordResultsIfNeeded =
    (sellerId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        if (shouldFetchSellerRecordResults(getState(), sellerId)) {
            return dispatch(loadSellerRecordResults(sellerId));
        }

        return Promise.resolve();
    };
