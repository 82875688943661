import { createSlice } from '@reduxjs/toolkit';
import {
    defaultSearchExclusionSlice,
    SearchExclusionSlice,
} from '@/redux/modules/search/exclusions/searchExclusions.types';
import { fetchBidderData } from '@/redux/modules/account/user/user.actions';
import { FulfilledActionFromAsyncThunk } from '@/redux/createAsyncThunk';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';
import { PASSWORD_RESET_REQUEST, PasswordResetRequestAction } from '../../passwordReset';
import { setSearchExclusion } from '@/redux/modules/search/exclusions/searchExclusions.actions';
import { submitConfirmPassword } from '../../confirmPassword/confirmPassword.actions';
import { submitCreateAccount } from '@/redux/modules/account/createAccount/createAccount.actions';
import { submitLogin } from '@/redux/modules/account/login/login.actions';

type LoginAction =
    | FulfilledActionFromAsyncThunk<typeof submitConfirmPassword>
    | FulfilledActionFromAsyncThunk<typeof fetchBidderData>
    | FulfilledActionFromAsyncThunk<typeof submitLogin>;

const populateSliceFromLogin = (slice: SearchExclusionSlice, action: LoginAction) => {
    if (action.payload.searchExclusions) {
        slice.excludedFacets = {
            auctionHouse: action.payload.searchExclusions.houseIds.map((houseId) => String(houseId)),
        };
    }
    slice.loaded = Date.now();
    slice.loading = false;
};

const enterLoadingState = (slice: SearchExclusionSlice) => {
    slice.loading = true;
};

const searchExclusionsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(setSearchExclusion.pending, enterLoadingState);
        builder.addCase(setSearchExclusion.fulfilled, (slice, { payload }) => {
            if (payload.houseIds) {
                slice.excludedFacets = {
                    auctionHouse: payload.houseIds.map((houseId) => String(houseId)),
                };
            }
            slice.loaded = Date.now();
            slice.loading = false;
        });
        builder.addCase(setSearchExclusion.rejected, (slice) => {
            slice.loading = false;
        });

        builder.addCase(submitCreateAccount.pending, enterLoadingState);
        builder.addCase(submitConfirmPassword.fulfilled, populateSliceFromLogin);

        builder.addCase(fetchBidderData.pending, enterLoadingState);
        builder.addCase(fetchBidderData.fulfilled, populateSliceFromLogin);

        builder.addCase(submitLogin.pending, enterLoadingState);
        builder.addCase(submitLogin.fulfilled, populateSliceFromLogin);

        builder.addCase<typeof PASSWORD_RESET_REQUEST, PasswordResetRequestAction>(
            PASSWORD_RESET_REQUEST,
            enterLoadingState
        );

        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(
            LOG_OUT_BIDDER,
            () => defaultSearchExclusionSlice
        );
    },
    initialState: defaultSearchExclusionSlice,
    name: 'searchExclusions',
    reducers: {},
});

export const { reducer: searchExclusionsReducer } = searchExclusionsSlice;
