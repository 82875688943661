import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { EmptySellerDetail, SellerDetail } from '@/types/SellerDetail';
import { fetchSellerDetailsByIds } from '@/redux/api/sellerDetail';
import { getDeployment } from './config';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
export const LOAD_SELLER_DETAILS_FAIL = 'la/domain/sellerDetail/LOAD_FAIL';
export const LOAD_SELLER_DETAILS_REQUEST = 'la/domain/sellerDetail/LOAD_REQUEST';
export const LOAD_SELLER_DETAILS_SUCCESS = 'la/domain/sellerDetail/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type SellerDetailSlice = {
    byId: { [sellerId: number]: SellerDetail };
    loaded: { [sellerId: number]: number };
    loading: number[];
};

export const defaultSellerDetailSlice: SellerDetailSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(
    state: SellerDetailSlice = defaultSellerDetailSlice,
    action: any = {}
): SellerDetailSlice {
    let existing;
    let loaded;
    let loading;
    let time;

    switch (action.type) {
        case LOAD_SELLER_DETAILS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, action.meta.sellerIds),
            };
        case LOAD_SELLER_DETAILS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, action.payload),
            };
        case LOAD_SELLER_DETAILS_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;

            if (action.payload.sellerDetails) {
                action.payload.sellerDetails.forEach((x) => {
                    existing[x.sellerId] = { ...x };
                    loaded[x.sellerId] = time;
                    loading = difference(loading, [x.sellerId]);
                });
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const sellerDetailSlice = createSelector(stateSelector, (state) => state.sellerDetail);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(sellerDetailSlice, (state) => state.byId);

const loadedSelector = createSelector(sellerDetailSlice, (state) => state.loaded);

const loadingSelector = createSelector(sellerDetailSlice, (state) => state.loading);

// takes in a (state, id) and returns the data
export const getSellerDetail = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || EmptySellerDetail);
export const getSellerPhoneNumber = createSelector(getSellerDetail, ({ phoneNumber }) => phoneNumber || '');
export const getSellerDescription = createSelector(getSellerDetail, ({ description }) => description || '');
export const getSellerEmail = createSelector(getSellerDetail, ({ email }) => email || '');

// takes in a (state, id) and returns the load time
export const getLoadTimeForSellerDetail = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

// takes in a (state, id) and returns the is loading
export const isSellerDetailLoading = createSelector([loadingSelector, idSelector], (loading, id) => {
    return loading.indexOf(id) !== -1;
});

const shouldFetchSellerDetail = (state, sellerId) => {
    const data = getSellerDetail(state, sellerId);
    if (!isEmpty(data)) {
        const loaded = getLoadTimeForSellerDetail(state, sellerId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isSellerDetailLoading(state, sellerId);
    return !loading;
};

const whichSellerDetailsNeeded = (state, sellerIds) =>
    sellerIds.filter((sellerId) => shouldFetchSellerDetail(state, sellerId));

/* ACTION CREATORS */
const loadSellerDetails = (sellerIds) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: sellerIds,
            type: LOAD_SELLER_DETAILS_REQUEST,
        });
        const response = await fetchSellerDetailsByIds({
            deployment,
            sellerIds,
        });
        return dispatch({
            meta: { actionTime: Date.now(), sellerIds },
            payload: response.data,
            type: LOAD_SELLER_DETAILS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { sellerIds },
            payload: error,
            type: LOAD_SELLER_DETAILS_FAIL,
        });
    }
};

export const fetchSellersDetailsIfNeeded =
    (sellerIds: number[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const needed = whichSellerDetailsNeeded(getState(), sellerIds);
        if (needed.length) {
            return dispatch(loadSellerDetails(needed));
        }
        return Promise.resolve();
    };
