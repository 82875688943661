import { getRetractedAbsenteeBidAnalytics } from '@/redux/modules/analytics';
import { Location } from 'react-router-dom';

export type RetractBidSlice = {
    bidRetracted: boolean;
    error: boolean;
    itemId: number;
    submitted: boolean;
    success: boolean;
};

export const defaultRetractBidSlice: RetractBidSlice = {
    bidRetracted: false,
    error: false,
    itemId: null,
    submitted: false,
    success: false,
};

export type SubmitRetractBidParams = {
    itemId: number;
    location: Location;
    retractionExplanation: string;
    retractionReason: string;
};

export type SubmitRetractBidPayload = {
    analytics: ReturnType<typeof getRetractedAbsenteeBidAnalytics>;
    itemId: number;
};
