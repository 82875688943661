import { Address } from '@liveauctioneers/caterwaul-components/types/Address';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import {
    LOAD_TAX_STATUS_FAIL,
    LOAD_TAX_STATUS_REQUEST,
    LOAD_TAX_STATUS_SUCCESS,
    UPDATE_TAX_ADDRESS_FAIL,
    UPDATE_TAX_ADDRESS_REQUEST,
    UPDATE_TAX_ADDRESS_SUCCESS,
} from './actions';
import api from '@/redux/api/taxes';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

/* reducer */
export type TaxesSlice = {
    byId: {};
    loaded: {};
    loading: any[];
};

export const defaultTaxesSlice: TaxesSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(state: TaxesSlice = defaultTaxesSlice, action: any = {}): TaxesSlice {
    let existing;
    let loaded;
    let loading;

    switch (action.type) {
        case LOAD_TAX_STATUS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.invoiceId]),
            };

        case LOAD_TAX_STATUS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_TAX_STATUS_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            existing[action.meta.invoiceId] = action.payload;
            loaded[action.meta.invoiceId] = action.meta.actionTime;
            loading = difference(loading, [action.meta.invoiceId]);

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const taxesSlice = createSelector(stateSelector, (state) => state.taxes);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(taxesSlice, (state) => state.byId);

export const getTaxStatus = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || {});

/* ACTION CREATORS */
export const fetchTaxStatus = (invoiceId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        dispatch({
            payload: invoiceId,
            type: LOAD_TAX_STATUS_REQUEST,
        });

        const response = await api.fetchTaxStatus({
            authToken,
            deployment,
            invoiceId,
        });

        return dispatch({
            meta: { actionTime: Date.now(), invoiceId },
            payload: response.payload,
            type: LOAD_TAX_STATUS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            meta: { invoiceId },
            payload: error,
            type: LOAD_TAX_STATUS_FAIL,
        });
    }
};

export const updateTaxAddress =
    (catalogId: number, invoiceId: number, shippingAddress: Address, phoneNumber: string, skipProfileUpdate: boolean) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);

            dispatch({
                payload: invoiceId,
                type: UPDATE_TAX_ADDRESS_REQUEST,
            });

            const response = await api.updateTaxAddress({
                authToken,
                deployment,
                invoiceId,
                shippingAddress: {
                    address1: shippingAddress.address1,
                    address2: shippingAddress.address2,
                    city: shippingAddress.city,
                    countryCode: shippingAddress.countryCode,
                    organizationName: shippingAddress.organizationName,
                    phoneNumber: phoneNumber,
                    postalCode: shippingAddress.postalCode,
                    state: shippingAddress.state,
                },
                skipProfileUpdate,
            });

            return dispatch({
                meta: { actionTime: Date.now(), invoiceId },
                payload: response.payload,
                type: UPDATE_TAX_ADDRESS_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                meta: { invoiceId },
                payload: error,
                type: UPDATE_TAX_ADDRESS_FAIL,
            });
        }
    };
