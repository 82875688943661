import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { LOAD_SIMILAR_ITEMS_SUCCESS } from './actions';
import cloneDeep from 'lodash/cloneDeep';

/* reducer */
export type SimilarItemsCategoriesSlice = {
    byId: {};
    loaded: {};
};

export const defaultSimilarItemsCategoriesSlice: SimilarItemsCategoriesSlice = {
    byId: {},
    loaded: {},
};

export default function reducer(
    state: SimilarItemsCategoriesSlice = defaultSimilarItemsCategoriesSlice,
    action: any = {}
): SimilarItemsCategoriesSlice {
    switch (action.type) {
        case LOAD_SIMILAR_ITEMS_SUCCESS:
            const existing = cloneDeep(state.byId);
            action.payload.forEach(({ categories, itemId }) => {
                existing[itemId] = categories;
            });
            return {
                ...state,
                byId: existing,
                loaded: {
                    ...state.loaded,
                    [action.meta.itemId]: action.meta.actionTime,
                },
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const similarItemsCategoriesSlice = createSelector(stateSelector, (state) => state.similarItemsCategories);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(similarItemsCategoriesSlice, (state) => state.byId);

export const getSimilarItemCategories = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);
