import { ShippingEstimate } from '@/types/ShippingEstimate';
import ms from 'ms';

export type ShippingSlice = {
    byId: { [id: number]: ShippingEstimate | HiddenQuoteReason };
    loaded: { [id: number]: number };
    loading: number[];
};

export const defaultShippingSlice: ShippingSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export const SHIPPING_CACHE_TIME = ms('30m');

export type ShippingEstimateParams = {
    countryCode: string;
    itemId: number;
    postalCode: string;
};

export enum HiddenQuoteReason {
    BadZipCode = 'Bad ZIP Code',
    MissingDimensions = 'Missing Dimensions',
    SystemOrPartnerError = 'System or Partner Error',
    TooExpensive = 'Too Expensive',
}

export type HiddenQuoteRequestParams = {
    countryCode?: string;
    emailAddress: string;
    itemId: number;
    postalCode: string;
};
