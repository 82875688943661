import { createSelector } from '@reduxjs/toolkit';
import { getAllItemSummaries } from '@/redux/modules/item/summary/itemSummary.selectors';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const searchSlice = createSelector(stateSelector, (state) => state.search);

export const getUseGeoLocation = createSelector(searchSlice, (state) => state.useGeoLocation);

export const getSearchInputBuffer = createSelector(searchSlice, (state) => state.searchInputBuffer);

export const getSearchTotalFound = createSelector(searchSlice, (state) => state.totalFound);

export const getSearchSubmittedFromPage = createSelector(searchSlice, (state) => state.submittedFromPage);

export const getSearchSellerItemsSearch = createSelector(searchSlice, (state) => state.sellerItemsSearch);

export const getSearchSellerItemsSearchSellerId = createSelector(
    searchSlice,
    (state) => state.sellerItemsSearchSellerId
);

export const getCurrentSearch = createSelector(searchSlice, (state) => state.currentQueryKey);

export const getCategoryCrumbs = createSelector(searchSlice, (state) => state.categoryCrumbs || []);

export const getSearchedItemIds = createSelector(searchSlice, (state) => state.itemIds || []);

export const getFirstSearchItemId = createSelector(getSearchedItemIds, (itemIds) => itemIds?.[0]);

const getSearchedItems = createSelector([getSearchedItemIds, getAllItemSummaries], (itemIds, allItems) => {
    return itemIds.map((itemId) => allItems[itemId]).filter((item) => Boolean(item)); // ensure that we only send back items that exist
});

export const getSellersFromSearchedItems = createSelector(getSearchedItems, (items) =>
    items.map((item) => item.sellerId)
);

export const getSearchUiState = createSelector(searchSlice, (state) => {
    return {
        error: state.error,
        isInitialLoading: state.isInitialLoading,
        isLoading: state.isLoading,
        soldItemIds: state.soldItemIds || [],
        sort: state.sort,
        submitted: state.submitted,
        success: state.success,
        totalBuyNowItems: state.totalBuyNowItems,
        totalFound: state.totalFound,
        totalLiveItems: state.totalLiveItems,
        totalPages: state.totalPages,
        totalSoldItems: state.totalSoldItems,
    };
});
