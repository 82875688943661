import * as api from '@/redux/modules/item/saved/savedItems.api';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
export const LOAD_SAVED_ITEM_COUNT_FAIL = 'la/domain/savedItemCount/LOAD_FAIL';
export const LOAD_SAVED_ITEM_COUNT_REQUEST = 'la/domain/savedItemCount/LOAD_REQUEST';
export const LOAD_SAVED_ITEM_COUNT_SUCCESS = 'la/domain/savedItemCount/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type SavedItemCountSlice = {
    byId: { [id: number]: { itemId: number; savedCount: number } };
    loaded: { [id: number]: number };
    loading: number[];
};

export const defaultSavedItemCountSlice: SavedItemCountSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(
    state: SavedItemCountSlice = defaultSavedItemCountSlice,
    action: any = {}
): SavedItemCountSlice {
    let existing;
    let loaded;
    let loading;
    let time;

    switch (action.type) {
        case LOAD_SAVED_ITEM_COUNT_FAIL:
            return {
                ...state,
                loading: difference(state.loading, action.meta.itemIds),
            };
        case LOAD_SAVED_ITEM_COUNT_REQUEST:
            return {
                ...state,
                loading: union(state.loading, action.payload),
            };
        case LOAD_SAVED_ITEM_COUNT_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;

            if (action.payload) {
                action.payload.forEach((x) => {
                    existing[x.itemId] = { ...x };
                    loaded[x.itemId] = time;
                    loading = difference(loading, [x.itemId]);
                });
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const savedItemCountSlice = createSelector(stateSelector, (state) => state.savedItemCount);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(savedItemCountSlice, (state) => state.byId);

const loadedSelector = createSelector(savedItemCountSlice, (state) => state.loaded);

const loadingSelector = createSelector(savedItemCountSlice, (state) => state.loading);

// takes in a (state, id) and returns the data
export const getSavedItemCount = createSelector(
    [byIdSelector, idSelector],
    (byId, id) => byId[id] || { itemId: id, savedCount: 0 }
);
// takes in a (state, id) and returns the saved count without the itemId
export const getSavedItemCountNum = createSelector(getSavedItemCount, ({ savedCount }) => savedCount || 0);

// takes in a (state, id) and returns the load time
export const getLoadTimeForSavedItemCount = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

// takes in a (state, id) and returns the is loading
export const isSavedItemCountLoading = createSelector([loadingSelector, idSelector], (loading, id) => {
    return loading.indexOf(id) !== -1;
});

const shouldFetchSavedItemCount = (state, itemId) => {
    const data = getSavedItemCount(state, itemId);
    if (data.savedCount !== 0) {
        const loaded = getLoadTimeForSavedItemCount(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isSavedItemCountLoading(state, itemId);
    return !loading;
};

const whichSavedItemCountsNeeded = (state, itemIds) =>
    itemIds.filter((itemId) => shouldFetchSavedItemCount(state, itemId));

/* ACTION CREATORS */
const loadSavedItemCounts = (itemIds) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: itemIds,
            type: LOAD_SAVED_ITEM_COUNT_REQUEST,
        });
        const response = await api.getSavedItemCounts({
            deployment,
            itemIds,
        });

        dispatch({
            meta: { actionTime: Date.now(), itemIds },
            payload: response.payload,
            type: LOAD_SAVED_ITEM_COUNT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { itemIds },
            payload: error,
            type: LOAD_SAVED_ITEM_COUNT_FAIL,
        });
    }
};

export const fetchSavedItemCountsIfNeeded =
    (itemIds: number[], force: boolean = false) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const needed = force ? itemIds : whichSavedItemCountsNeeded(getState(), itemIds);
        if (needed.length) {
            return dispatch(loadSavedItemCounts(needed));
        }
        return Promise.resolve();
    };
