import { createSlice } from '@reduxjs/toolkit';
import { defaultShippingQuoteSlice, ShippingQuoteSlice } from './shippingQuote.types';
import { fetchPurchasedShippingQuote, purchaseShippingQuote } from './shippingQuote.actions';
import { ShippingMethods } from '@/enums/shippingMethod';
import { ShippingQuote } from '@/types/ShippingQuote';
import difference from 'lodash/difference';
import union from 'lodash/union';

const onLoadData = (
    slice: ShippingQuoteSlice,
    catalogId: number,
    time: number,
    payload: ShippingQuote
): ShippingQuoteSlice => {
    if (slice.byId[catalogId]) {
        const existingEntry = slice.byId[catalogId];
        slice.byId[catalogId] = {
            currency: payload.currency || existingEntry.currency || '',
            link: payload.link || existingEntry.link || '',
            message: payload.message || '',
            quoteId: payload.quoteId || existingEntry.quoteId || '',
            quoteNumber: payload.quoteNumber || existingEntry.quoteNumber || '',
            shippingAvailable: payload.shippingAvailable || existingEntry.shippingAvailable || false,
            shippingMethod: payload.shippingMethod || existingEntry.shippingMethod || ShippingMethods.Unset,
            shippingProvider: payload.shippingProvider || existingEntry.shippingProvider || 0,
            shippingStatus: payload.shippingStatus || existingEntry.shippingStatus || '',
            shippingStatusSecondary: payload.shippingStatusSecondary || existingEntry.shippingStatusSecondary || '',
            shippingTrackingLink: payload.shippingTrackingLink || existingEntry.shippingTrackingLink || '',
            statusTerminal: payload.statusTerminal || existingEntry.statusTerminal || false,
            total: payload.total ? payload.total : existingEntry.total || '0',
            transitDays: payload.transitDays || existingEntry.transitDays || 0,
        };
    } else {
        slice.byId[catalogId] = payload;
    }

    slice.loaded[catalogId] = time;
    slice.loading = difference(slice.loading, [catalogId]);
    return slice;
};

const shippingQuoteSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(purchaseShippingQuote.pending, (slice, { meta }) => {
            slice.loading = union(slice.loading, [meta.arg.catalogId]);
        });
        builder.addCase(purchaseShippingQuote.fulfilled, (slice, { meta, payload }) =>
            onLoadData(slice, meta.arg.catalogId, Date.now(), payload)
        );

        builder.addCase(fetchPurchasedShippingQuote.pending, (slice, { meta }) => {
            slice.loading = union(slice.loading, [meta.arg.catalogId]);
        });
        builder.addCase(fetchPurchasedShippingQuote.fulfilled, (slice, { meta, payload }) =>
            onLoadData(slice, meta.arg.catalogId, Date.now(), payload)
        );
    },
    initialState: defaultShippingQuoteSlice,
    name: 'shippingQuote',
    reducers: {},
});

export const { reducer: shippingQuoteReducer } = shippingQuoteSlice;
