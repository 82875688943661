import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import ms from 'ms';

const stateSelector = (state: GlobalState) => state;
const shippingQuoteSlice = createSelector(stateSelector, (state) => state.shippingQuote);

type ShippingQuoteCatalogId = number;
const idSelector = (_: GlobalState, id: ShippingQuoteCatalogId) => id;

const byIdSelector = createSelector(shippingQuoteSlice, ({ byId }) => byId);
const loadedSelector = createSelector(shippingQuoteSlice, ({ loaded }) => loaded);
const loadingSelector = createSelector(shippingQuoteSlice, ({ loading }) => loading);

export const getShippingQuote = createSelector([byIdSelector, idSelector], (byId, id) => byId[id]);

const getLoadTimeForShippingQuote = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);
export const isShippingQuoteLoading = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(id)
);

export const SHIPPING_QUOTE_SLICE_CACHE_TIME = ms('30m');
export const shouldFetchShippingQuote = (state: GlobalState, catalogId: ShippingQuoteCatalogId): boolean => {
    if (!catalogId) {
        return false;
    }
    if (Boolean(getShippingQuote(state, catalogId))) {
        const loaded = getLoadTimeForShippingQuote(state, catalogId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < SHIPPING_QUOTE_SLICE_CACHE_TIME) {
            return false;
        }
    }
    return !isShippingQuoteLoading(state, catalogId);
};
