import * as api from '@/redux/api/saveSearch';
import { ActionWithPayload } from '@/types/redux';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector, Reducer } from '@reduxjs/toolkit';
import {
    DELETE_SAVE_SEARCH_FAIL,
    DELETE_SAVE_SEARCH_REQUEST,
    DELETE_SAVE_SEARCH_SUCCESS,
    DELETE_SAVED_SEARCH_FAIL,
    DELETE_SAVED_SEARCH_REQUEST,
    DELETE_SAVED_SEARCH_SUCCESS,
    LOAD_SAVED_SEARCH_ITEMS_FAIL,
    LOAD_SAVED_SEARCH_ITEMS_REQUEST,
    LOAD_SAVED_SEARCH_ITEMS_SUCCESS,
    LOAD_SAVED_SEARCHES_FAIL,
    LOAD_SAVED_SEARCHES_REQUEST,
    LOAD_SAVED_SEARCHES_SUCCESS,
    LoadSavedSearchesSuccessAction,
    SAVED_SEARCH_FILTER_CHANGE,
    SAVED_SEARCH_ITEMS_FILTER_CHANGE,
    SUBSCRIBE_ALL_SAVED_SEARCH_FAIL,
    SUBSCRIBE_ALL_SAVED_SEARCH_REQUEST,
    SUBSCRIBE_ALL_SAVED_SEARCH_SUCCESS,
    SUBSCRIBE_SAVED_SEARCH_FAIL,
    SUBSCRIBE_SAVED_SEARCH_REQUEST,
    SUBSCRIBE_SAVED_SEARCH_SUCCESS,
    UNSUBSCRIBE_ALL_SAVED_SEARCH_FAIL,
    UNSUBSCRIBE_ALL_SAVED_SEARCH_REQUEST,
    UNSUBSCRIBE_ALL_SAVED_SEARCH_SUCCESS,
    UNSUBSCRIBE_SAVED_SEARCH_FAIL,
    UNSUBSCRIBE_SAVED_SEARCH_REQUEST,
    UNSUBSCRIBE_SAVED_SEARCH_SUCCESS,
} from './actions';
import {
    deleteSavedSearchUrl,
    fetchSavedSearches,
    fetchSavedSearchItems,
    postDeleteSavedSearch,
    postSubscribeAllFromSavedSearch,
    postSubscribeToSavedSearch,
    postUnsubscribeAllFromSavedSearch,
    postUnsubscribeFromSavedSearch,
} from '@/redux/api/saveSearch';
import { getAuthToken, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { getSavedSearchKeyById } from './saveSearch';
import { getUnfollowSearchAnalytics } from './analytics';
import { LOG_OUT_BIDDER } from '@/redux/modules/account/logout/logout.actions';
import { ManageSavedSearchSortOrders, savedSearchItemSortOrders } from '@/enums';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import ms from 'ms';

export const LOADING_SAVED_SEARCH_ITEM_IDS = 'saved-search-items';

const REDUX_STORE_TIME = ms('5m');

/* reducer */
export type SavedSearchState = {
    error: string;
    itemIds: number[];
    itemsById: {
        [id: number]: {
            itemId: number;
            publishDate: string;
            publishTs: number;
        };
    };
    itemsLoaded: number;
    itemsLoading: boolean;
    savedSearchFilters: PaginationFilter;
    savedSearchesRecentFilters: PaginationFilter;
    searches: api.SavedSearch[];
    searchesLoaded: number;
    searchesLoading: boolean;
    totalSearchItems: number;
    totalSearches: number;
};

export const DEFAULT_STATE: SavedSearchState = {
    error: '',
    itemIds: [],
    itemsById: {},
    itemsLoaded: 0,
    itemsLoading: false,
    savedSearchesRecentFilters: {
        keyword: '',
        page: 1,
        pageSize: 24,
        sort: savedSearchItemSortOrders.NEWLY_LISTED,
        tab: 'items',
    },
    savedSearchFilters: {
        page: 1,
        pageSize: 24,
        sort: ManageSavedSearchSortOrders.RESULT_COUNT,
    },
    searches: [],
    searchesLoaded: 0,
    searchesLoading: false,
    totalSearches: 0,
    totalSearchItems: 0,
};

export const savedSearch: Reducer<SavedSearchState> = handleActions(
    {
        [combineActions(LOAD_SAVED_SEARCHES_FAIL, LOAD_SAVED_SEARCH_ITEMS_FAIL)]: (
            state: SavedSearchState,
            action: ActionWithPayload<any>
        ) => ({
            ...state,
            error: action.payload,
            itemsLoading: false,
            searchesLoading: false,
        }),
        [DELETE_SAVED_SEARCH_SUCCESS]: (state: SavedSearchState, action: ActionWithPayload<any>) => {
            let searches = cloneDeep(state.searches).filter((search) => search.savedSearchId !== action.payload);
            return {
                ...state,
                searches,
            };
        },
        [LOAD_SAVED_SEARCH_ITEMS_REQUEST]: (state: SavedSearchState) => ({
            ...state,
            error: '',
            itemsLoaded: 0,
            itemsLoading: true,
        }),
        [LOAD_SAVED_SEARCH_ITEMS_SUCCESS]: (state: SavedSearchState, action: ActionWithPayload<any>) => {
            let itemsById = {};
            let itemIds: number[] = [];
            action.payload?.data?.forEach((x) => {
                itemsById[x.itemId] = {
                    itemId: x.itemId,
                    publishDate: x.publishDate,
                    //todo don't have both date and ts
                    publishTs: Date.parse(x.publishDate) / 1000,
                };
                itemIds.push(x.itemId);
            });
            return {
                ...state,
                itemIds,
                itemsById,
                itemsLoaded: Date.now(),
                itemsLoading: false,
                savedSearchesRecentFilters: {
                    ...state.savedSearchesRecentFilters,
                },
                totalSearchItems: action.payload.totalRecords,
            };
        },
        [LOAD_SAVED_SEARCHES_REQUEST]: (state: SavedSearchState) => ({
            ...state,
            searchesLoading: true,
        }),
        [LOAD_SAVED_SEARCHES_SUCCESS]: (
            state: SavedSearchState,
            action: LoadSavedSearchesSuccessAction
        ): SavedSearchState => {
            return {
                ...state,
                savedSearchesRecentFilters: {
                    ...state.savedSearchesRecentFilters,
                },
                searches: action.payload,
                searchesLoaded: Date.now(),
                searchesLoading: false,
                totalSearches: action.meta.totalRecords,
            };
        },
        [LOG_OUT_BIDDER]: () => ({
            ...DEFAULT_STATE,
        }),
        [SAVED_SEARCH_FILTER_CHANGE]: (state: SavedSearchState, action: ActionWithPayload<PaginationFilter>) => {
            return {
                ...state,
                itemIds: DEFAULT_STATE.itemIds,
                itemsById: DEFAULT_STATE.itemsById,
                itemsLoaded: DEFAULT_STATE.itemsLoaded,
                savedSearchFilters: {
                    ...state.savedSearchFilters,
                    ...action.payload,
                },
            };
        },
        [SAVED_SEARCH_ITEMS_FILTER_CHANGE]: (state: SavedSearchState, action: ActionWithPayload<PaginationFilter>) => {
            return {
                ...state,
                itemIds: DEFAULT_STATE.itemIds,
                itemsById: DEFAULT_STATE.itemsById,
                itemsLoaded: DEFAULT_STATE.itemsLoaded,
                savedSearchesRecentFilters: {
                    ...state.savedSearchesRecentFilters,
                    ...action.payload,
                },
            };
        },
        [SUBSCRIBE_ALL_SAVED_SEARCH_REQUEST]: (state: SavedSearchState) => {
            let searches = cloneDeep(state.searches);
            searches.forEach((x) => {
                x.sendAlert = true;
            });

            return {
                ...state,
                searches,
            };
        },
        [SUBSCRIBE_SAVED_SEARCH_REQUEST]: (state: SavedSearchState, action: ActionWithPayload<any>) => {
            let searches = cloneDeep(state.searches);
            searches.forEach((x) => {
                if (x.savedSearchId === action.payload) {
                    x.sendAlert = true;
                }
            });

            return {
                ...state,
                searches,
            };
        },
        [UNSUBSCRIBE_ALL_SAVED_SEARCH_REQUEST]: (state: SavedSearchState) => {
            let searches = cloneDeep(state.searches);
            searches.forEach((x) => {
                x.sendAlert = false;
            });

            return {
                ...state,
                searches,
            };
        },
        [UNSUBSCRIBE_SAVED_SEARCH_REQUEST]: (state: SavedSearchState, action: ActionWithPayload<any>) => {
            let searches = cloneDeep(state.searches);
            searches.forEach((x) => {
                if (x.savedSearchId === action.payload) {
                    x.sendAlert = false;
                }
            });

            return {
                ...state,
                searches,
            };
        },
    },
    DEFAULT_STATE
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const savedSearchSlice = createSelector(stateSelector, (state) => state.savedSearch);

const idSelector = (_: GlobalState, id: string | number) => id;

export const savedSearchSearchesSelector = createSelector(savedSearchSlice, (savedSearch) => savedSearch.searches);

export const getSavedSearchTitles = createSelector(savedSearchSearchesSelector, (savedSearches) =>
    savedSearches?.map((savedSearch) => savedSearch.title)
);

export const hasSavedSearchesSelector = createSelector(
    savedSearchSearchesSelector,
    (savedSearches) => savedSearches?.length > 0
);

export const getSavedSearchById: (state: any, selector: any) => any = createSelector(
    [savedSearchSearchesSelector, idSelector],
    (savedSearches, id) => savedSearches.find((x) => x.savedSearchId === id)
);

const getLoadTimeForSavedSearches = createSelector(savedSearchSlice, (savedSearch) => savedSearch.searchesLoaded);

export const getHasSavedSearchItems = createSelector(savedSearchSlice, (savedSearch) =>
    Boolean(savedSearch.itemIds?.length)
);

export const isSavedSearchesLoading = createSelector(savedSearchSlice, (savedSearch) => savedSearch.searchesLoading);

const itemsByIdSelector = createSelector(savedSearchSlice, (state) => state.itemsById);

export const getSavedSearchItemIds = createSelector(savedSearchSlice, (state) => state.itemIds);
export const getLimitedSavedSearchItemIds = createSelector(savedSearchSlice, (state) => state.itemIds.slice(0, 12));

const getLoadTimeForSavedSearchItems = createSelector(savedSearchSlice, (state) => state.itemsLoaded);

export const isSavedSearchItemsLoading = createSelector(savedSearchSlice, (state) => state.itemsLoading);

export const getTotalSavedSearches = createSelector(savedSearchSlice, (state) => state.totalSearches);

export const getTotalSavedSearchItems = createSelector(savedSearchSlice, (state) => state.totalSearchItems);

export const getSavedSearchesRecentFilters = createSelector(
    savedSearchSlice,
    (state) => state.savedSearchesRecentFilters
);

export const getSavedSearchFilters = createSelector(savedSearchSlice, (state) => state.savedSearchFilters);

export const getSavedSearchItem = createSelector(
    [itemsByIdSelector, idSelector],
    (itemsById, id) =>
        itemsById[id] || {
            itemId: id,
            publishTs: 0,
        }
);

const shouldFetchSavedSearches = (
    state: GlobalState,
    nextSearchFilters: SavedSearchState['savedSearchesRecentFilters']
) => {
    if (isEqual(state.savedSearch.savedSearchesRecentFilters, nextSearchFilters)) {
        return false;
    }
    const searchesLoaded = getLoadTimeForSavedSearches(state);
    const time = Date.now();
    const diff = time - searchesLoaded;
    if (diff < REDUX_STORE_TIME) {
        return false;
    }
    return !isSavedSearchesLoading(state);
};

export const getSavedSearchesCount = (state: GlobalState) => {
    const items = savedSearchSearchesSelector(state);

    return items?.length;
};

/* ACTION CREATORS */
export const fetchSavedSearchesIfNeeded =
    (
        force: boolean = false,
        page: number = 1,
        pageSize: number = 24,
        sort: string = ManageSavedSearchSortOrders.DATE_ADDED_NEWEST,
        keyword: string = '',
        token?: string
    ) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const loggedIn = isUserLoggedIn(state);
        const nextSearchFilters: SavedSearchState['savedSearchesRecentFilters'] = {
            keyword,
            page,
            pageSize,
            sort,
            tab: '',
        };

        if ((force || shouldFetchSavedSearches(state, nextSearchFilters)) && loggedIn) {
            const deployment = getDeployment(state);
            try {
                dispatch({
                    type: LOAD_SAVED_SEARCHES_REQUEST,
                });
                const response = await fetchSavedSearches({
                    authToken: token || authToken,
                    deployment,
                    keyword,
                    page,
                    pageSize,
                    sort,
                });
                const successAction: LoadSavedSearchesSuccessAction = {
                    error: false,
                    meta: {
                        actionTime: Date.now(),
                        page: response.payload.page,
                        pageSize: response.payload.pageSize,
                        totalRecords: response.payload.totalRecords,
                    },
                    payload: response.payload.data,
                    type: LOAD_SAVED_SEARCHES_SUCCESS,
                };
                dispatch(successAction);
            } catch (error) {
                dispatch({
                    error: true,
                    payload: error,
                    type: LOAD_SAVED_SEARCHES_FAIL,
                });
            }
        }
        return Promise.resolve();
    };

export const submitDeleteSavedSearch = (savedSearch: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const { savedSearchId } = savedSearch;
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);
    const analytics = getUnfollowSearchAnalytics();
    const key = getSavedSearchKeyById(state, savedSearchId);

    try {
        dispatch({
            payload: savedSearchId,
            type: DELETE_SAVED_SEARCH_REQUEST,
        });
        dispatch({
            payload: key,
            type: DELETE_SAVE_SEARCH_REQUEST,
        });
        const response = await postDeleteSavedSearch({
            authToken,
            deployment,
            savedSearchId,
        });
        dispatch({
            meta: { actionTime: Date.now(), ...analytics, savedSearchId },
            payload: response.payload,
            type: DELETE_SAVED_SEARCH_SUCCESS,
        });
        dispatch({
            meta: analytics,
            payload: key,
            type: DELETE_SAVE_SEARCH_SUCCESS,
        });
        dispatch(fetchSavedSearchesIfNeeded(true));
    } catch (error) {
        dispatch({
            error: true,
            meta: { savedSearchId },
            payload: error,
            type: DELETE_SAVED_SEARCH_FAIL,
        });
    }
};

type SubmitDeleteSavedSearchUrlParams = {
    savedSearchId: number;
    signature: string;
};

// note: shares redux actions with submitDeleteSavedSearch
export const submitDeleteSavedSearchUrl =
    ({ savedSearchId, signature }: SubmitDeleteSavedSearchUrlParams) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const deployment = getDeployment(state);
        const analytics = getUnfollowSearchAnalytics();
        const key = getSavedSearchKeyById(state, savedSearchId);

        try {
            dispatch({
                payload: savedSearchId,
                type: DELETE_SAVED_SEARCH_REQUEST,
            });
            dispatch({
                payload: key,
                type: DELETE_SAVE_SEARCH_REQUEST,
            });
            const response = await deleteSavedSearchUrl({
                deployment,
                savedSearchId,
                signature,
            });
            if (!response.payload) {
                throw Error('save search not found or deleted for signature');
            }
            dispatch({
                meta: {
                    actionTime: Date.now(),
                    ...analytics,
                    key,
                    savedSearchId,
                },
                payload: savedSearchId,
                type: DELETE_SAVED_SEARCH_SUCCESS,
            });
            dispatch({
                meta: analytics,
                payload: key,
                type: DELETE_SAVE_SEARCH_SUCCESS,
            });
            dispatch(fetchSavedSearchesIfNeeded(true));
        } catch (error) {
            dispatch({
                error: true,
                meta: { savedSearchId },
                payload: error,
                type: DELETE_SAVED_SEARCH_FAIL,
            });
            dispatch({
                error: true,
                meta: { savedSearchId },
                payload: error,
                type: DELETE_SAVE_SEARCH_FAIL,
            });
        }
    };

export const toggleSavedSearchEmail =
    (savedSearchId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const search = getSavedSearchById(state, savedSearchId);
        if (search.sendAlert) {
            dispatch({
                payload: savedSearchId,
                type: UNSUBSCRIBE_SAVED_SEARCH_REQUEST,
            });
            try {
                const response = await postUnsubscribeFromSavedSearch({
                    authToken,
                    deployment,
                    savedSearchId,
                });
                dispatch({
                    meta: { savedSearchId },
                    payload: response.payload,
                    type: UNSUBSCRIBE_SAVED_SEARCH_SUCCESS,
                });
            } catch (error) {
                dispatch({
                    error: true,
                    meta: { savedSearchId },
                    payload: error,
                    type: UNSUBSCRIBE_SAVED_SEARCH_FAIL,
                });
            }
        } else {
            dispatch({
                payload: savedSearchId,
                type: SUBSCRIBE_SAVED_SEARCH_REQUEST,
            });
            try {
                const response = await postSubscribeToSavedSearch({
                    authToken,
                    deployment,
                    savedSearchId,
                });
                dispatch({
                    meta: { savedSearchId },
                    payload: response.payload,
                    type: SUBSCRIBE_SAVED_SEARCH_SUCCESS,
                });
            } catch (error) {
                dispatch({
                    error: true,
                    meta: { savedSearchId },
                    payload: error,
                    type: SUBSCRIBE_SAVED_SEARCH_FAIL,
                });
            }
        }
    };

export const unsubscribeAllSavedSearchEmail = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);
    dispatch({
        type: UNSUBSCRIBE_ALL_SAVED_SEARCH_REQUEST,
    });
    try {
        const response = await postUnsubscribeAllFromSavedSearch({
            authToken,
            deployment,
        });
        dispatch({
            payload: response.payload,
            type: UNSUBSCRIBE_ALL_SAVED_SEARCH_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: UNSUBSCRIBE_ALL_SAVED_SEARCH_FAIL,
        });
    }
};

export const subscribeAllSavedSearchEmail = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);
    dispatch({
        type: SUBSCRIBE_ALL_SAVED_SEARCH_REQUEST,
    });
    try {
        const response = await postSubscribeAllFromSavedSearch({
            authToken,
            deployment,
        });
        dispatch({
            payload: response.payload,
            type: SUBSCRIBE_ALL_SAVED_SEARCH_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: SUBSCRIBE_ALL_SAVED_SEARCH_FAIL,
        });
    }
};

const shouldFetchSavedSearchItems = (state) => {
    if (isSavedSearchItemsLoading(state)) {
        return false;
    }
    const loaded = getLoadTimeForSavedSearchItems(state);
    const time = Date.now();
    const diff = time - loaded;
    return !loaded || diff > REDUX_STORE_TIME;
};

/* ACTION CREATORS */
export const fetchSavedSearchItemsIfNeeded =
    (force?: boolean) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const authToken = getAuthToken(state);
        if ((force || shouldFetchSavedSearchItems(state)) && authToken) {
            const deployment = getDeployment(state);
            const filters = getSavedSearchesRecentFilters(state);
            try {
                dispatch({
                    type: LOAD_SAVED_SEARCH_ITEMS_REQUEST,
                });
                const response = await fetchSavedSearchItems({
                    authToken,
                    deployment,
                    filter: filters.keyword,
                    page: filters.page,
                    pageSize: filters.pageSize,
                    sort: filters.sort,
                });
                return dispatch({
                    meta: { actionTime: Date.now() },
                    payload: response.payload,
                    type: LOAD_SAVED_SEARCH_ITEMS_SUCCESS,
                });
            } catch (error) {
                return dispatch({
                    error: true,
                    payload: error,
                    type: LOAD_SAVED_SEARCH_ITEMS_FAIL,
                });
            }
        }
        return Promise.resolve();
    };

export const onSavedSearchRecentFilterChange = (filters: PaginationFilter) => async (dispatch: AppDispatch) =>
    dispatch({
        payload: filters,
        type: SAVED_SEARCH_ITEMS_FILTER_CHANGE,
    });

export const onSavedSearchFilterChange = (filters: PaginationFilter) => async (dispatch: AppDispatch) =>
    dispatch({
        payload: filters,
        type: SAVED_SEARCH_FILTER_CHANGE,
    });
