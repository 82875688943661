// I have no idea what the entire list of these are.
// The front-end usually iterates over the lists,
// and these are only manually set in tests,
// which is why I'm typing them here, but...
export type ExcludedFacets = {
    auctionHouse: string[];
};

export type SearchExclusionSlice = {
    excludedFacets: ExcludedFacets;
    loaded: number;
    loading: boolean;
};

export const defaultSearchExclusionSlice: SearchExclusionSlice = {
    excludedFacets: {
        auctionHouse: [],
    },
    loaded: 0,
    loading: false,
};
