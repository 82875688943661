import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getClientIpAddress } from './browser';
import { getDeployment } from './config';
import { getIsBot } from './analytics';
import { getUserExcludedHouses } from './search/exclusions/searchExclusions.selectors';
import {
    LOAD_SIMILAR_ITEMS_FAIL,
    LOAD_SIMILAR_ITEMS_REQUEST,
    LOAD_SIMILAR_ITEMS_SUCCESS,
    LOAD_SIMILAR_ITEMS_SUCCESS_ACTION,
} from './actions';
import { SimilarItemInfo as SimilarItemInfoType } from '@/types/SimilarItemInfo';
import api from '@/redux/api/similarItems';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
// import { transformSimilarItemsFrom } from '@/utils/similarItems';
import union from 'lodash/union';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type SimilarItemsSlice = {
    byId: any;
    currentPage: number;
    loaded: any;
    loading: any[];
    moreResultsAvailable: boolean;
    totalPages: number;
};

export const defaultSimilarItemsSlice: SimilarItemsSlice = {
    byId: {},
    currentPage: 1,
    loaded: {},
    loading: [],
    moreResultsAvailable: true,
    totalPages: 0,
};

export default function reducer(
    state: SimilarItemsSlice = defaultSimilarItemsSlice,
    action: any = {}
): SimilarItemsSlice {
    switch (action.type) {
        case LOAD_SIMILAR_ITEMS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.itemId]),
            };
        case LOAD_SIMILAR_ITEMS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_SIMILAR_ITEMS_SUCCESS:
            const existing = cloneDeep(state.byId);

            if (!Boolean(existing[action.meta.itemId])) {
                existing[action.meta.itemId] = [];
            }

            action.payload.forEach((i) => existing[action.meta.itemId].push(i.itemId));
            const moreResultsAvailable = state.totalPages ? state.currentPage < state.totalPages : true;
            return {
                ...state,
                byId: existing,
                currentPage: action.meta.currentPage,
                loaded: {
                    ...state.loaded,
                    [action.meta.itemId]: action.meta.actionTime,
                },
                loading: difference(state.loading, [action.meta.itemId]),
                moreResultsAvailable: moreResultsAvailable,
                totalPages: action.meta.totalPages,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const similarItemsSlice = createSelector(stateSelector, (state) => state.similarItems);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(similarItemsSlice, (state) => state.byId);

const loadedSelector = createSelector(similarItemsSlice, (state) => state.loaded);

const loadingSelector = createSelector(similarItemsSlice, (state) => state.loading);

export const getSimilarItemIds = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || []);

const getLoadTime = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

const getCurrentPage = createSelector(similarItemsSlice, (state) => state.currentPage);

const getTotalPages = createSelector(similarItemsSlice, (state) => state.totalPages);

export const getMoreResultsAvailable = createSelector(similarItemsSlice, (state) => state.moreResultsAvailable);

const shouldFetch = (state, itemId) => {
    if (!itemId) {
        return false;
    }
    const item = getSimilarItemIds(state, itemId);
    if (item.length) {
        const loaded = getLoadTime(state, itemId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isLoading(state, itemId);
    return !loading;
};

/* ACTION CREATORS */
export const fetchSimilarItemsIfNeeded =
    (similarItemInfo: SimilarItemInfoType) => async (dispatch: AppDispatch, getState: AppGetState) => {
        if (similarItemInfo?.itemId && shouldFetch(getState(), similarItemInfo.itemId)) {
            const state = getState();
            const currentPage = getCurrentPage(state);
            return dispatch(loadSimilarItems(similarItemInfo, currentPage));
        }
        return Promise.resolve();
    };

const loadSimilarItems =
    (similarItemInfo: SimilarItemInfoType, page: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const ipAddress = getClientIpAddress(state);
            const isBot = getIsBot(state);
            const searchExclusions = getUserExcludedHouses(state);

            dispatch({
                payload: similarItemInfo.itemId,
                type: LOAD_SIMILAR_ITEMS_REQUEST,
            });

            const { payload } = await api.fetchSimilarItems({
                authToken,
                deployment,
                ipAddress,
                isBot,
                page,
                searchExclusions,
                similarItemInfo,
            });

            return dispatch({
                meta: {
                    actionTime: Date.now(),
                    currentPage: page,
                    itemId: similarItemInfo.itemId,
                    totalPages: payload.pageCount,
                },
                payload: payload.items,
                type: LOAD_SIMILAR_ITEMS_SUCCESS,
            } as LOAD_SIMILAR_ITEMS_SUCCESS_ACTION);
        } catch (error) {
            return dispatch({
                error: true,
                meta: { itemId: similarItemInfo.itemId },
                payload: error,
                type: LOAD_SIMILAR_ITEMS_FAIL,
            });
        }
    };

export const fetchMoreSimilarItems =
    (similarItemInfo: SimilarItemInfoType) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const currentPage = getCurrentPage(state);
        const totalPages = getTotalPages(state);

        if (currentPage < totalPages) {
            return dispatch(loadSimilarItems(similarItemInfo, currentPage + 1));
        }
        return Promise.resolve();
    };
