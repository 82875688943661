import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getCustomShippingRateRequest, getItemShippingEstimate } from '@/redux/modules/shipping/shipping.api';
import { getDeployment } from '@/redux/modules/config';
import {
    HiddenQuoteReason,
    HiddenQuoteRequestParams,
    ShippingEstimateParams,
} from '@/redux/modules/shipping/shipping.types';
import { ShippingEstimate } from '@/types/ShippingEstimate';
import { shouldFetchItemShippingEstimate } from '@/redux/modules/shipping/shipping.selectors';

/**
 * Fetches a new shipping estimate if one doesn't already exist for the given item or a past estimate is outdated
 * @param countryCode The country code for the user's shipping address
 * @param itemId Which item's shipping info the user wants
 * @param postalCode The user's ZIP/postal code for the estimate
 */
export const fetchItemShippingEstimate = createAsyncThunk<
    ShippingEstimate,
    ShippingEstimateParams,
    AsyncThunkConfigWithRejectValue<number> // passes back HTTP status code
>(
    'la/ui/shipping/fetchItemShippingEstimate',
    async ({ countryCode, itemId, postalCode }, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const { payload } = await getItemShippingEstimate({
                authToken,
                countryCode,
                deployment,
                itemId,
                postalCode,
            });
            return payload;
        } catch (error) {
            if (error.message === HiddenQuoteReason.BadZipCode) {
                return rejectWithValue(404);
            }
            return rejectWithValue(Number(error.error?.code));
        }
    }
);

/**
 * If the redux store needs the data, fetches a new shipping estimate if one doesn't already exist for the given item or a past estimate is outdated
 * @param params
 * @param params.countryCode The country code for the user's shipping address
 * @param params.itemId Which item's shipping info the user wants
 * @param params.postalCode The user's ZIP/postal code for the estimate
 */
export const fetchItemShippingEstimateIfNeeded = createAsyncThunk<void, ShippingEstimateParams>(
    'la/ui/shipping/fetchItemShippingEstimateIfNeeded',
    async (params, { dispatch, getState }) => {
        if (shouldFetchItemShippingEstimate(getState(), params.itemId)) {
            await dispatch(fetchItemShippingEstimate(params));
        }
    }
);

/**
 * Prepares to send the hidden shipping rate to the user's provided email address
 * @param countryCode The country code for the user's shipping address
 * @param emailAddress The user's email address they want the shipping info sent to
 * @param itemId Which item's shipping info the user wants
 * @param postalCode The user's ZIP/postal code for the estimate
 */
export const sendCustomShippingRateRequest = createAsyncThunk<void, HiddenQuoteRequestParams>(
    'la/ui/shipping/sendCustomShippingRateRequest',
    async ({ countryCode, emailAddress, itemId, postalCode }, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        await getCustomShippingRateRequest({
            countryCode,
            deployment,
            email: emailAddress,
            itemId,
            postalCode,
        });
    }
);
