import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { LOAD_SELLERS_FAIL, LOAD_SELLERS_REQUEST, LOAD_SELLERS_SUCCESS } from './actions';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type SellerCatalogCountsSlice = {
    byId: any;
    loaded: any;
    loading: any[];
};

export const defaultSellerCatalogCountsSlice: SellerCatalogCountsSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(
    state: SellerCatalogCountsSlice = defaultSellerCatalogCountsSlice,
    action: any = {}
): SellerCatalogCountsSlice {
    let existing;
    let loaded;
    let loading;
    let time;

    switch (action.type) {
        case LOAD_SELLERS_FAIL:
            return {
                ...state,
                loading: difference(state.loading, action.meta.sellerIds),
            };
        case LOAD_SELLERS_REQUEST:
            return {
                ...state,
                loading: union(state.loading, action.payload),
            };
        case LOAD_SELLERS_SUCCESS:
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded };
            loading = cloneDeep(state.loading);
            time = action.meta.actionTime;

            if (action.payload.sellerCatalogCounts) {
                action.payload.sellerCatalogCounts.forEach((item) => {
                    existing[item.sellerId] = {
                        ...item,
                        upcomingCount: item.liveCount + item.onlineCount,
                    };
                    loaded[item.sellerId] = time;
                    loading = difference(loading, [item.sellerId]);
                });
            }

            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const sellerCatalogCountsSlice = createSelector(stateSelector, (state) => state.sellerCatalogCounts);

const idSelector = (_: GlobalState, id: number) => id;
const idsSelector = (_: GlobalState, ids: number[]) => ids;

const byIdSelector = createSelector(sellerCatalogCountsSlice, (state) => state.byId);

const loadedSelector = createSelector(sellerCatalogCountsSlice, (state) => state.loaded);

const loadingSelector = createSelector(sellerCatalogCountsSlice, (state) => state.loading);

// takes in a (state, id) and returns data
export const getSellerCatalogCounts = createSelector([byIdSelector, idSelector], (byId, id) => {
    return (
        byId[id] || {
            doneCount: 0,
            liveCount: 0,
            onlineCount: 0,
            upcomingCount: 0,
        }
    );
});

export const getSellersCatalogCounts = createSelector([byIdSelector, idsSelector], (byId, ids) =>
    ids.map(
        (id) =>
            byId[id] || {
                doneCount: 0,
                liveCount: 0,
                onlineCount: 0,
                sellerId: id,
                upcomingCount: 0,
            }
    )
);

export const getSellerUpcomingCatalogCount = createSelector(
    getSellerCatalogCounts,
    ({ upcomingCount }) => upcomingCount || 0
);

export const getSellerCatalogDoneCount = createSelector(getSellerCatalogCounts, ({ doneCount }) => doneCount || 0);

// takes in a (state, id) and returns load time
const getLoadTimeForSellerCatalogCounts = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

// takes in a (state, id) and returns if data is loading
const isSellerCatalogCountsLoading = createSelector(
    [loadingSelector, idSelector],
    (loading, id) => loading.indexOf(id) !== -1
);

export const shouldFetchSellerCatalogCounts = (state: GlobalState, sellerId: number) => {
    if (!sellerId) {
        return false;
    }
    const item = getSellerCatalogCounts(state, sellerId);
    if (item) {
        const loaded = getLoadTimeForSellerCatalogCounts(state, sellerId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isSellerCatalogCountsLoading(state, sellerId);
    return !loading;
};

export const whichSellerCatalogCountsNeeded = (state: GlobalState, sellerIds: number[]) => {
    return sellerIds.filter((sellerId) => shouldFetchSellerCatalogCounts(state, sellerId));
};
