import { ApiHosts, handleResponseWithNon200Errors, makeGet, makePost } from '../../api/helpers';
import { FinalShippingQuoteType } from '@/redux/modules/shippingQuote/shippingQuote.types';

/**
 * fetches the final invoice shipping quote via Post to Shipping API
 * @function postFetchFinalShippingQuote
 * @category Shipping API
 * @param {FinalShippingQuoteType}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/shipping/quote/invoice/${invoiceId}/purchase
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/2sprwrrwd6/resources/k9qsx9/methods/POST
 * @see https://github.com/LIVEauctioneers/shipping-api/tree/master/functions/PurchaseQuote-shipping-api
 */
export const postFetchFinalShippingQuote = ({
    authToken,
    deployment,
    invoiceId,
    quoteId,
    quoteNumber,
}: FinalShippingQuoteType) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `quote/invoice/${invoiceId}/purchase`,
            authToken,
            deployment,
            path: ApiHosts.Shipping,
        });
        request.send({ quoteId, quoteNumber });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

type PurchasedShippingQuoteType = {
    authToken: string;
    deployment: string;
    invoiceId: number;
};

/**
 * fetches the purchase shipping quote via Post to Shipping API
 * @function getPurchasedShippingQuote
 * @category Shipping API
 * @param {PurchasedShippingQuoteType}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/shipping/quote/invoice/${invoiceId}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/2sprwrrwd6/resources/a8l892/methods/GET
 * @see https://github.com/LIVEauctioneers/shipping-api/tree/master/functions/GetInvoiceShippingInfo-shipping-api
 */
export const getPurchasedShippingQuote = ({ authToken, deployment, invoiceId }: PurchasedShippingQuoteType) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `quote/invoice/${invoiceId}`,
            authToken,
            deployment,
            path: ApiHosts.Shipping,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
