import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { getPurchasedShippingQuote, postFetchFinalShippingQuote } from './shippingQuote.api';
import { getShippingQuote } from './shippingQuote.selectors';
import { ShippingQuoteInfoType } from './shippingQuote.types';

/**
 * Purchases a shipping quote
 */
export const purchaseShippingQuote = createAsyncThunk<any, ShippingQuoteInfoType>(
    'la/ui/shippingQuote/purchaseShippingQuote',
    async ({ catalogId, invoiceId }, { getState }) => {
        const state = getState();
        const { quoteId, quoteNumber } = getShippingQuote(state, catalogId);
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { payload } = await postFetchFinalShippingQuote({
            authToken,
            deployment,
            invoiceId,
            quoteId,
            quoteNumber,
        });
        return payload;
    }
);

export const fetchPurchasedShippingQuote = createAsyncThunk<any, ShippingQuoteInfoType>(
    'la/ui/shippingQuote/fetchPurchasedShippingQuote',
    async ({ invoiceId }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { payload } = await getPurchasedShippingQuote({
            authToken,
            deployment,
            invoiceId,
        });
        return payload;
    }
);
