import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { fetchSuggestedCategoriesByCategoryID } from '@/redux/api/suggestedCategoryTiles';
import { getDeployment } from './config';
import { SuggestedCategory } from '@/types/SuggestedCategory';

/* Action Types */
export const LOAD_SUGGESTED_CATEGORY_TILES_FAIL = 'la/domain/items/LOAD_SUGGESTED_CATEGORY_TILES_FAIL';
export const LOAD_SUGGESTED_CATEGORY_TILES_SUCCESS = 'la/domain/items/LOAD_SUGGESTED_CATEGORY_TILES_SUCCESS';

/* reducer */
export type SuggestedCategoryTilesSlice = {
    suggestedCategoryTiles: SuggestedCategory[];
};

export const defaultSuggestedCategoryTiles: SuggestedCategoryTilesSlice = {
    suggestedCategoryTiles: [],
};

export default function reducer(state = defaultSuggestedCategoryTiles, action: any = {}): SuggestedCategoryTilesSlice {
    switch (action.type) {
        case LOAD_SUGGESTED_CATEGORY_TILES_FAIL:
            return {
                ...state,
                suggestedCategoryTiles: [],
            };
        case LOAD_SUGGESTED_CATEGORY_TILES_SUCCESS:
            return {
                ...state,
                suggestedCategoryTiles: action.payload,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const suggestedCategoryTilesSlice = createSelector(stateSelector, (state) => state.suggestedCategoryTiles);

export const getSuggestedCategoryTiles = createSelector(suggestedCategoryTilesSlice, (state) => {
    return state.suggestedCategoryTiles.filter(
        (categoryTile) => Boolean(categoryTile.imageUrl) || Boolean(categoryTile.defaultImageUrl) || []
    );
});

/* ACTION CREATORS */
export const fetchSuggestedCategories =
    (categoryId: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const deployment = getDeployment(state);
        if (!categoryId) {
            return;
        }
        try {
            const response = await fetchSuggestedCategoriesByCategoryID({
                categoryId,
                deployment,
            });
            const suggestedCatsInPositionOrder = response.payload.sort((a, b) => {
                return a.position - b.position;
            });
            dispatch({
                payload: suggestedCatsInPositionOrder,
                type: LOAD_SUGGESTED_CATEGORY_TILES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { categoryId },
                payload: error,
                type: LOAD_SUGGESTED_CATEGORY_TILES_FAIL,
            });
        }
    };
