import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { DeliveryType, fetchSmsAlerts, toggleSmsAlerts } from '@/redux/api/alerts';
import { getAuthToken, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import {
    LOAD_SMS_ALERTS_FAIL,
    LOAD_SMS_ALERTS_REQUEST,
    LOAD_SMS_ALERTS_SUCCESS,
    TOGGLE_SMS_ALERTS_SUCCESS,
} from './actions';
import { UNSUBSCRIBE_SUCCESS } from './emailList';

// reducer
type SmsAlertsSlice = {
    subscribed: any[];
    subscriptions: any[];
};

export const defaultSmsAlertsSlice: SmsAlertsSlice = {
    subscribed: [],
    subscriptions: [],
};

export default function reducer(state: SmsAlertsSlice = defaultSmsAlertsSlice, action: any = {}): SmsAlertsSlice {
    switch (action.type) {
        case LOAD_SMS_ALERTS_SUCCESS:
            return {
                ...state,
                subscriptions: action.payload,
            };
        case TOGGLE_SMS_ALERTS_SUCCESS:
            return {
                ...state,
                subscribed: action.payload.subscribed,
            };
        case UNSUBSCRIBE_SUCCESS:
            if (action.meta.code !== 'bids_ending_soon') {
                return state;
            }
            const subscriptions = state.subscriptions.map((sub) => {
                if (sub.name === 'Bids Ending Soon') {
                    sub.subscribed = false;
                }
                return sub;
            });
            return {
                ...state,
                subscriptions,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const smsAlertsSlice = createSelector(stateSelector, (state) => state.smsAlerts);

// @ts-ignore - ignoring "state"
const idSelector = (_: GlobalState, id: number) => id;

export const getSubscriptions = createSelector(smsAlertsSlice, (state) => state.subscriptions || []);
export const getSubscriptionIdList = createSelector(getSubscriptions, (subscriptions): number[] => {
    const subs = subscriptions.map((subscription) => subscription.alertTypeId) || [];

    // @ts-ignore
    return [...new Set(subs)];
});
export const getSubscriptionsById = createSelector([getSubscriptions, idSelector], (subscriptions, id) => {
    return subscriptions.filter((subscription) => subscription.alertTypeId === id);
});

export const getSubscribedToSMSAlerts = createSelector(smsAlertsSlice, (state) =>
    Boolean(state.subscriptions.find((alert) => Boolean(alert.subscribed) && alert.deliveryType === 's'))
);

export const getSubscribedToBrowserAlerts = createSelector(smsAlertsSlice, (state) =>
    Boolean(state.subscriptions.find((alert) => Boolean(alert.subscribed) && alert.deliveryType === 'b'))
);

const shouldFetchSmsAlerts = createSelector(smsAlertsSlice, () => true);

/* ACTION CREATORS */
const loadSmsAlerts = (token?: string) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        const loggedIn = isUserLoggedIn(state);

        if (!loggedIn) {
            return Promise.resolve();
        }

        dispatch({
            type: LOAD_SMS_ALERTS_REQUEST,
        });
        const response = await fetchSmsAlerts({
            authToken: token || authToken,
            deployment,
        });
        return dispatch({
            meta: { actionTime: Date.now() },
            payload: response.payload,
            type: LOAD_SMS_ALERTS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_SMS_ALERTS_FAIL,
        });
    }
};

export const toggleSmsAlert =
    (alertTypeId: number, deliveryType: DeliveryType, subscribed: boolean) => async (dispatch, getState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const authToken = getAuthToken(state);

            dispatch({
                type: LOAD_SMS_ALERTS_REQUEST,
            });
            const response = await toggleSmsAlerts({
                alertTypeId,
                authToken,
                deliveryType,
                deployment,
                subscribed,
            });
            return dispatch({
                meta: { actionTime: Date.now() },
                payload: response.payload,
                type: LOAD_SMS_ALERTS_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: LOAD_SMS_ALERTS_FAIL,
            });
        }
    };

export const fetchSmsAlertsIfNeeded =
    (authToken?: string, force?: boolean) => async (dispatch: AppDispatch, getState: AppGetState) => {
        if (shouldFetchSmsAlerts(getState()) || force) {
            return dispatch(loadSmsAlerts(authToken));
        }
        return Promise.resolve();
    };
