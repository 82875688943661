import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import {
    postSetSearchFacetOptionExclusion,
    PostSetSearchFacetOptionExclusionResponseData,
} from '@/redux/modules/search/exclusions/searchExclusions.api';

type SetSearchExclusionInfo = {
    exclude: boolean;
    facetId: string;
    optionId: string | number;
};

export const setSearchExclusion = createAsyncThunk<
    PostSetSearchFacetOptionExclusionResponseData,
    SetSearchExclusionInfo,
    AsyncThunkConfigWithRejectValue<string>
>(
    'la/ui/searchExclusions/setSearchExclusion',
    async ({ exclude, facetId, optionId }, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        try {
            const { data } = await postSetSearchFacetOptionExclusion({
                authToken,
                deployment,
                exclude,
                facetId,
                optionId,
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
