import { createSelector } from '@reduxjs/toolkit';
import { defaultSearchExclusionSlice } from '@/redux/modules/search/exclusions/searchExclusions.types';
import { GlobalState } from '@/redux/store';
import cloneDeep from 'lodash/cloneDeep';

const stateSelector = (state: GlobalState) => state;
const searchExclusionsSlice = createSelector(stateSelector, (state) => state.searchExclusions);

const idSelector = (_: GlobalState, id: number) => id;

export const getUserExcludedFacets = createSelector(
    searchExclusionsSlice,
    (state) => state.excludedFacets || cloneDeep(defaultSearchExclusionSlice.excludedFacets)
);

export const getUserExcludedHouses = createSelector(getUserExcludedFacets, (facets) => {
    const searchExclusions: string[] = facets?.auctionHouse || [];
    return searchExclusions.map((exclusion) => Number(exclusion));
});

export const isUserExcludedHouse = createSelector([getUserExcludedHouses, idSelector], (excludedHouses, houseId) =>
    excludedHouses.includes(houseId)
);
