import { ApiHosts, handleResponseWithNon200Errors, makeGet } from '@/redux/api/helpers';
import { getDeployment } from '@/redux/modules/config';
import { HiddenQuoteReason } from '@/redux/modules/shipping/shipping.types';
import { ShippingEstimate } from '@/types/ShippingEstimate';

/**
 * @category Shipping API
 * @see getItemShippingEstimate
 */
type FetchItemShippingEstimate = {
    authToken: string;
    countryCode: string;
    deployment: string;
    itemId: number;
    postalCode: string;
};

export type ItemShippingEstimateResponse = {
    error: boolean;
    payload: ShippingEstimate;
};

/**
 * Fetches a shipping estimate via Get from Shipping API
 * @function fetchItemShippingEstimate
 * @category Shipping API
 * @param {FetchItemShippingEstimate}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/shipping/estimate
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/2sprwrrwd6/resources/tztdqt/methods/GET
 * @see https://github.com/LIVEauctioneers/shipping-api/tree/master/functions/GetEstimate-shipping-api
 */
export const getItemShippingEstimate = ({
    authToken,
    countryCode,
    deployment,
    itemId,
    postalCode,
}: FetchItemShippingEstimate) =>
    new Promise<ItemShippingEstimateResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'estimate',
            authToken,
            deployment,
            path: ApiHosts.Shipping,
            queryParams: { countryCode, itemId, postalCode },
        });
        request.end((err, response) => {
            // TODO: Once we have a way to distinguish bad zip from the API response, we should do so.
            if (response?.statusCode === 204) {
                return reject(new Error(HiddenQuoteReason.BadZipCode));
            }
            return handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            });
        });
    });

/**
 * @category Shipping API
 * @see getCustomShippingRateRequest
 */
type GetCustomShippingRateRequestParams = {
    countryCode: string;
    deployment: ReturnType<typeof getDeployment>;
    email: string;
    itemId: number;
    postalCode: string;
};

/**
 * Makes a request to email the user with the estimated shipping quote of the item.
 *
 * @param countryCode Country code for user
 * @param deployment Which environment is the user on
 * @param email User's email address
 * @param itemId Which item is the shipping rate request for
 * @param postalCode Where is the user's postal code?
 */
export const getCustomShippingRateRequest = ({
    countryCode,
    deployment,
    email,
    itemId,
    postalCode,
}: GetCustomShippingRateRequestParams) =>
    new Promise<void>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'hidden-estimate',
            deployment,
            path: ApiHosts.Shipping,
            queryParams: {
                countryCode,
                email,
                itemId,
                postalCode,
            },
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
