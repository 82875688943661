import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import api from '@/redux/api/review';

/* Action Types */
export const SUBMIT_REVIEW_FAIL = 'la/ui/submitReview/FAIL';
export const SUBMIT_REVIEW_REQUEST = 'la/ui/submitReview/REQUEST';
export const SUBMIT_REVIEW_SUCCESS = 'la/ui/submitReview/SUCCESS';
export const FETCH_REVIEW_FAIL = 'la/ui/fetchReview/FAIL';
export const FETCH_REVIEW_REQUEST = 'la/ui/fetchReview/REQUEST';
export const FETCH_REVIEW_SUCCESS = 'la/ui/fetchReview/SUCCESS';
export const FETCH_COMMENTS_FAIL = 'la/ui/fetchReviewComments/FAIL';
export const FETCH_COMMENTS_REQUEST = 'la/ui/fetchReviewComments/REQUEST';
export const FETCH_COMMENTS_SUCCESS = 'la/ui/fetchReviewComments/SUCCESS';
export const RESET_REVIEW_PAGE = 'RESET_REVIEW_PAGE';

type ReviewType = {
    catID: number;
    lots: any[];
    preventReview: boolean;
    review: any;
    reviewed: boolean;
};

// reducer
export type ReviewSlice = {
    error: boolean;
    reviewComments: any[];
    reviewData: ReviewType[] | ReviewType;
    submitted: boolean;
    success: boolean;
};

export const defaultReviewSlice: ReviewSlice = {
    error: false,
    reviewComments: [],
    reviewData: [
        {
            catID: 0,
            lots: [],
            preventReview: false,
            review: null,
            reviewed: false,
        },
    ],
    submitted: false,
    success: false,
};

export default function reducer(state: ReviewSlice = defaultReviewSlice, action: any = {}): ReviewSlice {
    switch (action.type) {
        case SUBMIT_REVIEW_REQUEST:
            return {
                ...state,
                submitted: true,
                success: false,
            };
        case SUBMIT_REVIEW_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        case SUBMIT_REVIEW_FAIL:
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
            };
        case FETCH_REVIEW_SUCCESS:
            return {
                ...state,
                reviewData: action.payload || {
                    catID: 0,
                    lots: [],
                    preventReview: true,
                    reviewed: false,
                },
            };
        case FETCH_COMMENTS_SUCCESS:
            return {
                ...state,
                reviewComments: action.payload,
            };
        case RESET_REVIEW_PAGE:
            return {
                ...defaultReviewSlice,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const reviewSlice = createSelector(stateSelector, (state) => state.review);

export const getReviewSubmitted = createSelector(reviewSlice, (state) => state.submitted);

export const getReviewSubmitSuccess = createSelector(reviewSlice, (state) => state.success);

export const getReviewError = createSelector(reviewSlice, (state) => state.error);

export const getReviewData = createSelector(reviewSlice, (state) =>
    state.reviewData && Array.isArray(state.reviewData) && state.reviewData.length === 1
        ? state.reviewData[0]
        : state.reviewData
);

// @ts-ignore
export const getReviewComments = createSelector(reviewSlice, (state) => state.reviewComments);

/* ACTION CREATORS */
const submitReviewFail = (error) => ({
    error: true,
    payload: error,
    type: SUBMIT_REVIEW_FAIL,
});

const submitReviewRequest = (review) => ({
    payload: {
        review,
    },
    type: SUBMIT_REVIEW_REQUEST,
});

const submitReviewSuccess = (review) => ({
    meta: {
        review,
    },
    payload: review,
    type: SUBMIT_REVIEW_SUCCESS,
});

const fetchReviewDataFail = (error) => ({
    error: true,
    payload: error,
    type: FETCH_REVIEW_FAIL,
});

const fetchReviewDataSuccess = (catIDs) => ({
    meta: {
        catIDs,
    },
    payload: catIDs,
    type: FETCH_REVIEW_SUCCESS,
});

const fetchReviewCommentsFail = (error) => ({
    error: true,
    payload: error,
    type: FETCH_COMMENTS_FAIL,
});

const fetchReviewCommentsRequest = (reviewId) => ({
    payload: {
        reviewId,
    },
    type: FETCH_COMMENTS_REQUEST,
});

const fetchReviewCommentsSuccess = (payload) => ({
    payload,
    type: FETCH_COMMENTS_SUCCESS,
});

export const resetReviewPage = () => ({
    type: RESET_REVIEW_PAGE,
});

export const submitReview = (review: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch(submitReviewRequest(review));
        await api.submitReview({
            authToken,
            deployment,
            review,
        });
        return dispatch(submitReviewSuccess(review));
    } catch (error) {
        dispatch(submitReviewFail(error));
    }
};

export const fetchReviewData = (catalogIds: number[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        dispatch({ type: FETCH_REVIEW_REQUEST });

        const response = await api.fetchReviewData({
            authToken,
            bidderId,
            catalogIds,
            deployment,
        });
        return dispatch(fetchReviewDataSuccess(response.payload));
    } catch (error) {
        dispatch(fetchReviewDataFail(error));
    }
};

export const fetchReviewComments = (reviewId: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch(fetchReviewCommentsRequest(reviewId));
        const response = await api.fetchReviewComments({
            authToken,
            deployment,
            reviewId,
        });
        return dispatch(fetchReviewCommentsSuccess(response.payload));
    } catch (error) {
        dispatch(fetchReviewCommentsFail(error));
    }
};
