import { addOrsBetweenKeywords, sanitizeSearchKey } from '@/utils/strings';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { cleanKeywordSearchString } from '@/utils/cleanSearchTerms';
import { createSelector } from '@reduxjs/toolkit';
import {
    DELETE_SAVE_SEARCH_FAIL,
    DELETE_SAVE_SEARCH_REQUEST,
    DELETE_SAVE_SEARCH_SUCCESS,
    LOAD_SAVED_SEARCHES_SUCCESS,
    SAVE_RECENT_SEARCH_FAIL,
    SAVE_RECENT_SEARCH_REQUEST,
    SAVE_RECENT_SEARCH_SUCCESS,
    SAVE_SEARCH_FAIL,
    SAVE_SEARCH_REQUEST,
    SAVE_SEARCH_SUCCESS,
    SET_SIGNUP_SAVE_SEARCH_PARAMS,
} from './actions';
import { fetchSavedSearchesIfNeeded } from './savedSearch';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getAuthToken, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { getFollowSearchAnalytics, getUnfollowSearchAnalytics } from './analytics';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { LOG_OUT_BIDDER } from '@/redux/modules/account/logout/logout.actions';
import { postDeleteSavedSearch, postRecentSearch, postSaveSearch } from '@/redux/api/saveSearch';
import { SearchParams } from '@/types/SaveSearch';
import { SearchQueryParams } from '@/types/SearchQueryParams';
import { toFacetUrlParams } from '@/utils/savedSearchFacets';
import { trackSearchFollowedAnalytics, trackSearchUnfollowedAnalytics } from '@/utils/analytics';
import cloneDeep from 'lodash/cloneDeep';

// reducer
export const defaultSaveSearchSlice: SaveSearchSlice = {
    byParams: {},
    error: false,
    newSignupSearchParams: {},
    //TODO remove these and update places using them to use byParams
    submitted: false,
    success: false,
};

type SavedSearch = {
    deleted?: boolean;
    key: string;
    newSignupSearchParams: SearchParams;
    savedSearchId?: number;
    submitted: boolean;
    success: boolean;
};

type ByParamsType = {
    [key: string]: SavedSearch;
};

export type SaveSearchSlice = {
    byParams: ByParamsType;
    error: boolean;
    newSignupSearchParams: {};
    submitted: boolean;
    success: boolean;
};

export default function reducer(state = defaultSaveSearchSlice, action: any = {}): SaveSearchSlice {
    const existing = cloneDeep(state.byParams);
    switch (action.type) {
        case SAVE_SEARCH_REQUEST:
        case DELETE_SAVE_SEARCH_REQUEST:
            return {
                ...state,
                submitted: true,
                success: false,
            };
        case SAVE_SEARCH_SUCCESS:
            const sanitizedKey = sanitizeSearchKey(String(action.payload.key));
            return {
                ...state,
                byParams: {
                    ...existing,
                    [`${sanitizedKey}`]: {
                        ...existing[`${sanitizedKey}`],
                        deleted: false,
                        key: `${sanitizedKey}`,
                        savedSearchId: action.payload.id,
                    },
                },
                submitted: false,
                success: true,
            };
        case LOAD_SAVED_SEARCHES_SUCCESS:
            let byParams = {};
            action.payload?.forEach((search) => {
                const key = createSaveSearchKeyBySearchParams(toFacetUrlParams(search.facets));
                if (key) {
                    byParams = {
                        ...byParams,
                        [key]: {
                            deleted: false,
                            key,
                            savedSearchId: search.savedSearchId,
                        },
                    };
                }
            });
            return {
                ...state,
                byParams: { ...existing, ...byParams },
                submitted: false,
                success: true,
            };
        case LOG_OUT_BIDDER:
            return { ...defaultSaveSearchSlice };
        case DELETE_SAVE_SEARCH_SUCCESS:
            const deleteKey = action.payload;
            return {
                ...state,
                byParams: {
                    ...existing,
                    [deleteKey]: {
                        ...existing[deleteKey],
                        deleted: true,
                    },
                },
                error: false,
                submitted: false,
                success: true,
            };
        case DELETE_SAVE_SEARCH_FAIL:
        case SAVE_SEARCH_FAIL:
            let newByParams = cloneDeep(existing);
            if (action.meta?.key) {
                newByParams[`${action.meta.key}`] = {
                    ...existing[`${action.meta.key}`],
                };
            }
            return {
                ...state,
                byParams: newByParams,
                error: true,
                submitted: false,
                success: false,
            };
        case SET_SIGNUP_SAVE_SEARCH_PARAMS:
            return {
                ...state,
                newSignupSearchParams: action.payload,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const getSaveSearchState = createSelector(stateSelector, (state) => state.saveSearch);

const idSelector = (_: GlobalState, id: number | string) => id;

const byParamsSelector = createSelector(getSaveSearchState, (state) => state.byParams);

export const getNewSignupSearchParams = createSelector(getSaveSearchState, (state) => state.newSignupSearchParams);
export const getSaveSearchSubmitted = createSelector(getSaveSearchState, (state) => state.submitted);
export const getSaveSearchSuccess = createSelector(getSaveSearchState, (state) => state.success);

/**
 *
 * @param queryParams SearchQueryParams usually sourced from parseQueryParams
 * @param pathname string pathname to parse categories from, if category or priceguide page for additional category_id parsing
 * @param cleanAndFilter boolean to clean and filter the keyword param
 * @returns SearchQueryParams
 */
export const getSaveSearchParams = (
    queryParams: Partial<SearchQueryParams>,
    pathname: string = '',
    cleanAndFilter: boolean = false
): Partial<SearchQueryParams> | null => {
    const MAX_WORDS = 4;
    const newParams = filterSaveSearchParams(queryParams);

    if (newParams?.keyword) {
        newParams.keyword = cleanAndFilter
            ? addOrsBetweenKeywords(cleanKeywordSearchString(newParams.keyword), MAX_WORDS)
            : newParams.keyword;
    } else {
        delete newParams.keyword;
    }

    if ((pathname.includes('/c/') || pathname.includes('/price-guide/')) && !queryParams.campaign_id) {
        delete newParams.keyword;
        let pathCategoryIds: string[] = [];
        const categoryIdMatches = pathname.matchAll(/\/(\d+)/g);
        for (const categoryId of categoryIdMatches) {
            pathCategoryIds.push(categoryId[1]);
        }
        newParams.category_id = [...pathCategoryIds, newParams.category_id].filter((i) => Boolean(i)).join(',');
    }

    if (pathname.includes('/price-result/') && !queryParams.category_id) {
        newParams.keyword = typeof document !== 'undefined' ? document?.title : '';
    }

    return Object.keys(newParams).length ? newParams : null;
};

/**
 * filterSaveSearchParams - filters given queryParams object and returns SearchQueryParams with allowed
 * parameters only for a Save Search
 * @param queryParams Partial<SearchQueryParams>
 * @returns Partial<SearchQueryParams>
 */
const filterSaveSearchParams = (queryParams: Partial<SearchQueryParams>) => {
    const safeSearchFilters = [
        'category_id',
        'country',
        'current_bid',
        'distance',
        'estimated_price',
        'house_id',
        'keyword',
        'location',
        'payment',
        'sale_type',
        'shipping',
        'topRated',
    ];
    let safeSaveSearchParams: Partial<SearchQueryParams> = {};
    Object.keys(queryParams).forEach((queryKey) => {
        if (safeSearchFilters.includes(queryKey)) {
            safeSaveSearchParams[queryKey] = queryParams[queryKey];
        }
    });
    return safeSaveSearchParams;
};

export const createSaveSearchParams = (categoryId: string) => {
    return {
        category_id: categoryId,
    };
};

export const createSaveSearchKey = (categoryId: string) => {
    return createSaveSearchKeyBySearchParams(createSaveSearchParams(categoryId));
};

export const createSaveSearchKeyBySearchParams = (searchParams: SearchParams): string => {
    if (!searchParams) {
        return '';
    }
    const sortedParams = {};
    Object.keys(searchParams)
        .sort()
        .forEach((key: string) => {
            if (key === 'status') {
                return;
            }
            sortedParams[key] =
                (typeof searchParams?.[key] === 'string' ? searchParams[key] : '').replace(/\s+/g, ' ').trim() ||
                sortedParams[key];
        });

    const searchKey = sanitizeSearchKey(JSON.stringify(sortedParams).replace(/}|{|"/g, ''));
    return searchKey;
};

export const getHasSavedSearches = createSelector(byParamsSelector, (byParams) => {
    const savedSearches = Object.keys(byParams).filter((key) => {
        return !byParams[key].deleted;
    });
    return Boolean(savedSearches.length);
});

export const getSavedSearchesCount = createSelector(byParamsSelector, (byParams) => {
    const savedSearches = Object.keys(byParams).filter((key) => !byParams[key].deleted);
    return savedSearches.length;
});

export const getIsSavedSearch = createSelector([byParamsSelector, idSelector], (byParams, saveSearchKey) => {
    const sanitizedSaveSearchKey = sanitizeSearchKey(String(saveSearchKey));
    const savedSearch = Boolean(byParams[sanitizedSaveSearchKey] && !byParams[sanitizedSaveSearchKey].deleted);
    return savedSearch;
});

export const getSavedSearchId = createSelector([byParamsSelector, idSelector], (byParams, saveSearchKey) => {
    const sanitizedSaveSearchKey = sanitizeSearchKey(String(saveSearchKey));
    return byParams[sanitizedSaveSearchKey]?.savedSearchId || 0;
});

export const getSavedSearchKeyById = createSelector([byParamsSelector, idSelector], (byParams, id) => {
    const savedSearchArray = Object.keys(byParams);
    let savedSearchKey = '';
    savedSearchArray.forEach((key) => {
        if (byParams[key].savedSearchId === Number(id)) {
            savedSearchKey = byParams[key].key;
        }
    });

    return savedSearchKey;
});

export type ParamsOrKey = SearchParams | string;

/* ACTION CREATORS */
export const submitSaveSearch =
    (saveSearchParams: ParamsOrKey, sendAlert: boolean) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const analytics = getFollowSearchAnalytics();
            const savedSearchKey =
                typeof saveSearchParams !== 'string'
                    ? createSaveSearchKeyBySearchParams(saveSearchParams)
                    : saveSearchParams;

            dispatch({
                payload: savedSearchKey,
                type: SAVE_SEARCH_REQUEST,
            });
            const { payload } = await postSaveSearch({
                authToken,
                deployment,
                saveSearchParams,
                sendAlert,
            });
            dispatch({
                error: false,
                meta: analytics,
                payload: {
                    id: payload,
                    key: savedSearchKey,
                },
                type: SAVE_SEARCH_SUCCESS,
            });
            dispatch(fetchSavedSearchesIfNeeded(true));

            const analyticsKeyword = typeof saveSearchParams !== 'string' ? saveSearchParams.keyword : saveSearchParams;
            trackSearchFollowedAnalytics({
                pageName: getAmplitudePageNameFromPath(window.location.pathname),
                searchKeywords: analyticsKeyword,
                url: getFullUrl(deployment, window.location.pathname),
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: {
                    key:
                        typeof saveSearchParams !== 'string'
                            ? createSaveSearchKeyBySearchParams(saveSearchParams)
                            : saveSearchParams,
                },
                payload: error,
                type: SAVE_SEARCH_FAIL,
            });
        }
    };

export const deleteSavedSearch =
    (saveSearchParams: ParamsOrKey) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const saveSearchKey =
                typeof saveSearchParams !== 'string'
                    ? createSaveSearchKeyBySearchParams(saveSearchParams)
                    : sanitizeSearchKey(saveSearchParams);
            const savedSearchId = getSavedSearchId(state, saveSearchKey);
            const analytics = getUnfollowSearchAnalytics();

            dispatch({
                payload: saveSearchKey,
                type: DELETE_SAVE_SEARCH_REQUEST,
            });

            await postDeleteSavedSearch({
                authToken,
                deployment,
                savedSearchId,
            });
            dispatch({
                meta: analytics,
                payload: saveSearchKey,
                type: DELETE_SAVE_SEARCH_SUCCESS,
            });

            trackSearchUnfollowedAnalytics({
                pageName: getAmplitudePageNameFromPath(window.location.pathname),
                url: getFullUrl(deployment, window.location.pathname),
            });

            return dispatch(fetchSavedSearchesIfNeeded(true));
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: DELETE_SAVE_SEARCH_FAIL,
            });
        }
    };

export const setNewSignupSearchParams = (params: SearchParams) => async (dispatch: AppDispatch) => {
    return dispatch({
        payload: params,
        type: SET_SIGNUP_SAVE_SEARCH_PARAMS,
    });
};

export type SubmitRecentSearchParams = {
    saveSearchParams: ParamsOrKey;
    url: string;
};

export const submitRecentSearch =
    ({ saveSearchParams, url }: SubmitRecentSearchParams) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const loggedIn = isUserLoggedIn(state);
            const deployment = getDeployment(state);
            const analytics = getFollowSearchAnalytics();

            // If they are not logged in, we dont need to do this.
            if (!loggedIn) {
                return Promise.resolve();
            }

            dispatch({
                payload: url,
                type: SAVE_RECENT_SEARCH_REQUEST,
            });
            const { payload } = await postRecentSearch({
                authToken,
                deployment,
                saveSearchParams,
                url,
            });
            dispatch({
                error: false,
                meta: analytics,
                payload: {
                    id: payload,
                    key: url,
                },
                type: SAVE_RECENT_SEARCH_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: {
                    key: url,
                },
                payload: error,
                type: SAVE_RECENT_SEARCH_FAIL,
            });
        }
    };
