import { AmplitudePageName } from '@/utils/getPageNameFromPath';
import { Crumb } from '@liveauctioneers/caterwaul-components/lib/Breadcrumbs/Breadcrumbs';
import { ItemSummary } from '@/types/item/ItemSummary';
import { ResultFacet } from '@/types/SearchFacet';
import { SearchQueryStatus } from '@/types/search/searchQuery';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';
import { TypedActionWithPayloadMetaActionTime } from '@/types/redux';
import LandingPageMetadata from '@/types/metadata/LandingPageMetadata';

export const DEFAULT_ARCHIVE_STATUSES: SearchQueryStatus[] = [
    SearchQueryStatus.Sold,
    SearchQueryStatus.Passed,
    SearchQueryStatus.Done,
];
export const DEFAULT_LIVE_STATUSES: SearchQueryStatus[] = [
    SearchQueryStatus.Upcoming,
    SearchQueryStatus.Live,
    SearchQueryStatus.Online,
];
export const DEFAULT_SORT_ORDER = SearchSortAndDirection.BestMatch;

export type SearchSlice = {
    categoryCrumbs: Crumb[];
    currentQueryKey?: string;
    error: boolean;
    facets: any[];
    isInitialLoading: boolean;
    isLoading: boolean;
    itemIds: number[];
    searchInputBuffer: string;
    sellerItemsSearch?: boolean;
    sellerItemsSearchSellerId?: number;
    similarItemsURL?: string;
    soldItemFacets: any[];
    soldItemIds: number[];
    sort: SearchSortAndDirection[];
    submitted: boolean;
    submittedFromPage: AmplitudePageName;
    success: boolean;
    totalBuyNowItems: number;
    totalFound: number;
    totalLiveItems: number;
    totalPages: number;
    totalSoldItems: number;
    useGeoLocation: boolean;
};

export const defaultSearchSlice: SearchSlice = {
    categoryCrumbs: [],
    currentQueryKey: '',
    error: false,
    facets: [],
    isInitialLoading: true,
    isLoading: true,
    itemIds: [],
    searchInputBuffer: '',
    sellerItemsSearch: false,
    sellerItemsSearchSellerId: 0,
    similarItemsURL: '',
    soldItemFacets: [],
    soldItemIds: [],
    sort: [],
    submitted: false,
    submittedFromPage: undefined,
    success: false,
    totalBuyNowItems: 0,
    totalFound: 0,
    totalLiveItems: 0,
    totalPages: 0,
    totalSoldItems: 0,
    useGeoLocation: false,
};

/**
 * @deprecated If using redux-toolkit's createSlice, use submitSearch.fulfilled
 */
export const LOAD_SEARCH_RESULTS_SUCCESS = 'la/domain/searchResults/LOAD_SUCCESS';
/**
 * @deprecated If using redux-toolkit's createSlice, use submitSearch.fulfilled
 */
export type LOAD_SEARCH_RESULTS_SUCCESS_ACTION = TypedActionWithPayloadMetaActionTime<
    typeof LOAD_SEARCH_RESULTS_SUCCESS,
    SearchResultPayload
>;

export type SubmitSearchPayload = {
    pageName: string;
    routerState?: {
        [key: string]: any;
    };
    searchQuery: any;
};

/**
 * SearchResultPayload - search payload result shape for most search-party search results
 * @category Search API
 * @see SearchResult
 */
export type SearchResultPayload = {
    currentQueryKey?: string;
    facets: ResultFacet[];
    itemIDs: number[];
    items: ItemSummary[];
    landingPageMetadata: LandingPageMetadata[];
    pageCount: number;
    queryId: string;
    similarItemsURL?: string;
    soldItemFacets: ResultFacet[] | null;
    soldItemIDs: number[] | null;
    sort: SearchSortAndDirection[];
    startIndex: number;
    time: string;
    totalBuyNow: number;
    totalFound: number;
    totalLive?: number;
    totalPages: number;
    totalRecords?: number;
    totalSold?: number;
    usedIndex: string;
};

export type CategoryBreadcrumb = {
    id: string;
    name: string;
    url: string;
};

/**
 * CategorySearchResultPayload - search payload result shape for v4/categoryweb results.
 * @category Search API
 * @see CategorySearchResult
 */
export type CategorySearchResultPayload = SearchResultPayload & {
    breadcrumbs: CategoryBreadcrumb[];
};

/**
 * Represents a valid search response state.
 */
export type ValidSearchResult<SearchPayload> = {
    error: false;
    payload: SearchPayload;
};

/**
 * Represents an invalid search response state.
 */
export type ErrorSearchResult = {
    error: true;
    payload: string;
};

export type BaseSearchResult<SearchPayload> = ValidSearchResult<SearchPayload> | ErrorSearchResult;

/**
 * @category Search API
 * @see fetchPromotedSearchResults
 * @see fetchSearchFacetsResults
 * @see fetchSearchResults
 * @see SearchResultPayload
 */
export type SearchResult = BaseSearchResult<SearchResultPayload>;

/**
 * @category Search API
 * @see fetchCategoryResults
 */
export type CategorySearchResult = BaseSearchResult<CategorySearchResultPayload>;

/**
 * User type guard function, checks if our response is an error or not.
 * @param resp Either a SearchResult or a CategorySearchResult
 * @returns true if in error state.
 */
export const isErrorResponse = (resp: SearchResult | CategorySearchResult): resp is ErrorSearchResult => {
    return resp.error === true || typeof resp.payload === 'string';
};
