import { createSlice } from '@reduxjs/toolkit';
import { defaultShippingSlice, HiddenQuoteReason } from '@/redux/modules/shipping/shipping.types';
import { fetchItemShippingEstimate } from '@/redux/modules/shipping/shipping.actions';
import { ShippingVisibility } from '@/types/ShippingEstimate';
import difference from 'lodash/difference';
import union from 'lodash/union';

const shippingSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchItemShippingEstimate.pending, (slice, { meta }) => {
            slice.loading = union(slice.loading, [meta.arg.itemId]);

            // clear out previous error if an error occurred. That way, new/repeated errors will behave properly
            if (typeof slice.byId[meta.arg.itemId] === 'string') {
                slice.byId[meta.arg.itemId] = null;
            }
        });
        builder.addCase(fetchItemShippingEstimate.fulfilled, (slice, { meta, payload }) => {
            slice.loading = difference(slice.loading, [meta.arg.itemId]);
            slice.loaded[meta.arg.itemId] = Date.now();
            if (
                payload?.shippingAvailable ||
                payload?.visibility === ShippingVisibility.Pending ||
                (payload?.visibility === ShippingVisibility.Uncalculated && !payload?.message) ||
                payload?.visibility === ShippingVisibility.Unavailable ||
                (!meta.arg.countryCode && !meta.arg.postalCode)
            ) {
                slice.byId[meta.arg.itemId] = payload;
            } else {
                slice.byId[meta.arg.itemId] =
                    payload?.message === 'invalid zip code'
                        ? HiddenQuoteReason.BadZipCode
                        : HiddenQuoteReason.TooExpensive;
            }
        });
        builder.addCase(fetchItemShippingEstimate.rejected, (slice, { meta, payload: statusCode }) => {
            slice.loading = difference(slice.loading, [meta.arg.itemId]);
            slice.loaded[meta.arg.itemId] = Date.now();
            let errorType: HiddenQuoteReason;
            switch (statusCode) {
                case 400:
                    errorType = HiddenQuoteReason.MissingDimensions;
                    break;
                default:
                    errorType = HiddenQuoteReason.SystemOrPartnerError;
                    break;
            }
            slice.byId[meta.arg.itemId] = errorType;
        });
    },
    initialState: defaultShippingSlice,
    name: 'shipping',
    reducers: {},
});

export const { reducer: shippingReducer } = shippingSlice;
