import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { fetchSubcategoriesByCategoryID } from '@/redux/api/subcategoryTiles';
import { getDeployment } from './config';

/* Action Types */
export const LOAD_SUBCATEGORY_TILES_FAIL = 'la/domain/items/LOAD_SUBCATEGORY_TILES_FAIL';
export const LOAD_SUBCATEGORY_TILES_SUCCESS = 'la/domain/items/LOAD_SUBCATEGORY_TILES_SUCCESS';

/* reducer */
export type Subcategory = {
    categoryId: string;
    imageURL: string;
    label: string;
    lotCount: number;
    name: string;
    url: string;
};

export type SubcategoryTilesSlice = {
    subcategoryTiles: Subcategory[];
};

export const DEFAULT_STATE: SubcategoryTilesSlice = {
    subcategoryTiles: [],
};

export default function reducer(state: SubcategoryTilesSlice = DEFAULT_STATE, action: any = {}): SubcategoryTilesSlice {
    switch (action.type) {
        case LOAD_SUBCATEGORY_TILES_FAIL:
            return {
                ...state,
                subcategoryTiles: [],
            };
        case LOAD_SUBCATEGORY_TILES_SUCCESS:
            return {
                ...state,
                subcategoryTiles: action.payload,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const subcategoryTilesSlice = createSelector(stateSelector, (state) => state.subcategoryTiles);

export const getSubcategoryTiles = createSelector(subcategoryTilesSlice, (state) => {
    return state.subcategoryTiles.filter((categoryTile) => Number(categoryTile.lotCount) > 0) || [];
});

/* ACTION CREATORS */
export const fetchSubcategories = (categoryId: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const deployment = getDeployment(state);
    if (!categoryId) {
        return;
    }
    try {
        const response = await fetchSubcategoriesByCategoryID({
            categoryId,
            deployment,
        });
        const subcatsInNumLotsOrder = response.payload.sort((a, b) => {
            return b.lotCount - a.lotCount;
        });

        dispatch({
            payload: subcatsInNumLotsOrder,
            type: LOAD_SUBCATEGORY_TILES_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { categoryId },
            payload: error,
            type: LOAD_SUBCATEGORY_TILES_FAIL,
        });
    }
};
