import { AppDispatch, AppGetState } from '@/redux/store';
import { fetchItemSummariesIfNeeded } from './item/summary/itemSummary.actions';
import { fetchSellerEndedCatalogs } from './sellerEndedCatalogs';
import { fetchSellerRecordResultsIfNeeded, getSellerRecordResultsIds } from './sellerRecordResults';
import { fetchSellerUpcomingCatalogsIfNeeded } from './sellerUpcomingCatalogs';

export const fetchDataForSellerPageUpcoming = (sellerId: number) => async (dispatch: AppDispatch) => {
    return Promise.all([dispatch(fetchSellerUpcomingCatalogsIfNeeded(sellerId))]);
};

export const fetchDataForSellerPagePast =
    (sellerId: number, page: number, pageSize: number | 'all', searchTerms: string, sort: string) =>
    async (dispatch: AppDispatch) =>
        dispatch(fetchSellerEndedCatalogs(sellerId, page, pageSize, sort));

export const fetchDataForSellerPageRecordResults =
    (sellerId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        await dispatch(fetchSellerRecordResultsIfNeeded(sellerId));
        const itemIds = getSellerRecordResultsIds(getState(), sellerId);
        if (itemIds.length) {
            return dispatch(
                fetchItemSummariesIfNeeded({
                    identifier: 'SELLER-PAGE-RECORD-RESULTS',
                    itemIds,
                })
            );
        }
        return Promise.resolve();
    };
